import React from 'react';
import { Box, Flex, Image } from '@mantine/core';

import { getImage } from 'utils/getImage';
import { IImage } from 'types';

type Props = {
  className?: string;
  hotel: any;
};

function Gallery({ hotel }: Props) {
  const mainImage: IImage = hotel?.images?.length > 0 && hotel?.images[0];
  const image = getImage(mainImage?.encoded_url, { width: 810, height: 380 });
  const smallImages: IImage[] = hotel?.images?.slice(0, 4);
  const images = smallImages
    .map((img) => getImage(img?.encoded_url, { width: 196, height: 110 }))
    .map((img, index) => <Image key={index} {...img} />);

  return (
    <Box>
      <Image {...image} />

      <Flex style={{ overflow: 'hidden' }} gap='8px' mt='12px'>
        {images}
      </Flex>
    </Box>
  );
}
export default Gallery;
