import React from 'react';
import { Text, Flex, Divider } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type Props = React.PropsWithChildren<{
  rate: any;
}>;

function Rate({ rate }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Flex w='100%' justify='space-between'>
        <Flex w='50%'>
          <Text fz={14} c='var(--mantine-color-gray-6)'>
            {t('Tariffs.Rates.Description', { ageGroup: rate.age_group, ...rate.age_period, ...rate })}
          </Text>
        </Flex>
        <Flex w='100%'>
          <Text fz={14} c='var(--mantine-color-gray-6)'>
            {rate.price.amount} ₽
          </Text>
        </Flex>
      </Flex>

      <Divider my='md' />
    </>
  );
}

export default Rate;
