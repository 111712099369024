import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate, Outlet } from 'react-router-dom';
import { Text, Table, Button, Box, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cx from 'clsx';

import Loader from 'components/ui/Loader';
import { Main, TopBar, Content } from 'components/ui/Layout';
import classes from './List.module.css';

import { STATUSES } from 'types';
import { fetchParsingAction } from 'store/parsingSlice/actions';
import { parsingAllSelector } from 'store/parsingSlice/selectors';
import { IParsingResponse } from 'types/parsing';
import parseAsyncAction from 'store/parsingSlice/actions/parseAsyncAction';

const format = 'DD.MM.YYYY';

function ParsingList() {
  const { t } = useTranslation();
  const statuses: any = {
    created: t('Parsing.Status.Created'),
    pending_hotel_info: t('Parsing.Status.PendingHotel'),
    parsing_hotel_info: t('Parsing.Status.ParsingHotel'),
    pending_rates_info: t('Parsing.Status.PendingRates'),
    parsing_rates_info: t('Parsing.Status.ParsingRates'),
    parsed: t('Parsing.Status.Parsed'),
    completed: t('Parsing.Status.Completed'),
    cancelled: t('Parsing.Status.Cancelled'),
    expired: t('Parsing.Status.Expired'),
    failed: t('Parsing.Status.Failed')
  };

  const navigate = useNavigate();

  const statusClasses: any = {
    failed: classes.statusFailed,
    completed: classes.statusCompleted
  };

  const dispatch = useAppDispatch();
  const [state, parsing]: [STATUSES, IParsingResponse[]] = useAppSelector(parsingAllSelector);

  useEffect(() => {
    dispatch(fetchParsingAction());
  }, []);

  if (state !== STATUSES.FULFILLED) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  const handleScroll = (e: any) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const clientHeight = e.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      // if (stateMore !== STATUSES.PENDING) {
      //   setPage(page + 1);
      //   dispatch(fetchObjectMoreAction(page));
      // }
    }
  };

  const handleClick = (parsing: IParsingResponse) => {
    navigate(`/parsing/${parsing.uuid}/`);
  };

  const handleCreate = () => {
    navigate('/parsing/add/');
  };

  const handleParse = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(parseAsyncAction(id));
  };

  const rows = parsing.map((element) => (
    <Table.Tr className={classes.table_row} key={element.uuid} onClick={() => handleClick(element)}>
      <Table.Td fz={14}>{element.name?.ru}</Table.Td>
      <Table.Td fz={14}>{element.hotel_code}</Table.Td>
      <Table.Td fz={14}>{dayjs(element.created_at).format(format)}</Table.Td>
      <Table.Td fz={14}>
        {element.available_period
          ? [
              dayjs(element.available_period.from).format(format),
              dayjs(element.available_period.to).format(format)
            ].join(' - ')
          : ''}
      </Table.Td>
      <Table.Td fz={14}>{element.tariffs_count}</Table.Td>
      <Table.Td fz={14}>
        <Box className={cx(classes.status, statusClasses[element.state] || classes.statusInProgress)}>
          {statuses[element.state] || t('Parsing.Status.Completed')}
        </Box>
      </Table.Td>

      <Table.Td>
        <Flex bg='green' w='34px' h='34px' align='center' justify='center' onClick={handleParse(element.uuid)}>
          <Text ta='center' c='#fff' fz={15} w={600}>
            P
          </Text>
        </Flex>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Main>
      <TopBar>
        <Text fz='h1' fw='500'>
          {t('Parsing.Title')}
        </Text>

        <Button color='green' onClick={handleCreate}>
          {t('Parsing.Create')}
        </Button>
      </TopBar>

      <Content onScroll={handleScroll}>
        <Table bg='white' verticalSpacing='xl' horizontalSpacing='xl'>
          <Table.Thead bg='var(--mantine-color-blue-1)'>
            <Table.Tr>
              <Table.Th h='70px'>{t('Parsing.Table.Field1')}</Table.Th>
              <Table.Th>{t('Parsing.Table.Field2')}</Table.Th>
              <Table.Th>{t('Parsing.Table.Field3')}</Table.Th>
              <Table.Th>{t('Parsing.Table.Field4')}</Table.Th>
              <Table.Th>{t('Parsing.Table.Field5')}</Table.Th>
              <Table.Th>{t('Parsing.Table.Field6')}</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Content>

      <Outlet />
    </Main>
  );
}

export default ParsingList;
