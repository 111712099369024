import React from 'react';
import { Box } from '@mantine/core';
import cx from 'clsx';

import classes from './Layout.module.css';

type Props = React.PropsWithChildren<{
  className?: string | undefined;
  children: React.ReactNode;
}>;

function Layout({ children, className }: Props) {
  return <Box className={cx(classes.root, className)}>{children}</Box>;
}

export default Layout;
