import React from 'react';
import dayjs from 'dayjs';
import { Text, Box, Flex } from '@mantine/core';

import AvailabilityTable from 'components/ui/AvailabilityTable';
import { IAvailabilitiesResponse } from 'types/availabilities';

type Props = React.PropsWithChildren<{
  data: IAvailabilitiesResponse;
  variant: string;
}>;

function SearchArea({ data, variant }: Props) {
  const availabilities: [string, number][] = data.availabilities.map((d: any) => {
    return [
      dayjs(d.day).format('YYYY-MM-DD'),
      d[variant]
    ];
  });

  return (
    <Box pos='relative' pb='md' mb='md'>
      <Flex direction='column' justify='center' align='center' w='100%'>
        <Text fz={18} w={400} ta='center'>
          {data.hotel.name}
        </Text>

        <Text fz={12} c='#777777' ta='center' mt='xs'>
          {data.hotel.address.location}
        </Text>
      </Flex>

      <AvailabilityTable availabilities={availabilities} />
    </Box>
  );
}

export default SearchArea;
