import { getImage } from 'utils/getImage';
import Uploader from '../utils/Uploader';

import pasteEvent from '../utils/pasteEvent';

class HotelUi {
  constructor({ hotel, api }) {
    this.hotel = hotel;
    this.image = null;
    this.api = api;

    this.uploader = new Uploader({
      config: {},
      onUpload: (response) => this.onUpload(response)
    });

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      name: make('div', this.CSS.name),
      location: make('div', this.CSS.location),
      rating: make('div', this.CSS.rating),
      image: make('img', this.CSS.image),
      imageContainer: make('div', this.CSS.imageContainer),
      button: make('div', this.CSS.button),
      description: make('div', this.CSS.description, {
        contentEditable: true
      }),
      locationContainer: make('div', this.CSS.locationContainer),
      priceContainer: make('div', this.CSS.priceContainer),
      bottomContainer: make('div', this.CSS.bottomContainer),
      rightContainer: make('div', this.CSS.rightContainer),
      amenities: make('div', this.CSS.amenities),
      amenity: make('div', this.CSS.amenity),
      caption: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: true
      }),
      price: make('div', this.CSS.price, {
        contentEditable: true
      }),
      billingHour: make('div', this.CSS.billing, {
        contentEditable: true
      })
    };

    this.nodes.description.addEventListener('paste', pasteEvent);

    this.setHotelContent();
  }

  setHotel(hotel) {
    this.hotel = hotel;

    this.setHotelContent();
  }

  setHotelContent() {
    if (!this.hotel) return;

    this.image = this.hotel.image;

    this.nodes.name.innerHTML = this.hotel.name;
    this.nodes.location.innerHTML = this.hotel.address.region;
    this.nodes.rating.innerHTML = this.hotel.rating;
    this.nodes.description.innerHTML = this.hotel.description || 'Введите описание';
    this.nodes.price.innerHTML = this.hotel.price_text || 'от 2000 ₽';
    this.nodes.billingHour.innerHTML = this.hotel.billingText || 'за сутки';

    this.renderImage();

    this.renderRight();
  }

  renderLeft() {

  }

  renderRight() {
    this.nodes.locationContainer.appendChild(this.nodes.rating);
    this.nodes.locationContainer.appendChild(this.nodes.location);

    this.nodes.priceContainer.appendChild(this.nodes.price);
    this.nodes.priceContainer.appendChild(this.nodes.billingHour);

    this.nodes.bottomContainer.appendChild(this.nodes.priceContainer);
    this.nodes.bottomContainer.appendChild(this.nodes.button);

    this.nodes.rightContainer.appendChild(this.nodes.name);
    this.nodes.rightContainer.appendChild(this.nodes.locationContainer);
    this.nodes.rightContainer.appendChild(this.nodes.description);
    this.nodes.rightContainer.appendChild(this.nodes.bottomContainer);

    this.nodes.wrapper.appendChild(this.nodes.rightContainer);
  }

  onUpload = (data) => {
    this.image = data;

    this.render();
  };

  renderImage() {
    this.nodes.image.addEventListener('click', () => {
      this.uploader.uploadSelectedFile({});
    });

    this.nodes.caption.dataset.placeholder = 'Подсказка для изображения';
    this.nodes.caption.innerHTML = this.image.alt || this.hotel.name;

    this.nodes.imageContainer.appendChild(this.nodes.image);
    this.nodes.imageContainer.appendChild(this.nodes.caption);

    this.nodes.wrapper.appendChild(this.nodes.imageContainer);
  }

  setImage() {
    if (!this.image) return;

    const img = getImage(this.image.encoded_url, { width: 600, height: 420 });

    this.nodes.image.src = img.defaultSrc;
  }

  get CSS() {
    return {
      input: this.api.styles.input,
      wrapper: 'hotelcardSm_hotel_wrapper',
      caption: 'hotelcardSm_hotel__caption',
      billing: 'hotelcardSm_hotel_billing',
      name: 'hotelcardSm_hotel_name',
      location: 'hotelcardSm_hotel_location',
      button: 'hotelcardSm_hotel_button',
      locationContainer: 'hotelcardSm_hotel_locationcontainer',
      rating: 'hotelcardSm_hotel_rating',
      image: 'hotelcardSm_hotel_image',
      imageContainer: 'hotelcardSm_hotel_image_container',
      bottomContainer: 'hotelcardSm_hotel_bottom',
      description: 'hotelcardSm_hotel_description',
      priceContainer: 'hotelcardSm_hotel_pricecontainer',
      price: 'hotelcardSm_hotel_price',
      priceButton: 'hotelcardSm_hotel_price_button',
      rightContainer: 'hotelcardSm_hotel_rightcontainer'
    };
  }

  getHotelData() {
    const description = this.nodes.description.innerHTML;
    const billingText = this.nodes.billingHour.innerHTML;
    const price_text = this.nodes.price.innerHTML;
    const alt = this.nodes.caption.innerHTML;

    return {
      ...this.hotel,
      description,
      image: {
        ...this.image,
        alt
      },
      billingText,
      price_text
    };
  }

  render() {
    this.setImage();

    return this.nodes.wrapper;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};

export default HotelUi;
