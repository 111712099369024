import React from 'react';
import { Flex, Image } from '@mantine/core';
import { Waypoint } from 'react-waypoint';

import { getImage } from 'utils/getImage';

import RightContainer from './RightContainer';
import useHotelPrice from 'hooks/useHotelPrice';

type HotelCardSmallProps = {
  data?: any;
}
function HotelCardSmall({ data: { hotel } }:HotelCardSmallProps) {
  const [{ price, isPending }, { fetch }] = useHotelPrice(hotel);

  if (!hotel.id) return;

  const handleEnter = () => {
    !isPending && !price && fetch();
  };

  const img = getImage(hotel.image.encoded_url, { width: 600, height: 420 });

  return (
    <Waypoint onEnter={handleEnter}>
      <Flex mt='12px' gap='16px' p='24px' bg='var(--mantine-color-orange-1)'>
        <Image w='300px' radius='md' h='210px' src={img.defaultSrc} />

        <RightContainer hotel={hotel} price={price} isPending={isPending} />
      </Flex>
    </Waypoint>
  );
}

export default HotelCardSmall;
