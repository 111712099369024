import { createAsyncThunk } from '@reduxjs/toolkit';

import { objectsDelete } from 'apis/ObjectsApi';
import { IObjectResponse } from 'types';

const ACTION = 'objects/delete';

const deleteArticle = createAsyncThunk(
  ACTION,
  async (data: IObjectResponse): Promise<IObjectResponse> => {
    await objectsDelete(data.id);

    return data;
  }
);

export default deleteArticle;
