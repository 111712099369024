const availableKeysArr = [
  'id',
  'type',
  'encoded_url',
  'thumb_url',
  'url',
  'image',
  'file'
];

const fieldsForCopy = [
  'coordinates'
];

const getTranslateArray = (obj) => {
  const translateArray = [];
  getProp(obj, translateArray);

  function getProp(o, translateArray, text = 'Путь: ') {
    for (const prop in o) {
      if (typeof (o[prop]) === 'object') {
        if (fieldsForCopy.includes(prop)) {
          translateArray.push(o[prop]);

          return;
        }

        getProp(o[prop], translateArray, text += `${prop} `);
      } else {
        if (!availableKeysArr.includes(prop)) {
          translateArray.push(text + o[prop]);
        }
      }
    }
  }

  return translateArray;
};

const getTranslatedObject = (obj, translateArray) => {
  getProp(obj, translateArray);

  function getProp(o, translateArray) {
    for (const prop in o) {
      if (typeof (o[prop]) === 'object') {
        if (fieldsForCopy.includes(prop)) {
          o[prop] = translateArray.shift();

          return;
        }

        getProp(o[prop], translateArray);
      } else {
        if (!availableKeysArr.includes(prop)) {
          o[prop] = translateArray.shift();
        }
      }
    }
  }

  return translateArray;
};

function translate(object) {
  const translateArray = getTranslateArray(object);
  const newObj = JSON.parse(JSON.stringify(object));
  getTranslatedObject(newObj, translateArray);

  return newObj;
}

export default translate;
