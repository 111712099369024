import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum OSTROVOK_PROVIDER_FIELDS {
    PROVIDER_ID = 'provider_id',

}

export const validationSchema = () =>
  yup.object({
    [OSTROVOK_PROVIDER_FIELDS.PROVIDER_ID]: yup.string().required('Обязательное поле')
  });

export const defaultValues = {
  [OSTROVOK_PROVIDER_FIELDS.PROVIDER_ID]: ''
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
