import { createAsyncThunk } from '@reduxjs/toolkit';

import { objectsUnpublish } from 'apis/ObjectsApi';
import { IObjectResponse } from 'types';

const ACTION = 'objects/unpublish';

const unpublishArticle = createAsyncThunk(
  ACTION,
  async (data: IObjectResponse): Promise<IObjectResponse> => {
    const article = await objectsUnpublish(data.id);

    return article;
  }
);

export default unpublishArticle;
