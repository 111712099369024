import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum PARSING_FIELDS {
    HOTEL_CODE = 'hotel_code',
    PLACE = 'place',
}

export const validationSchema = () =>
  yup
    .object({
      [PARSING_FIELDS.HOTEL_CODE]: yup.string().required('Обязательное поле'),
      [PARSING_FIELDS.PLACE]: yup.string().required('Обязательное поле')
    });

export const defaultValues = {
  [PARSING_FIELDS.HOTEL_CODE]: '',
  [PARSING_FIELDS.PLACE]: ''
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
