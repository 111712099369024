import _orderBy from 'lodash/orderBy';

function applySort(data, sort) {
  if (sort === 'name') {
    return _orderBy(data, 'title.ru');
  }

  if (sort === 'publish') {
    return _orderBy(data, (d) => !d.published);
  }

  if (sort === 'category') {
    return _orderBy(data, (d) => d.category?.name);
  }
  return data;
}

export default applySort;
