import connection from 'connection/instance';
import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DD';
const SITE = process.env.REACT_APP_SITE;
const TYPESENSE_API_KEY = process.env.REACT_APP_TYPESENSE_API_KEY;

class HotelsApi {
  constructor() {
    this.baseUrl = `${SITE}/api/hotels`;
  }

  async handleRequest(method, url, config = {}) {
    try {
      const response = await connection[method](url, config);
      return { data: response.data, error: undefined };
    } catch (error) {
      return { data: undefined, error };
    }
  }

  async fetchHotels(q) {
    const params = {
      q: q.toLowerCase(),
      query_by: 'index_strings',
      sort_by: '_text_match:desc',
      num_typos: 1
    };

    const headers = { 'X-TYPESENSE-API-KEY': TYPESENSE_API_KEY };

    const response = await this.handleRequest('get', '/api/ts/collections/hotels/documents/search', { params, headers });
    return { data: response.data?.hits, error: response.error };
  }

  async fetchHotel(slug) {
    const response = await this.handleRequest('get', `${this.baseUrl}/${slug}`);
    return { data: response.data?.hotel, error: response.error };
  }

  async fetchPrice(hotel) {
    const check_in = dayjs().add(7, 'day').format(DATE_FORMAT);
    const check_out = dayjs().add(14, 'day').format(DATE_FORMAT);

    const params = {
      check_in,
      check_out,
      travellers: [{ age_group: 'adult', age: 0 }],
      exclude_ids: [hotel.id],
      address: hotel.address
    };

    const response = await this.handleRequest('get', `${this.baseUrl}/${hotel.id}/prices`, { params });
    return { data: response.data?.meta, error: response.error };
  }
}

export default new HotelsApi();
