import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Modal } from '@mantine/core';

import { useAppDispatch } from 'store';
import createArticleAction from 'store/articlesSlice/actions/createArticleAction';
import Form from 'components/ui/Form';
import AddBlogForm, { extendDefaultValues, validationSchema } from 'pages/BlogNew/components/AddBlogForm';
import { getFormattedFormData } from 'pages/BlogNew/utils';

import { type ArticleFormFields } from 'types';

function Add() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (formData: ArticleFormFields) => {
    const formattedData = getFormattedFormData(formData);

    await dispatch(createArticleAction(formattedData));

    navigate('/articles');
  };

  const handleReset = () => {
    navigate('/articles');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  return (
    <Modal
      size='lg'
      title={<Text fz='24px'>{t('Blog.Title')}</Text>}
      centered
      onClose={handleReset}
      withCloseButton={false}
      opened
    >
      <Form
        schema={validationSchema()}
        defaultValues={extendDefaultValues({ type: 'blog' })}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <AddBlogForm />
      </Form>
    </Modal>
  );
}

export default Add;
