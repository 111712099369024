import { AuthorRequest } from '../../../types/authors';

export const getFormattedFormData = (
  formData: Omit<AuthorRequest, 'specializations'> & {
    specializations?: string[];
  }
): AuthorRequest => {
  const specializations = formData.specializations ?? [];

  return {
    ...formData,
    specializations: specializations.map((specialization) => ({ tag: specialization }))
  };
};
