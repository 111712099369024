import { useState } from 'react';
import sourcesApi from 'apis/SourcesApi';

function useSources() {
  const [value, setValue] = useState([]);

  const fetch = async () => {
    const { data, error } = await sourcesApi.fetchList();

    if (!error) {
      setValue(data);
    }

    return { data, error };
  };

  return [value, { fetch }];
}

export default useSources;
