import dayjs from 'dayjs';

const format = 'YYYY-MM-DD';

function getSearchDate() {
  const today = dayjs();
  const check_in = today.add(7, 'day').format(format);
  const check_out = today.add(14, 'day').format(format);

  return {
    check_in,
    check_out
  };
}

export default getSearchDate;
