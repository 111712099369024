import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import { IRequest, IResponseData } from 'types';
import { IAvailabilitiesMeta, IAvailabilitiesRequestData, IAvailabilitiesResponse } from 'types/availabilities';

type AvailabilitiesResponseData = IResponseData<IAvailabilitiesResponse[], IAvailabilitiesMeta>;

const handleApiResponse = <T>(response: AxiosResponse<T>): T => {
  return response.data;
};

export const availabilitesFetch = async (
  requestParams: IAvailabilitiesRequestData
): Promise<AvailabilitiesResponseData> => {
  const params: IRequest<IAvailabilitiesRequestData> = { data: requestParams };

  const response = await connection.get<AvailabilitiesResponseData>(
    '/api/reports/availabilities',
    { params }
  );

  return handleApiResponse(response);
};
