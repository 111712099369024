import React from 'react';
import { Controller } from 'react-hook-form';
import { Switch, type SwitchProps } from '@mantine/core';

import classes from './SwitchForm.module.css';

const SwitchForm = ({ name, label, disabled, width, required }: SwitchProps) => {
  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <Switch
            {...field}
            checked={field.value}
            className={classes.align_items_center}
            label={label}
            required={required}
            w={width}
            disabled={disabled}
            color='green'
          />
        );
      }}
    />
  );
};

export default SwitchForm;
