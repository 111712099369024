import { createAsyncThunk } from '@reduxjs/toolkit';

import { IObjectsParams, objectsUpdate } from 'apis/ObjectsApi';
import { IObjectData } from 'types';
import { toBlocksMapper } from 'utils/hotelMapper';

const ACTION = 'objects/update';

const fetchOneArticlesAction = createAsyncThunk(
  ACTION,
  async (data: IObjectsParams): Promise<IObjectData> => {
    const hotel: any = await objectsUpdate(data);

    return toBlocksMapper(hotel);
  }
);

export default fetchOneArticlesAction;
