import React from 'react';
import { Flex, Text } from '@mantine/core';

import CommonPart from '../CommonPart';
import { IObjectDataFull } from 'types';

type Props = {
  object: IObjectDataFull;
  currentLanguage: string;
};

function DescriptionChatGPT({ object, currentLanguage }: Props) {
  const description = object.body_translations[currentLanguage]?.blocks.find(
    (b) => b.type === 'descriptionChatGPT'
  ).data;

  return (
    <Flex direction='column' gap='xs'>
      <CommonPart>
        <Text style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: description?.about || description }} />
      </CommonPart>
    </Flex>
  );
}

export default DescriptionChatGPT;
