import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Button } from '@mantine/core';

import Loader from 'components/ui/Loader';

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

type Props = {
  isPending: boolean;
  hotel: any;
  price: any
}

function Bottom({ hotel, price, isPending }:Props) {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}#roomTypes`);
  };

  const pretiffyPrice = (price) => {
    const n = Math.round(price).toString();
    const separator = ' ';
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + separator);
  };

  const enteredPrice = {
    amount: Number(hotel.min_price_text.match(/\d+/)[0]),
    currency: price?.currency || 'RUB'
  };

  return (
    <Flex mih='155px' gap='16px' mt='12px'>
      <Text fz='18px' dangerouslySetInnerHTML={{ __html: hotel.description }} />
      <Flex p='xs' style={{ border: '1px solid var(--mantine-color-orange-2)' }} direction='column' h='fit-content'>
        {!isPending && enteredPrice?.amount > 0 && (
          <Flex align='center' gap='8px'>
            <Text fz='30px' fw='bold'>
              {pretiffyPrice(price?.amount > 0 ? price.amount : enteredPrice.amount)} ₽
            </Text>

            <Text fz='18px'>
              за сутки
            </Text>
          </Flex>
        )}

        {!isPending && enteredPrice?.amount === 0 && (
          <Text c='var(--mantine-color-red-6)' mb='12px'>{t('HotelCard.Unavailable')}</Text>
        )}

        {isPending && <Loader />}

        <Button size='lg' color='green' onClick={handleClick}>
          {t('HotelCard.Action.SelectRoom')}
        </Button>
      </Flex>

    </Flex>
  );
}

export default Bottom;
