import React from 'react';
import ConditionalComponent from '../../ConditionalComponent';

import BlockCards from './BlockCards';
import ListCards from './ListCards';

import { type BlogCardsProps } from 'types';

function BlogCards(props: BlogCardsProps) {
  const { type } = props;

  return (
    <ConditionalComponent condition={type === 'list'} fallback={<BlockCards {...props} />}>
      <ListCards {...props} />
    </ConditionalComponent>
  );
}

export default BlogCards;
