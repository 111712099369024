import React from 'react';
import { Box } from '@mantine/core';

import { OSTROVOK } from '../../AddObject/constants';
import OstrovokFormEdit from './OstrovokForm';
import SanatoryFormEdit from './SanatoryForm';
import { SanatoryFormProps } from '../types';

type EditObjectProps = {
  hotel: SanatoryFormProps;
};

function EditObject({ hotel }: EditObjectProps) {
  const renderForm = () => {
    switch (hotel?.provider) {
      case OSTROVOK:
        return <OstrovokFormEdit hotel={hotel} />;
      default:
        return <SanatoryFormEdit hotel={hotel} />;
    }
  };

  return <Box>{renderForm()}</Box>;
}
export default EditObject;
