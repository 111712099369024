import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Center, TextInput, UnstyledButton, type TextInputProps } from '@mantine/core';
import cx from 'clsx';
import { IconEye, IconEyeClosed, IconAlertOctagon } from '@tabler/icons-react';

import ConditionalComponent from '../../ConditionalComponent';

import classes from './InputForm.module.css';

interface Props extends TextInputProps {
  width?: string;
  isPasswordField?: boolean;
  customError?: string | undefined | null;
}

const getError = (path: string, errors: any) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], errors);
};

function InputForm({
  name,
  label,
  required = false,
  className,
  placeholder,
  type,
  disabled,
  onBlur,
  isPasswordField = false,
  width,
  description,
  leftSection,
  customError
}: Props) {
  const {
    formState: { errors }
  } = useFormContext<{ name: Props['name'] }>();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const errorMsg = getError(name, errors) ? String(getError(name, errors)?.message) : undefined;

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <TextInput
            {...field}
            label={label}
            placeholder={placeholder}
            required={required}
            error={errorMsg || customError}
            w={width}
            leftSection={leftSection}
            errorProps={{ className: classes.errorLabel }}
            description={description}
            type={isPasswordField ? (showPassword ? 'text' : 'password') : type}
            rightSection={
              <ConditionalComponent
                condition={isPasswordField}
                fallback={
                  <ConditionalComponent condition={!!errorMsg}>
                    <IconAlertOctagon size={20} />
                  </ConditionalComponent>
                }
              >
                <UnstyledButton variant='transparent' size='xs' onClick={togglePasswordVisibility} color='gray'>
                  <Center>
                    <ConditionalComponent condition={showPassword} fallback={<IconEye size={20} />}>
                      <IconEyeClosed size={20} />
                    </ConditionalComponent>
                  </Center>
                </UnstyledButton>
              </ConditionalComponent>
            }
            disabled={disabled}
            className={cx(className, type === 'hidden' && classes.hidden)}
            onBlur={onBlur}
            withAsterisk={required}
          />
        );
      }}
    />
  );
}

export default InputForm;
