import React from 'react';

import HotelCard from './HotelCard';
import Region from './Region';
import Header from './Header';
import Paragraph from './Paragraph';
import Image from './Image';
import HotelCardSmall from './HotelCardSmall';

type Props = {
  group: {
    type: string;
    data: any;
  };
  region?: {
    name?: string;
  };
};

function Group({ group, ...rest }: Props) {
  switch (group.type) {
    case 'regionCard':
      return <Region data={group.data} {...rest} />;

    case 'header':
      return <Header data={group.data} {...rest} />;

    case 'hotelCard':
      return <HotelCard data={group.data} {...rest} />;

    case 'paragraph':
      return <Paragraph data={group.data} {...rest} />;

    case 'image':
      return <Image data={group.data} {...rest} />;

    case 'hotelCardSmall':
      return <HotelCardSmall data={group.data} {...rest} />;

    default:
      return <div />;
  }
}

export default Group;
