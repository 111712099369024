import { startAppListening } from '../../listenerMiddleware';

import { toastify } from 'utils/toastify';

import {
  updateSearchArea
} from '../actions';

startAppListening({
  actionCreator: updateSearchArea.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось обновить область поиска');
  }
});

startAppListening({
  actionCreator: updateSearchArea.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', 'Область поиска успешно обновлена');
  }
});
