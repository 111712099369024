import React, { useEffect, useState } from 'react';

import SelectForm from 'components/ui/SelectForm';
import reviewersApi from 'apis/ReviewersApi/ReviewersApi';

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  required?: boolean;
};

const ReviewersSelect = ({ label, placeholder, name, required }: Props) => {
  const [authors, setAuthors] = useState<any[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await reviewersApi.fetchList();

        Array.isArray(data) &&
          setAuthors(
            data.map((option) => ({
              value: option.id,
              label: option.full_name
            }))
          );
      } catch (e) {
        console.log(e, 'err');
      }
    };

    fetch();
  }, []);

  return (
    <SelectForm
      width='100%'
      name={name}
      label={label}
      options={authors}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default ReviewersSelect;
