import React from 'react';
import { MapContainer as Map, TileLayer, Marker, ZoomControl } from 'react-leaflet';
import { Box, Paper } from '@mantine/core';

import getMapTilesUrl from 'utils/getMapTilesUrl';
import Distances from './Distances';
import { IObjectDataFull } from 'types';

type Props = {
  object: IObjectDataFull;
  hotel: any;
  currentLanguage: string;
  tileBaseUrl?: string;
};

function MapPage({ object, hotel, tileBaseUrl, currentLanguage }: Props) {
  const tileUrl = getMapTilesUrl({ tileBaseUrl });

  const coordinates = [...hotel.address.coordinates].reverse();

  const distances = object.body_translations[currentLanguage]?.blocks?.find((b) => b.type === 'distances')?.data?.list;

  if (!distances) {
    return <Box />;
  }

  const distancesElement = distances.length > 0 && (
    <Box>
      <Distances distances={distances} />
    </Box>
  );

  return (
    <Paper radius='md'>
      <Map
        // @ts-ignore
        animate
        minZoom={1}
        useFlyTo
        center={coordinates}
        zoom={15}
        zoomControl={false}
      >
        <TileLayer url={tileUrl} />

        <ZoomControl position='bottomright' />

        <Marker position={coordinates} />
      </Map>

      <Paper radius='xs' p='md' withBorder>
        {distancesElement}
      </Paper>
    </Paper>
  );
}

MapPage.defaultProps = {
  tileBaseUrl: 'https://tiles.worldota.net/api/v1/t/ostrovok'
};

export default MapPage;
