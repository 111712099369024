import React from 'react';

import About from './About';
import Diseases from './Diseases';
import Facts from './Facts';
import HotelCard from './HotelCard';
import HotelCardSmall from './HotelCardSmall';
import Links from './Links';
import { Image, Paragraph, Header, InfoBlock, List } from 'components/ui/PreviewArticles/PreviewBlocks';
import Region from './Region';
import Treatments from './Treatments';
import WhatToSee from './WhatToSee';

type GroupProps = {
  group: any
  region?: any
}

function Group({ group, region, ...rest }:GroupProps) {
  switch (group.type) {
    case 'titleAndDescription':
      return <About data={group.data} {...rest} />;

    case 'list':
      return <List data={group.data} {...rest} />;

    case 'regionCard':
      return <Region data={group.data} {...rest} />;

    case 'links':
      return <Links data={group.data} {...rest} />;

    case 'infoBlock':
      return <InfoBlock data={group.data} {...rest} />;

    case 'header':
      return <Header data={group.data} {...rest} />;

    case 'hotelCard':
      return <HotelCard data={group.data} {...rest} />;

    case 'hotelCardSmall':
      return <HotelCardSmall data={group.data} {...rest} />;

    case 'listItems':
      return <Diseases data={group.data} {...rest} />;

    case 'facts':
      return <Facts data={group.data} {...rest} />;

    case 'paragraph':
      return <Paragraph data={group.data} {...rest} />;

    case 'image':
      return <Image data={group.data} {...rest} />;

    case 'treatments':
      return <Treatments data={group.data} {...rest} />;

    case 'whatToSee':
      return <WhatToSee data={group.data} {...rest} />;

    default:
      return <div />;
  }
}

export default Group;
