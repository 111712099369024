import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import Form from 'components/ui/Form';
import AuthorsForm, { extendDefaultValues, validationSchema } from 'pages/Authors/components/Form';

import useReviewers from 'pages/Authors/hooks/useReviewers';
import { Text, Modal } from '@mantine/core';
import { getFormattedFormData } from '../../utils';
import { AuthorRequest } from '../../../../types/authors';

function EditAdditional() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [author, { fetchOne, update }] = useReviewers();

  useEffect(() => {
    fetchOne(id);
  }, [id]);

  const handleSubmit = async (formData: AuthorRequest & { specializations: string[] }) => {
    const formattedData = getFormattedFormData(formData);

    const { error } = await update(id, formattedData);

    if (!error) {
      navigate('/reviewers');
    }
  };

  const handleReset = () => {
    navigate('/reviewers/');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  return (
    <Modal
      size='lg'
      opened
      centered
      onClose={handleReset}
      title={
        <Text fz='20' fw='500'>
          Редактировать автора
        </Text>
      }
    >
      <Form
        schema={validationSchema()}
        defaultValues={extendDefaultValues()}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <AuthorsForm author={author && author.length ? author[0] : undefined} />
      </Form>
    </Modal>
  );
}

export default EditAdditional;
