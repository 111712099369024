function pasteEvent(event) {
  event.preventDefault();
  event.stopPropagation();

  const paste = (event.clipboardData || window.clipboardData).getData('text');

  const selection = window.getSelection();
  if (!selection.rangeCount) return;
  selection.deleteFromDocument();
  selection.getRangeAt(0).insertNode(document.createTextNode(paste));
}

export function pasteHtmlEvent(event) {
  event.preventDefault();
  event.stopPropagation();

  const paste = (event.clipboardData || window.clipboardData).getData('text/html');

  const docElem = document.createElement('div');
  docElem.innerHTML = paste;

  const selection = window.getSelection();
  if (!selection.rangeCount) return;
  selection.deleteFromDocument();
  selection.getRangeAt(0).insertNode(docElem);
}

export default pasteEvent;
