import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Text } from '@mantine/core';

import { ADD_DISEASE_FIELDS } from './schema';
import InputForm from 'components/ui/InputForm';
import TextareaForm from 'components/ui/TextareaForm';

import { IArticle } from 'types';

import MetaTagsForm from 'pages/BlogNew/components/MetaTagsForm';

type IProps = {
  article?: IArticle;
};

function AddBlogForm({ article }: IProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!article) {
      return;
    }
    setValue(ADD_DISEASE_FIELDS.TITLE_FIELD_RU, article.title.ru);
    setValue(ADD_DISEASE_FIELDS.SEO_TITLE_FIELD_RU, article.seo_title.ru);
    setValue(ADD_DISEASE_FIELDS.SEO_DESCRIPTION_FIELD_RU, article.seo_description.ru);
    setValue(ADD_DISEASE_FIELDS.SUB_TITLE_FIELD_RU, article.sub_title.ru);
    setValue(ADD_DISEASE_FIELDS.TYPE_FIELD, article.type);
    setValue(ADD_DISEASE_FIELDS.META_TAGS_FIELD, article.meta_tags);
  }, [article, setValue]);

  return (
    <Flex direction='column' gap='lg'>
      <Flex direction='column' gap='xs'>
        <Text>{t('Common.General')}</Text>
        <InputForm name='type' type='hidden' />
        <InputForm
          name={ADD_DISEASE_FIELDS.TITLE_FIELD_RU}
          placeholder={t('Common.Name.Placeholder')}
          label={t('Regions.Form.Name')}
          required
        />
      </Flex>

      <Flex direction='column' gap='xs'>
        <Text>{t('Disease.Form.FieldsetTitle')}</Text>
        <InputForm
          name={ADD_DISEASE_FIELDS.SUB_TITLE_FIELD_RU}
          placeholder={t('Disease.Form.Placeholder')}
          label={t('Disease.Form.Label')}
          required
        />
      </Flex>

      <Flex direction='column' gap='xs'>
        <Text>{t('Common.Seo.Title')}</Text>
        <InputForm
          name={ADD_DISEASE_FIELDS.SEO_TITLE_FIELD_RU}
          placeholder={t('Common.Title.Placeholder')}
          label={t('Common.Title')}
        />
        <TextareaForm
          name={ADD_DISEASE_FIELDS.SEO_DESCRIPTION_FIELD_RU}
          placeholder={t('Common.Description.Placeholder')}
          label={t('Common.Description')}
        />
        <Text mb={0} mt='lg'>
          {t('Common.MetaTags')}
        </Text>
        <MetaTagsForm />
      </Flex>

      <Flex align='center' gap='md'>
        <Button type='submit' size='md' color='green' px='xl'>
          {t('Action.Save')}
        </Button>

        <Button type='reset' size='md' color='gray'>
          {t('Action.Cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default AddBlogForm;
