import pasteEvent from '../utils/pasteEvent';
import _uniqueId from 'lodash/uniqueId';

export default class Ui {
  constructor({ list, onAdd, onRemove, t }) {
    this.list = list;
    this.onAdd = onAdd;
    this.onRemove = onRemove;
    this.t = t;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      listElement: make('div', [this.CSS.listElement]),
      title: make('h2', this.CSS.title, {
        innerHTML: t('Food.Title')
      }),
      listContainer: make('div', this.CSS.listContainer),
      button: this.createAddButton()
    };
  }

  createAddButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = this.t('Common.Add');

    button.addEventListener('click', () => {
      this.onAdd();
    });

    return button;
  }

  get CSS() {
    return {
      wrapper: 'feeding_programs',
      listElement: 'feeding_programs_element',
      title: 'feeding_programs_title',
      listContainer: 'feeding_programs_list',
      remove: 'feeding_programs_settings_remove',
      button: 'feeding_programs_add',
      removeIcon: 'feeding_programs_element_remove',
      listText: 'feeding_programs_element_text',
      listTitle: 'feeding_programs_element_title'
    };
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    console.log(e.code);
  }

  getData() {
    const list = this.nodes.wrapper.getElementsByClassName(this.CSS.listElement);

    return {
      list: Array.from(list).map((el) => {
        const title = el.querySelector(`.${this.CSS.listTitle}`);
        const description = el.querySelector(`.${this.CSS.listText}`);

        return {
          title: title.innerHTML,
          description: description.innerHTML,
          id: _uniqueId()
        };
      })
    };
  }

  renderList() {
    return this.list.map((element, index) => {
      const listElement = make('div', this.CSS.listElement);

      const listText = make('div', this.CSS.listText, {
        contentEditable: true,
        innerHTML: element.description
      });

      listText.addEventListener('keyup', this.onKeyUp);

      const listTitle = make('h3', this.CSS.listTitle, {
        contentEditable: true,
        innerHTML: element.title
      });

      listTitle.addEventListener('paste', pasteEvent);
      listText.addEventListener('paste', pasteEvent);

      const removeIcon = make('div', this.CSS.removeIcon);

      removeIcon.addEventListener('click', () => {
        this.onRemove(index);
      });

      listElement.appendChild(listTitle);
      listElement.appendChild(listText);
      listElement.appendChild(removeIcon);

      return listElement;
    });
  }

  render() {
    const list = this.renderList();

    this.clear(this.nodes.listContainer);

    list.forEach((element) => {
      this.nodes.listContainer.appendChild(element);
    });

    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.listContainer);
    this.nodes.wrapper.appendChild(this.nodes.button);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  }

  displayErrorItemTitle = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listTitle);
  }

  displayErrorItemDescription = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listText);
  }

  displayItemElementError = (flag, index, cssClass) => {
    const item = this.getItem(index);
    const element = item.querySelector(`.${cssClass}`);

    flag ? element.classList.add('error') : element.classList.remove('error');

    return element;
  }

  getItem = (index) => {
    return this.nodes.wrapper.getElementsByClassName(this.CSS.listElement)[index];
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
