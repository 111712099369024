import { createAsyncThunk } from '@reduxjs/toolkit';

import { parseAsync } from 'apis/ParsingApi';

const ACTION = 'parsing/async';

const parseAsyncAction = createAsyncThunk(
  ACTION,
  parseAsync
);

export default parseAsyncAction;
