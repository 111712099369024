import React, { useEffect, useState, type UIEvent } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate, Outlet } from 'react-router-dom';
import { Text, Table, Button, Box, Menu, Flex, ActionIcon } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { IconDots } from '@tabler/icons-react';

import Loader from 'components/ui/Loader';
import RegionSelect from 'components/ui/RegionSelect';

import { Main, TopBar, Content } from 'components/ui/Layout';
import { IObjectResponse, IRegionDocument, STATUSES } from 'types';
import { fetchObjectAction, fetchObjectMoreAction } from 'store/objectsSlice/actions';
import { objectsAllSelector } from 'store/objectsSlice/selectors';
import { getProviderTranslate } from './getProviderTranslate';

const format = 'DD-MM-YYYY HH:mm';

const site = process.env.REACT_APP_SITE;

function ObjectsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(2);

  const dispatch = useAppDispatch();
  const [state, objects, stateMore]: [STATUSES, IObjectResponse[], string] = useAppSelector(objectsAllSelector);

  useEffect(() => {
    dispatch(fetchObjectAction(1));
  }, []);

  const handleEdit = (object: IObjectResponse) => () => {
    navigate(`/objects/${object.id}/edit`);
  };

  if (state !== STATUSES.FULFILLED) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  const handleCheck = (object: IObjectResponse) => () => {
    window.open(`${site}/hotel/${object.slug}`);
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const clientHeight = e.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      if (stateMore !== STATUSES.PENDING) {
        setPage((page) => page + 1);

        dispatch(fetchObjectMoreAction(page));
      }
    }
  };

  const handleSelect = (hotelObj: IRegionDocument) => {
    navigate(`/objects/${hotelObj?.place_id}/edit`);
  };

  const rows = objects.map((element) => (
    <Table.Tr key={element.id}>
      <Table.Td maw='300px' fz={14}>
        {element.name}
      </Table.Td>
      <Table.Td maw='300px' fz={14}>
        {element.address.location}
      </Table.Td>
      <Table.Td fz={14}>
        {dayjs(element.created_at).isValid() ? dayjs(element.created_at).format(format) : null}
      </Table.Td>
      <Table.Td fz={14}>{dayjs(element.updated_at).format(format)}</Table.Td>
      <Table.Td fz={14}>{getProviderTranslate(element.provider)}</Table.Td>
      <Table.Td fz={14}>
        <Flex align='center' gap='xs'>
          <Button color='gray' onClick={handleEdit(element)} size='xs'>
            Редактировать
          </Button>

          <Menu>
            <Menu.Target>
              <ActionIcon color='gray'>
                <IconDots />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item>
                <Button fullWidth onClick={handleCheck(element)} color='gray'>
                  Посмотреть на сайте
                </Button>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Main>
      <TopBar>
        <Flex align='center' gap='md'>
          <Text fz='h1' fw='500'>
            {t('Objects.Title')}
          </Text>
          <Box style={{ minInlineSize: '240px' }}>
            <RegionSelect placeholder={t('SearchArea.Objects.Placeholder')} onSelect={handleSelect} type='hotels' />
          </Box>
        </Flex>
        <Button onClick={() => navigate('/objects/new')} color='green'>
          {t('Action.AddObject')}
        </Button>
      </TopBar>

      <Content onScroll={handleScroll}>
        <Table bg='white' withTableBorder style={{ '--table-border-color': 'var(--mantine-color-gray-4)' }}>
          <Table.Thead bg='var(--sb-main-gray)'>
            <Table.Tr style={{ '--table-border-color': 'var(--mantine-color-gray-3)' }}>
              <Table.Th h='70px'>{t('Objects.Table.Field1')}</Table.Th>
              <Table.Th>{t('Objects.Table.Field2')}</Table.Th>
              <Table.Th>{t('Objects.Table.Field3')}</Table.Th>
              <Table.Th>{t('Objects.Table.Field4')}</Table.Th>
              <Table.Th>{t('Objects.Table.Field5')}</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
        {stateMore === STATUSES.PENDING && <Loader />}
      </Content>

      <Outlet />
    </Main>
  );
}

export default ObjectsList;
