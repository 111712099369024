function blogMapper({ slug, title, id, sub_title }) {
  return {
    slug,
    title,
    sub_title,
    id
  };
}

export default blogMapper;
