import { createAsyncThunk } from '@reduxjs/toolkit';

import { tariffsFetch } from 'apis/ParsingApi';

const ACTION = 'parsingTariffs/fetch';

const fetchParsingTariffsAction = createAsyncThunk(
  ACTION,
  tariffsFetch
);

export default fetchParsingTariffsAction;
