import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mantine/core';

import AddParsingForm, { extendDefaultValues, validationSchema } from './components/Form';
import Form from 'components/ui/Form';
import { useAppDispatch } from 'store';
import { createParsingAction } from 'store/parsingSlice/actions';
import { IParsingCreateRequest } from 'apis/ParsingApi';

function Add() {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (data: IParsingCreateRequest) => {
    setLoading(true);
    await dispatch(createParsingAction(data));
    setLoading(false);

    navigate('/parsing/');
  };

  const handleReset = () => {
    navigate('/parsing/');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  return (
    <Modal title='Добавить парсинг тарифа' centered opened onClose={handleReset}>
      <Form
        schema={validationSchema()}
        defaultValues={extendDefaultValues({})}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <AddParsingForm loading={loading} />
      </Form>
    </Modal>
  );
}

export default Add;
