import arrayToTree from 'array-to-tree';
import _compact from 'lodash/compact';

function amenitiesMapper(amenities) {
  let amenitiesList = arrayToTree(amenities || [], {
    parentProperty: 'parent_tag',
    customID: 'tag'
  });

  amenitiesList = amenitiesList.map((amenity) => {
    if (amenity.children) {
      const amenityLvl2 = amenity.children[0];
      let text = amenityLvl2.name;

      if (amenityLvl2.children) {
        text = [text, amenityLvl2.children[0].name].join(' ');
      }

      return {
        text
      };
    }
  });

  return _compact(amenitiesList);
}

export default amenitiesMapper;
