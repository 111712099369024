import React from 'react';
import { Button, Text, Paper, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Content } from 'components/ui/Layout';
import RegionSelectForm from 'components/ui/RegionSelectForm/RegionSelectForm';
import TimezonesSelect from 'components/forms/TimezonesSelect';

import { useAppDispatch } from 'store';
import createObjectAction from 'store/objectsSlice/actions/createObjectAction';
import { toastify } from 'utils/toastify';
import Form from 'components/ui/Form';
import { extendDefaultValues, OSTROVOK_FIELDS, validationSchema } from './schema';

import InputForm from 'components/ui/InputForm';

type OstrovokFormProps = {
  setOpenModal: (open: boolean) => void;
  setModalData: (data: any) => void;
  provider: string
}

function OstrovokForm({ setOpenModal, setModalData, provider }:OstrovokFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleReset = () => {
    navigate('/objects');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  const handleSubmit = async (data) => {
    const formatedData = {
      ...data,
      provider
    };

    try {
      const result = await dispatch(createObjectAction(formatedData));
      setOpenModal(true);
      // @ts-ignore */
      setModalData(result?.payload?.meta);
    } catch (error) {
      toastify('error', 'Возникла ошибка');
    }
  };

  return (
    <Form
      schema={validationSchema()}
      defaultValues={extendDefaultValues({})}
      onSubmit={handleSubmit}
      onError={handleError}
      onReset={handleReset}
    >
      <Content>
        <Paper w='650px' withBorder shadow='md' p={30} radius='md'>
          <Text fz={30}>{t('Objects.New.Form1.Title')}</Text>

          <Flex direction='column' gap='md'>
            <Text mt='md' fz={20}>{t('Objects.New.Form.All')}</Text>
            <InputForm
              name={OSTROVOK_FIELDS.PROVIDER_ID}
              label={t('Objects.Form.Ostrovok.ProviderId')}
              placeholder={t('Objects.Form.Ostrovok.ProviderId.Placeholder')}
              required
            />

            <InputForm
              name={OSTROVOK_FIELDS.LOCATION}
              label={t('Objects.New.Form.Address')}
              placeholder={t('Objects.New.Form.Address.Placeholder')}
              required
            />

            <RegionSelectForm required label={t('Objects.New.Form.SearchArea')} placeholder={t('SearchArea.Objects.Placeholder')} name={OSTROVOK_FIELDS.PLACE_ID} />

            <Flex gap='md'>
              <InputForm
                name={`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LNG}`}
                label={t('Objects.New.Form.Lng')}
                placeholder={t('Objects.New.Form.Lng.Placeholder')}
                required
              />

              <InputForm
                name={`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LAT}`}
                label={t('Objects.New.Form.Lat')}
                placeholder={t('Objects.New.Form.Lat.Placeholder')}
                required
              />

              <TimezonesSelect name={OSTROVOK_FIELDS.TIMEZONE} label={t('Objects.New.Form.Timezone')} />

            </Flex>
            <Flex align='center' gap='md' mt='36px' w='100%' justify='flex-end'>
              <Button color='green' type='submit'>
                {t('Action.Add')}
              </Button>
              <Button type='reset' color='gray'>
                {t('Action.Cancel')}
              </Button>
            </Flex>
          </Flex>
        </Paper>
      </Content>
    </Form>
  );
}

export default OstrovokForm;
