import React from 'react';
import { Box } from '@mantine/core';
import { Waypoint } from 'react-waypoint';

import Top from './Top';
import Bottom from './Bottom';
import useHotelPrice from 'hooks/useHotelPrice';
import Gallery from '../../Gallery';

type HotelCardProps = {
  data?: any;
}
function HotelCard({ data: { hotel } }:HotelCardProps) {
  const [{ price, isPending }, { fetch }] = useHotelPrice(hotel);

  if (!hotel.id) return;

  const handleEnter = () => {
    !isPending && !price && fetch();
  };

  return (
    <Waypoint onEnter={handleEnter}>
      <Box mt='56px' maw='936px'>
        <Top hotel={hotel} />

        <Gallery hotel={hotel} />

        <Bottom hotel={hotel} price={price} isPending={isPending} />
      </Box>
    </Waypoint>
  );
}

export default HotelCard;
