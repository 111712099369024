import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Tariffs from './Tariffs';
import Add from './Add';
import Tariff from './Tariff';

function ParsingTariffs() {
  return (
    <Routes>
      <Route path='/' element={<List />}>
        <Route path='/add' element={<Add />} />
      </Route>

      <Route path='/:id/' element={<Tariffs />} />

      <Route path='/:id/tariff/:tariffId' element={<Tariff />} />
    </Routes>
  );
}

export default ParsingTariffs;
