import carouselAddBarIcon from '../icons/carousel-addbar';
import { classNames, SIDE_IMAGES_COUNT } from '../blocks/carousel';

const carouselPlugin = {
  translations: {
    en: {
      carousel: {
        carousel: 'Carousel',
        add: 'Add carousel',
        edit: 'Edit carousel',
        save: 'Save',
        cancel: 'Cancel',
        insert: 'Insert'
      }
    }
  },
  defaults: {
    upload: false,
    select: false,
    name: 'file',
    data: false,
    multiple: true
  },
  popups: {
    add: {
      title: '## carousel.add ##',
      width: '100%',
      footer: {
        insert: {
          title: '## carousel.insert ##',
          command: 'carousel.insert',
          type: 'primary'
        },
        cancel: { title: '## carousel.cancel ##', command: 'popup.close' }
      }
    },
    edit: {
      title: '## carousel.edit ##',
      width: '100%',
      footer: {
        save: {
          title: '## carousel.save ##',
          command: 'carousel.save',
          type: 'primary'
        },
        cancel: { title: '## carousel.cancel ##', command: 'popup.close' }
      }
    }
  },
  start: function () {
    this.app.addbar.add('carousel', {
      title: '## carousel.carousel ##',
      icon: carouselAddBarIcon,
      command: 'carousel.popup'
    });
  },
  popup: function () {
    // create
    const stack = this.app.popup.add('slideshow', this.popups.add);

    // body
    const $body = stack.getBody();

    // box
    this.$box = this.dom('<div>').addClass(this.prefix + '-slideshow-items');
    $body.append(this.$box);

    // upload
    this.$upload = this.app.image.createUploadBox(
      this.opts.carousel.upload, $body
    );

    // select box
    this.app.image.createSelectBox(
      this.opts.carousel.select,
      $body,
      'carousel.insertFromSelect'
    );

    // open
    stack.open();

    // build upload
    this._buildUpload(this.$upload, 'carousel.insertByUpload');
  },
  edit: function (params, button) {
    // create
    const stack = this.app.popup.create('slideshow', this.popups.edit);

    // body
    const $body = stack.getBody();

    // data
    const current = this.app.block.get();
    const $block = current.getBlock();
    const $elms = $block.find('img');

    // box
    this.$box = this.dom('<div>').addClass(this.prefix + '-slideshow-items');
    $body.append(this.$box);

    // items
    $elms.each(function ($node) {
      this._buildBoxItem($node.attr('src'));
    }.bind(this));

    // upload
    this.$upload = this.app.image.createUploadBox(
      this.opts.carousel.upload,
      $body
    );

    // select box
    this.app.image.createSelectBox(
      this.opts.carousel.select,
      $body,
      'carousel.insertFromSelect'
    );

    // open
    this.app.popup.open({ button: button });

    // build upload
    this._buildUpload(this.$upload, 'carousel.insertByUpload');
  },
  insertByUpload: function (response) {
    for (const key in response) {
      this._buildBoxItem(response[key].url);
    }
  },
  insertFromSelect: function (e) {
    e.preventDefault();

    const $target = this.dom(e.target);
    this._buildBoxItem($target.attr('data-url'));
  },
  insert: function () {
    // instance
    const instance = this.app.create('block.carousel');

    this.app.block.add({ instance: instance });

    // save
    this.save();
  },
  save: function () {
    const current = this.app.block.get();

    const $elms = this.$box.find('img');

    if ($elms.length === 0) {
      current.remove();
      this.app.popup.close();
      return;
    }

    const $block = current.getBlock();
    const $imageList = $block.find(`.${classNames.imageList}`);

    // const blockId = current.getId();

    $imageList.html('');

    $elms.each(($node, i) => {
      const src = $node.attr('src');

      const $item = this.dom('<div>').addClass(classNames.imageListItem);
      const $img = this.dom('<img>').attr('src', src);

      $item.append($img);
      $imageList.append($item);
    });

    $block
      .find(`.${classNames.imagesCountIndicator}`)
      .text($elms.length - SIDE_IMAGES_COUNT);

    const currentNumber = Number(
      $block
        .find(`.${classNames.imagesCurrentNumberIndicator}`)
        .nodes[0]
        .textContent
    );

    if (currentNumber > $elms.length - SIDE_IMAGES_COUNT) {
      $block
        .find(`.${classNames.imagesCurrentNumberIndicator}`)
        .text($elms.length - SIDE_IMAGES_COUNT);
    }

    // close popup
    this.app.popup.close();
  },

  // private
  _removeItem: function (e) {
    const $target = this.dom(e.target)
      .closest('.' + this.prefix + '-slideshow-item');

    $target.fadeOut(function ($node) {
      $node.remove();
    });
  },
  _buildBoxItem: function (src) {
    const $item = this.dom('<span>').addClass(this.prefix + '-slideshow-item');
    const $remover = this.dom('<span>').addClass(this.prefix + '-upload-remove');
    const $img = this.dom('<img>').attr('src', src);

    $remover.one('click', this._removeItem.bind(this));

    $item.append($img);
    $item.append($remover);
    this.$box.append($item);
  },
  _buildUpload: function ($item, callback) {
    if (!this.opts.carousel.upload) return;

    const params = {
      box: true,
      placeholder: this.lang.get('image.upload-new-placeholder'),
      url: this.opts.carousel.upload,
      name: this.opts.carousel.name,
      data: this.opts.carousel.data,
      multiple: this.opts.carousel.multiple,
      success: callback,
      error: 'image.error'
    };

    this.app.create('upload', $item, params);
  }
};

export default carouselPlugin;
