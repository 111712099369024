import { createSlice } from '@reduxjs/toolkit';

import articlesAdapter, { initialState } from './adapter';

import {
  deleteArticleAction,
  fetchArticlesAction,
  unpublishArticleAction,
  publishArticleAction,
  updateArticleAction,
  changeTypeArticleAction,
  fetchOneArticlesAction,
  saveArticleAction,
  setArticleTypeAction,
  updateArticleFormAction,
  clearArticleAction,
  fetchMoreArticlesAction
} from './actions';
import createArticleAction from './actions/createArticleAction';
// Actions
const topicsSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeTypeArticleAction, articlesAdapter.articleChangeType)
      .addCase(fetchOneArticlesAction.pending, articlesAdapter.articlesOneFetchPending)
      .addCase(fetchOneArticlesAction.fulfilled, articlesAdapter.articlesOneFetchFulfilled)
      .addCase(fetchArticlesAction.pending, articlesAdapter.articlesFetchPending)
      .addCase(clearArticleAction, articlesAdapter.articlesClear)
      .addCase(fetchArticlesAction.fulfilled, articlesAdapter.articlesFetchFulfilled)
      .addCase(deleteArticleAction.fulfilled, articlesAdapter.articleDeleteFullfilled)
      .addCase(updateArticleAction.fulfilled, articlesAdapter.articleSave)
      .addCase(publishArticleAction.fulfilled, articlesAdapter.articlesUpdateFulfilled)
      .addCase(unpublishArticleAction.fulfilled, articlesAdapter.articlesUpdateFulfilled)
      .addCase(updateArticleFormAction.fulfilled, articlesAdapter.articlesUpdateFulfilled)
      .addCase(saveArticleAction, articlesAdapter.articleSave)
      .addCase(createArticleAction.fulfilled, articlesAdapter.articleCreate)
      .addCase(setArticleTypeAction, articlesAdapter.articleSetType)
      .addCase(fetchMoreArticlesAction.pending, articlesAdapter.articlesFetchMorePending)
      .addCase(fetchMoreArticlesAction.fulfilled, articlesAdapter.articlesFetchMoreFulfilled);
  }
});

export default topicsSlice.reducer;
