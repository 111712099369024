import { useState } from 'react';
import { IRegionDocument, IRegionResponse } from 'types';
import { regionFetch } from 'apis/RegionsApi';

function useRegions() {
  const [regions, setRegions] = useState<IRegionDocument[]>([]);

  const fetch = async (q:string):Promise<any> => {
    const data:IRegionResponse = await regionFetch(q);
    setRegions(data.hits.map((h) => h.document));
  };

  const clear = () => {
    setRegions([]);
  };
  return [{ regions }, { fetch, clear }] as const;
}

export default useRegions;
