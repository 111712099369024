import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

function IFrame({ children, mobile }) {
  const [ref, setRef] = useState(null);
  const mountNode = ref?.contentWindow?.document?.body;
  const headNode = ref?.contentWindow?.document?.head;

  useEffect(() => {
    const cssLink = document.createElement('link');
    cssLink.href = `${process.env.PUBLIC_URL}/css/turbo.css`;
    cssLink.rel = 'stylesheet';
    cssLink.type = 'text/css';
    if (headNode) {
      headNode.appendChild(cssLink);
    }
  }, [headNode]);

  return (
    <iframe ref={setRef} style={{ width: mobile ? '100%' : '788px', margin: '0 auto' }}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
}

IFrame.propTypes = {
  children: PropTypes.any.isRequired,
  mobile: PropTypes.bool
};

export default IFrame;
