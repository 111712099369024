import React from 'react';
import qs from 'qs';
import { IconBook, IconCircleArrowRightFilled } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import getSearchDates from 'utils/getSearchDates';
import { Box, Flex, Text } from '@mantine/core';

const site = process.env.REACT_APP_SITE;

const DEFAULT_PARAMS = {
  ...getSearchDates(),
  travellers: [{ age_group: 'adult', age: 0 }]
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

type Props = {
  data: any;
};

function Region({ data }: Props) {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(
      `${site}/search/list/?${qs.stringify({
        place: {
          ...data.region,
          label: data.region.name,
          id: data.region.place_id
        },
        check_in: DEFAULT_PARAMS.check_in,
        check_out: DEFAULT_PARAMS.check_out
      })}`
    );
  };

  return (
    <Flex
      style={{ cursor: 'pointer' }}
      bg='linear-gradient(89.47deg, #D3D9FC 29.49%, #F5E3FF 99.5%)'
      align='center'
      justify='space-between'
      h='144px'
      p='44px'
      mt='48px'
      onClick={handleClick}
    >
      <Flex align='center' gap='24px'>
        <IconBook size='60px' color='var(--mantine-color-blue-5)' />

        <Box maw='400px'>
          <Text inline fz='22px' lh='30px'>
            {t('RegionCard.Check')}
          </Text>

          <Text inline fz='22px' lh='30px' c='var(--mantine-color-blue-5)'>
            {data.mainText}
          </Text>

          <Text inline fz='22px' lh='30px'>
            ({getRandomInt(50, 100)})
          </Text>
        </Box>
      </Flex>

      <IconCircleArrowRightFilled size='50px' color='white' />
    </Flex>
  );
}

export default Region;
