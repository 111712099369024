import { createAsyncThunk } from '@reduxjs/toolkit';
import { searchAreaFetch } from 'apis/SearchAreaApi';

const ACTION = 'search_area/fetch';

const fetchSearchAreaAction = createAsyncThunk(
  ACTION,
  searchAreaFetch
);

export default fetchSearchAreaAction;
