import React from 'react';
import cx from 'clsx';

import classes from './Main.module.css';

type Props = React.PropsWithChildren<{
  className?: string | undefined;
  onScroll?: (e: any) => void;
}>;

function Main({ children, className, onScroll }: Props) {
  return (
    <div onScroll={onScroll} className={cx(classes.root, className)}>
      {children}
    </div>
  );
}

export default Main;
