import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Button } from '@mantine/core';

import Loader from 'components/ui/Loader';

import Top from './Top';

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

type RightContainerProps = {
  hotel: any;
  price: any;
  isPending: boolean;
};

function RightContainer({ hotel, price, isPending }: RightContainerProps) {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}#roomTypes`);
  };

  const pretiffyPrice = (price) => {
    const n = Math.round(price).toString();
    const separator = ' ';
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + separator);
  };

  const enteredPrice = {
    amount: Number(hotel.price_text.match(/\d+/)[0]),
    currency: price?.currency || 'RUB'
  };

  return (
    <Flex direction='column'>
      <Box>
        <Top hotel={hotel} />
        <Text mt='12px' dangerouslySetInnerHTML={{ __html: hotel.description }} />
      </Box>

      <Flex gap='8px' wrap='wrap' mt='24px'>
        <Flex align='center' gap='8px'>
          {!isPending && enteredPrice?.amount > 0 && (
            <Flex align='center' gap='8px'>
              <Text fz='30px' fw='bold'>
                {pretiffyPrice(price?.amount > 0 ? price.amount : enteredPrice.amount)} ₽
              </Text>

              <Text fz='18px'>за сутки</Text>
            </Flex>
          )}

          {!isPending && enteredPrice?.amount === 0 && (
            <Text c='var(--mantine-color-red-6)' mb='12px'>
              {t('HotelCard.Unavailable')}
            </Text>
          )}

          {isPending && <Loader />}
        </Flex>

        <Button color='green' onClick={handleClick}>
          {t('HotelCard.Action.ShowRooms')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default RightContainer;
