import store from 'store';
import { logoutAction } from 'store/authSlice/actions';

const success = (response) => {
  return response;
};

const error = (error) => {
  if (error.response.status === 401) {
    store.dispatch(logoutAction());
  } else {
    return Promise.reject(error);
  }
};

export default [success, error];
