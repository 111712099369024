import React, { useState } from 'react';
import { Flex, Modal } from '@mantine/core';

import LanguageSelect from 'components/ui/LanguageSelect';
import Group from './Group';

const languages = ['ru', 'en'];

const hideEnContent = process.env.REACT_APP_HIDE_EN_CONTENT || true;

type Props = {
    onClose: () => void;
    data: any;
};

function PreviewArticles({ onClose, data: { content } }:Props) {
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);

  const onChange = (lang) => {
    if (currentLanguage !== lang) {
      setCurrentLanguage(lang);
    }
  };

  return (
    <Modal size='xl' opened onClose={onClose}>
      <Flex direction='column'>
        {!hideEnContent && <LanguageSelect languages={languages} onChange={onChange} language={currentLanguage} />}
        <Flex direction='column'>
          {content[currentLanguage]?.blocks?.map((block) => (
            <Group key={block.id} group={block} />
          ))}
        </Flex>
      </Flex>
    </Modal>
  );
}

export default PreviewArticles;
