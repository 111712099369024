import { RootState } from 'store';
import { IArticleResponse, SHOW_TYPE } from 'types';

export const articlesAllSelector = (state: RootState): [boolean, IArticleResponse[], SHOW_TYPE, string, boolean] => {
  return [
    state.articles.loading,
    state.articles.articles,
    state.articles.type,
    state.articles.stateMore,
    state.articles.hasMore
  ];
};
