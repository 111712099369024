import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import {
  ISearchAreaFetchParams,
  ISearchArea,
  ISearchAreaResponseData,
  ISearchAreaMeta,
  ISearchAreaRequestParamsFull,
  ICatalog
} from 'types';

const API_BASE = '/api/search_area';
const LIMIT = 100;
const SITE = process.env.REACT_APP_SITE;

const handleRequest = async <T>(method: 'get' | 'put', url: string, config?: any): Promise<T> => {
  const response: AxiosResponse<T> = await connection[method](url, config);
  return response.data;
};

export const searchAreaFetch = async ({
  page,
  parent_osm_id
}: {
  page: number;
  parent_osm_id?: string;
}): Promise<ISearchAreaResponseData<ISearchArea[], ISearchAreaMeta>> => {
  const params: ISearchAreaFetchParams = {
    data: {
      filter: { parent_osm_id },
      paginate: {
        page,
        limit: LIMIT
      }
    }
  };

  return handleRequest<ISearchAreaResponseData<ISearchArea[], ISearchAreaMeta>>('get', API_BASE, { params });
};

export const searchAreaFetchOne = (id: string) =>
  handleRequest<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>>('get', `${API_BASE}/${id}`);

export const catalogFetch = (): Promise<ISearchAreaResponseData<ICatalog[], ISearchAreaMeta>> =>
  handleRequest<ISearchAreaResponseData<ICatalog[], ISearchAreaMeta>>('get', `${SITE}/api/pages/seo`);

export const searchAreaUpdate = async ({
  id,
  data
}: {
  id: string;
  data: ISearchAreaRequestParamsFull;
}): Promise<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>> =>
  handleRequest<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>>('put', `${API_BASE}/${id}`, { data });
