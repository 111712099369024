import React from 'react';
import { Flex, Paper, Text, Modal } from '@mantine/core';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

type DocumentsProps = {
    data: any
}

function Documents({ data }:DocumentsProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const documents = data.list.map((doc, index) => {
    return (
      <Flex direction='column' key={index}>
        <Text fw='500' dangerouslySetInnerHTML={{ __html: doc.title }} />
        <Text fz='14px' mb='xs' dangerouslySetInnerHTML={{ __html: doc.description }} />
      </Flex>
    );
  });

  return (
    <Paper p='lg' bg='var(--mantine-color-blue-2)'>
      <Flex align='center' gap='xs' w='100%'>
        <IconInfoCircleFilled size={30} color='var(--mantine-color-blue-5)' />

        <Flex direction='column' maw='250px'>
          <Text fz='14px' variant='transparent' p='0'><b onClick={open} style={{ cursor: 'pointer' }}>Документы</b>, которые вам</Text>
          <Text fz='14px'>
            потребуются для поездки
          </Text>
        </Flex>

      </Flex>
      <Modal size='md' opened={opened} centered onClose={close} title={<Text fz='30px' fw='600'>Документы, которые вам потребуются для поездки</Text>}>
        {documents}
      </Modal>
    </Paper>
  );
}
export default Documents;
