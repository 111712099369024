import { useState, useCallback } from 'react';
import { IRegionDocument, IRegionHit } from 'types';
import hotelsApi from 'apis/HotelsApi';

type useHotels = {
  (): [
    IRegionDocument[],
    {
      fetch: (q: string) => void;
      clear: () => void;
    }
  ];
};

const useHotels: useHotels = () => {
  const [regions, setHotels] = useState<IRegionDocument[]>([]);

  const fetch = useCallback((q: string) => {
    hotelsApi.fetchHotels(q).then((data) => {
      setHotels(data.data.map((region: IRegionHit) => region.document));
    });
  }, []);

  const clear = () => {
    setHotels([]);
  };

  return [
    regions,
    {
      fetch,
      clear
    }
  ];
};

export default useHotels;
