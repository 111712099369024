// Resizing types:
// fit: resizes the image while keeping aspect ratio to fit given size;
// fill: resizes the image while keeping aspect ratio to fill given size and cropping projecting parts;
// auto: if both source and resulting dimensions have the same orientation (portrait or landscape), imgproxy will use fill. Otherwise, it will use fit.

// Width and height
// Width and height parameters define the size of the resulting image in pixels. Depending on the resizing type applied, the dimensions may differ from the requested ones.

// Gravity
// When imgproxy needs to cut some parts of the image, it is guided by the gravity. The following values are supported:

// no: north (top edge);
// so: south (bottom edge);
// ea: east (right edge);
// we: west (left edge);
// noea: north-east (top-right corner);
// nowe: north-west (top-left corner);
// soea: south-east (bottom-right corner);
// sowe: south-west (bottom-left corner);
// ce: center;
// sm: smart. libvips detects the most “interesting” section of the image and considers it as the center of the resulting image;
// fp:%x:%y - focus point. x and y are floating point numbers between 0 and 1 that describe the coordinates of the center of the resulting image. Treat 0 and 1 as right/left for x and top/bottom for y.

// Enlarge
// When set to 1, t or true, imgproxy will enlarge the image if it is smaller than the given size.

// Extension
// Extension specifies the format of the resulting image. At the moment, imgproxy supports only jpg, png, webp, gif, ico, and tiff, them being the most popular and useful image formats.

const defaultOpts = {
  resize: 'fill',
  width: 0,
  height: 0,
  gravity: 'sm',
  enlarge: 1,
  extension: 'webp',
  defaultExtension: 'jpg'
};

const cdnUrl = process.env.REACT_APP_IMAGE_CDN || 'https://cdn.sanatory.ru';

function getImage(encoded_url, options = {}) {
  const {
    resize,
    width,
    height,
    gravity,
    enlarge,
    extension,
    defaultExtension
  } = { ...defaultOpts, ...options };

  const path = `/${resize}/${width}/${height}/${gravity}/${enlarge}/${encoded_url}.${extension}`;
  const defaultPath = `/${resize}/${width}/${height}/${gravity}/${enlarge}/${encoded_url}.${defaultExtension}`;
  const image = {
    src: [cdnUrl, path].join(''),
    defaultSrc: [cdnUrl, defaultPath].join('')
  };

  return image;
}

export { getImage };
