import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Hotels from './Hotels';

function Reports() {
  return (
    <Routes>
      <Route path='/availability' element={<Hotels />} />
    </Routes>
  );
}

export default Reports;
