import React from 'react';
import { Text, Flex, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import Rate from '../Rate';
import { IRate } from 'types/parsing';

type Props = React.PropsWithChildren<{
  rates: IRate[];
}>;

function Rates({ rates }: Props) {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap='4px'>
      <Text c='var(--mantine-color-gray-8)'>{t('Tariffs.Rates.Title')}</Text>

      <Box>
        {rates.map((rate, index) => (
          <Rate rate={rate} key={index} />
        ))}
      </Box>
    </Flex>
  );
}

export default Rates;
