import React from 'react';
import { Box } from '@mantine/core';
import cx from 'clsx';

import classes from './TopBar.module.css';

type Props = React.PropsWithChildren<{
  className?: string | undefined;
  transparent?: boolean;
}>;

function TopBar({ children, transparent, className }: Props) {
  return (
    <Box className={cx(className, classes.root, transparent ? classes.backgroundLight : classes.backgroundDark)}>
      {children}
    </Box>
  );
}

export default TopBar;
