import React from 'react';
import { List, ThemeIcon, rem, Text } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

type ListProps = {
  data: any;
};

function ListComponent({ data }: ListProps) {
  return (
    <List spacing='xs' size='sm' center>
      {data.items.map((element, index) => (
        <List.Item
          icon={
            <ThemeIcon color='teal' size={24} radius='xl'>
              <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          }
          key={index}
        >
          <Text dangerouslySetInnerHTML={{ __html: element }} />
        </List.Item>
      ))}
    </List>
  );
}

export default ListComponent;
