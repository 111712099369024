import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { IconPlus, IconX } from '@tabler/icons-react';
import { Flex, Button, Text, ActionIcon } from '@mantine/core';

import InputForm from 'components/ui/InputForm';
import { AUTHORS_FIELDS } from '../schema';
import { AuthorResponse } from 'types/authors';

type Props = {
  author?: AuthorResponse;
};

function AwardsForm({ author }: Props) {
  const arrFieldName = 'awards';
  const { fields, append, remove } = useFieldArray({
    name: arrFieldName
  });

  useEffect(() => {
    if (author?.awards?.length) {
      remove();
      author.awards.forEach((award) => {
        append({ name: award.name });
      });
    }
  }, [author, append, remove]);

  const handleAddClick = (ev) => {
    ev.preventDefault();
    append({});
  };

  const handleRemoveClick = (index) => (ev) => {
    ev.preventDefault();
    remove(index);
  };

  return (
    <Flex direction='column' gap='8px'>
      <Text fw='500'>Награды</Text>

      {fields.map((item, index) => (
        <Flex key={item.id} justify='space-between' align='center' gap='md'>
          <InputForm
            width='100%'
            label={`Награда ${index + 1}`}
            name={`${arrFieldName}.${index}.${AUTHORS_FIELDS.NAME_FIELD}`}
            placeholder='Введите награду'
            defaultValue={item[AUTHORS_FIELDS.NAME_FIELD] || ''}
          />

          <ActionIcon onClick={handleRemoveClick(index)} size='sm' variant='light' color='red' mt='lg'>
            <IconX size={24} />
          </ActionIcon>
        </Flex>
      ))}

      <Button onClick={handleAddClick} size='sm' variant='light' leftSection={<IconPlus size={18} />}>
        Добавить награду
      </Button>
    </Flex>
  );
}

export default AwardsForm;
