const bookIcon = `
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.67107 0C3.67042 0 3.66969 0 3.66905 0C3.44205 0 3.22856
      0.0884853 3.06734 0.249294C2.90435 0.411881 2.81458 0.628367
      2.81458 0.858833V13.7369C2.81458 14.2091 3.20027 14.5943
      3.67446 14.5955C5.67351 14.6003 9.0227 15.0169 11.3332
      17.4348V3.95752C11.3332 3.79744 11.2923 3.64705 11.2151
      3.52261C9.31878 0.46869 5.67464 0.0046869 3.67107 0Z" fill="#2E7CD5"
    />

    <path
      d="M21.1856 13.737V0.858833C21.1856 0.628367 21.0958 0.411881
      20.9328 0.249294C20.7716 0.0884853 20.5579 0 20.3312 0C20.3305
      0 20.3297 0 20.3291 0C18.3256 0.00476771 14.6814 0.46877
      12.785 3.52269C12.7079 3.64713 12.6671 3.79752 12.6671
      3.9576V17.4348C14.9775 15.0169 18.3267 14.6003 20.3258
      14.5955C20.7999 14.5943 21.1856 14.2091 21.1856 13.737Z" fill="#2E7CD5"
    />

    <path
      d="M23.1414 2.9707H22.5187V13.7377C22.5187 14.9435 21.5363 15.9267
      20.3287 15.9296C18.6331 15.9337 15.8373 16.2652 13.8572
      18.1393C17.2817 17.3008 20.8918 17.8459 22.9492 18.3147C23.2061
      18.3732 23.4715 18.3128 23.6773 18.1487C23.8824 17.9851 24.0001
      17.7405 24.0001 17.4779V3.82937C24.0002 3.35592 23.6149 2.9707
      23.1414 2.9707Z" fill="#2E7CD5"
    />

    <path
      d="M1.48138 13.7377V2.9707H0.858672C0.385295 2.9707 0 3.35592
      0 3.82937V17.4777C0 17.7403 0.117657 17.9849 0.322749
      18.1485C0.528407 18.3125 0.793621 18.3731 1.05092
      18.3145C3.1083 17.8455 6.71842 17.3006 10.1428 18.139C8.1628
      16.2651 5.36698 15.9336 3.67138 15.9296C2.46385 15.9267
      1.48138 14.9435 1.48138 13.7377Z" fill="#2E7CD5"
    />
  </svg>
`;

const sourcesLinkSnippet = {
  name: 'SourcesLink',
  html: `
    <div class="sources-link">
      <div class="left-container">
        <div class="icon">${bookIcon}</div>
      </div>

      <div class="right-container">
        <p class="title">
          Ссылки на источники
        </p>

        <p class="description">
          При создании статей наши авторы всегда используют научную литературу
        </p>

        <div class="adm-link-block">
          Список ссылок на источники
        </div>

        <div class="link-block">
          <p class="link-close">
            Посмотреть все ссылки
          </p>

          <p class="link-open">
            Скрыть все ссылки
          </p>
        </div>

        <dl>
          <dt>Диетология простым языком. Основы правильного питания</dt>

          <dd>https://zen.yandex.ru/media/id/5cc17bf5bfff6400b302</dd>
        </dl>
      </div>
    </div>
  `
};

export default sourcesLinkSnippet;
