import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Modal } from '@mantine/core';

import { useAppDispatch, useAppSelector } from 'store';
import { articleSelector } from 'store/articlesSlice/selectors';
import { fetchOneArticlesAction, updateArticleFormAction } from 'store/articlesSlice/actions';

import Form from 'components/ui/Form';
import RegionsForm, { validationSchema } from 'pages/Regions/components/RegionsForm';
import { getFormattedFormData } from 'pages/Regions/utils';
import type { RegionsFormFields } from 'types';

function EditForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, article] = useAppSelector(articleSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOneArticlesAction(id));
  }, []);

  const handleSubmit = async (formData: RegionsFormFields) => {
    const formattedData = getFormattedFormData(formData);

    dispatch(
      updateArticleFormAction({
        article: formattedData,
        id
      })
    );

    navigate('/regions');
  };

  const handleReset = () => {
    navigate('/regions');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  if (!article) return null;

  return (
    <Modal
      size='lg'
      title={<Text fz='24px'>{t('Region.Form.Edit')}</Text>}
      centered
      onClose={handleReset}
      withCloseButton={false}
      opened
    >
      <Form
        schema={validationSchema()}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <RegionsForm article={article} />
      </Form>
    </Modal>
  );
}

export default EditForm;
