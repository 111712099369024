const templateData = {
  list: [
    {
      title: 'Бальнео- и гидротерапия',
      includes: []
    },
    {
      title: 'Грязелечение',
      includes: []
    },
    {
      title: 'Физиотерапия',
      includes: []
    },
    {
      title: 'Альтернативные методики',
      includes: []
    },
    {
      title: 'Массажи',
      includes: []
    },
    {
      title: 'Спа',
      includes: []
    },
    {
      title: 'Уникальные процедуры',
      includes: []
    }
  ]
};

export default templateData;
