import React from 'react';
import { Image } from '@mantine/core';

import { getImage } from 'utils/getImage';

type Props = {
  data : any
}

function ImagePreview({ data, ...rest }:Props) {
  const image = getImage(data.file.encoded_url, { width: 1200, height: 600 });

  return <Image mt='60px' radius='md' src={image.defaultSrc} {...rest} />;
}

export default ImagePreview;
