import React from 'react';
import PropTypes from 'prop-types';
import { IconStarFilled } from '@tabler/icons-react';

function Stars({ stars }) {
  return (
    <div className='stars_wrapper'>
      <div className='stars_container'>
        {
          Array.from({ length: stars }).map((_, idx) => (
            <div className='star_container' key={idx}>
              <IconStarFilled color='orange' className='star_container_svg' />
            </div>
          ))
          }
      </div>
    </div>
  );
}

Stars.propTypes = {
  stars: PropTypes.number.isRequired
};

export default Stars;
