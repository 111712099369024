import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import settings from './Editor.settings';
import { Box, Textarea } from '@mantine/core';

function Editor({ content, contentRef, ArticleEditor, ...rest }) {
  const wrapperRef = useRef();

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    const editorInstance = new ArticleEditor(
      wrapperRef.current,
      {
        ...settings(rest),
        content: content
      }
    );

    contentRef.current = editorInstance;

    return () => {
      try {
        editorInstance.destroy();
      } catch {
        return undefined;
      }
    };
  }, []);

  return (
    <Box w='820px' p='32px 0'>
      <Textarea ref={wrapperRef} />
    </Box>
  );
}

Editor.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  autoSaveUrl: PropTypes.string.isRequired,
  uploadImgUrl: PropTypes.string.isRequired,
  selectImgUrl: PropTypes.string.isRequired,
  contentRef: PropTypes.any,
  ArticleEditor: PropTypes.func.isRequired
};

export default Editor;
