import React from 'react';
import { Box, Divider, Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import SelectedRegion from '../RegionSelect/SelectedRegion';
import RegionSelectForm from 'components/ui/RegionSelectForm';
import { IRegionDocument } from 'types';

type Object = {
  area_id: string;
  type: string;
  name: string;
};

type Props = React.PropsWithChildren<{
  name?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: ({ area_id, type, name }: Object) => void;
  onChangeRadius: (radius: number, index: number) => void;
  onDelete: (id: string) => void;
  searchAdditionals: any[];
}>;

function HotelSearchArea({ onSelect, onDelete, searchAdditionals, onChangeRadius, name }: Props) {
  const { t } = useTranslation();

  const handleSelect = async (regionObj: IRegionDocument) => {
    regionObj &&
      onSelect({
        type: 'hotels',
        area_id: String(regionObj.place_id),
        name: regionObj.label_ru
      });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const selectedRegionComponents = searchAdditionals
    ?.filter(({ type }) => type === 'hotels')
    // eslint-disable-next-line @typescript-eslint/ban-types
    ?.map((region: Object, index) => (
      <SelectedRegion
        key={region.area_id}
        region={region}
        handleDelete={onDelete}
        searchWithHotels
        index={index}
        onChangeRadius={onChangeRadius}
      />
    ));

  return (
    <Box>
      <Text fz={14}>Показывать также объекты размещения</Text>

      <RegionSelectForm
        onSelect={handleSelect}
        name={name}
        clearAfterSelect
        type='hotels'
        placeholder={t('Common.Name.Placeholder')}
      />

      <Flex direction='column' gap='xs' mt='md'>
        {selectedRegionComponents}
      </Flex>
    </Box>
  );
}

export default HotelSearchArea;
