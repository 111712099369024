import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Button } from '@mantine/core';
import { IconList, IconBorderAll } from '@tabler/icons-react';

import { useAppDispatch, useAppSelector } from 'store';
import { TopBar, Content, Main } from 'components/ui/Layout';
import Loader from 'components/ui/Loader';
import BlogCards from 'components/ui/BlogCards';
import DisplayType from 'components/ui/DisplayType';

import { ARTICLE_FILTER_TYPE, SHOW_TYPE, type IArticleResponse } from 'types';

import {
  changeTypeArticleAction,
  deleteArticleAction,
  fetchArticlesAction,
  publishArticleAction,
  unpublishArticleAction
} from 'store/articlesSlice/actions';
import { articlesAllSelector } from 'store/articlesSlice/selectors';

const types = [
  {
    name: SHOW_TYPE.LIST,
    icon: <IconList />
  },
  {
    name: SHOW_TYPE.REGION,
    icon: <IconBorderAll />
  }
];

function List() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, articles, type] = useAppSelector(articlesAllSelector);

  const handleChange = (t: `${SHOW_TYPE}`) => {
    dispatch(changeTypeArticleAction(t));
  };

  useEffect(() => {
    dispatch(fetchArticlesAction(ARTICLE_FILTER_TYPE.REGION));
  }, []);

  const handleAdd = () => {
    navigate('/regions/add');
  };

  const handleEdit = (id: IArticleResponse['id']) => {
    navigate(`/regions/${id}/edit`);
  };

  const handleEditForm = (id: IArticleResponse['id']) => {
    navigate(`/regions/${id}/editform`);
  };

  const handleEditAdditional = (id: IArticleResponse['id']) => {
    navigate(`/regions/${id}/edit-additional`);
  };

  const handleDelete = async (blog: IArticleResponse) => {
    dispatch(deleteArticleAction(blog));
  };

  const handlePublish = (id: IArticleResponse['id'], checked: boolean) => {
    const action = checked ? publishArticleAction : unpublishArticleAction;

    dispatch(action(id));
  };

  if (loading || !articles.length) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  return (
    <Main>
      <TopBar>
        <Text fz='h1' fw='500'>
          {t('Regions.Title')}
        </Text>
        <DisplayType types={types} type={type} onChange={handleChange} />
        <Button color='green' onClick={handleAdd}>
          {t('Regions.Add')}
        </Button>
      </TopBar>

      <Content>
        <BlogCards
          type={type}
          sitePath='region'
          data={articles}
          onEdit={handleEdit}
          onEditForm={handleEditForm}
          onDelete={handleDelete}
          onPublish={handlePublish}
          onEditAdditional={handleEditAdditional}
        />
      </Content>

      <Outlet />
    </Main>
  );
}

export default List;
