import React from 'react';
import PropTypes from 'prop-types';

import { getImage } from 'utils/getImage';

function Image({ data, ...rest }) {
  const image = getImage(data.file.encoded_url, { width: 1200, height: 600 });

  return <img className='image_wrapper' src={image.defaultSrc} {...rest} />;
}

Image.propTypes = {
  data: PropTypes.object
};

export default Image;
