import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesFetchOne } from 'apis/ArticlesApi';

const ACTION = 'articles/fetchOne';

const fetchOneArticlesAction = createAsyncThunk(
  ACTION,
  articlesFetchOne
);

export default fetchOneArticlesAction;
