import React from 'react';
import { Flex } from '@mantine/core';
import MenuItem from './MenuItem';
import { NavigationItem } from 'types/menu';

type MenuProps = {
  data: NavigationItem[];
};

function Menu({ data }: MenuProps) {
  return (
    <Flex direction='column'>
      {data.map((menu, index: number) => (
        <MenuItem data={menu} key={index} />
      ))}
    </Flex>
  );
}

export default Menu;
