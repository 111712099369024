import { type RegionRequest, type RegionsFormFields } from 'types';

export const getFormattedFormData = (formData: RegionsFormFields) => {
  const formattedData: RegionRequest = {
    type: formData.type,
    place: formData.place,
    title: {
      ru: formData?.title_ru || null,
      en: formData?.title_en || null
    },
    sub_title: {
      ru: formData?.sub_title_ru || null,
      en: formData?.sub_title_en || null
    },

    // при создании записи будет title_ru, при редактировании seo_title_ru
    seo_title: {
      ru: formData?.seo_title_ru || formData?.title_ru || null,
      en: formData?.seo_title_en || formData?.title_en || null
    },

    // при создании записи будет sub_title_ru, при редактировании seo_description_ru
    seo_description: {
      ru: formData?.seo_description_ru || formData?.sub_title_ru || null,
      en: formData?.seo_description_en || formData?.sub_title_en || null
    },
    meta_tags: [
      {
        name: 'Description',
        property: null,
        content: formData?.seo_description_ru || formData?.sub_title_ru || null
      },
      ...formData.metaTags
    ]
  };

  return formattedData;
};
