import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum SEARCH_AREA_FIELDS {
  IS_SEARCHABLE = 'is_searchable',
  SEARCH_MODE = 'search_mode',
  SEARCH_RADIUS = 'search_radius',
  SORT = 'sort_basis',
  SEARCH_ADDITIONALS = 'search_additionals',
  SLUG = 'slug'
}

export const validationSchema = () =>
  yup.object({
    [SEARCH_AREA_FIELDS.SEARCH_RADIUS]: yup
      .number()
      .min(0, 'Целое число, начиная с ноля')
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Обязательное поле')
  });

export const defaultValues = {};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
