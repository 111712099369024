import Ui from './Ui';
import './index.css';
import _isEmpty from 'lodash/isEmpty';

import { showError, clearError } from '../utils/errorHandlers';

const DEFAULT_DATA = {
  title: 'Введите заголовок',
  list: ['Введите описание']
};

class Facts {
  static get toolbox() {
    return {
      icon: '<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"><path d="M32.12,10H3.88A1.88,1.88,0,0,0,2,11.88V30.12A1.88,1.88,0,0,0,3.88,32H32.12A1.88,1.88,0,0,0,34,30.12V11.88A1.88,1.88,0,0,0,32.12,10ZM32,30H4V12H32Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M8.56,19.45a3,3,0,1,0-3-3A3,3,0,0,0,8.56,19.45Zm0-4.6A1.6,1.6,0,1,1,7,16.45,1.6,1.6,0,0,1,8.56,14.85Z" class="clr-i-outline clr-i-outline-path-2"></path><path d="M7.9,28l6-6,3.18,3.18L14.26,28h2l7.46-7.46L30,26.77v-2L24.2,19a.71.71,0,0,0-1,0l-5.16,5.16L14.37,20.5a.71.71,0,0,0-1,0L5.92,28Z" class="clr-i-outline clr-i-outline-path-3"></path><path d="M30.14,3h0a1,1,0,0,0-1-1h-22a1,1,0,0,0-1,1h0V4h24Z" class="clr-i-outline clr-i-outline-path-4"></path><path d="M32.12,7V7a1,1,0,0,0-1-1h-26a1,1,0,0,0-1,1h0V8h28Z" class="clr-i-outline clr-i-outline-path-5"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>',
      title: 'Список фактов (советов)'
    };
  }

  static get sanitize() {
    return true;
  }

  constructor({ data }) {
    this._data = _isEmpty(data) ? DEFAULT_DATA : data;
    const title = this._data.title;
    const list = this._data.list;

    this.ui = new Ui({
      title,
      list,
      onAdd: this._onAdd,
      onRemove: this._onRemove
    });

    this._error = this._data.error || null;
  }

  save() {
    const errors = this._validate();
    const { list, title } = this.ui.getData();

    return { list, title, errors };
  }

  _validate = () => {
    const { ui } = this;
    const errors = [];
    const { list, title } = ui.getData();

    if (!title) {
      showError(errors, 'Список фактов (советов). Заголовок не может быть пустым', ui.displayErrorTitle);
    } else {
      clearError(ui.displayErrorTitle);
    }

    list.forEach((item, index) => {
      if (!item) {
        showError(
          errors,
          `Список фактов (советов). Описание блока(${index}) не может быть пустым`,
          ui.displayErrorItem,
          index
        );
      } else {
        clearError(ui.displayErrorItem, index);
      }
    });

    return errors;
  };

  _onRemove = (index) => {
    const { list } = this.ui.getData();

    this.ui.list = list.filter((_, i) => index !== i);

    this.render();
  };

  _onAdd = () => {
    const { list } = this.ui.getData();

    this.ui.list = [...list, ...DEFAULT_DATA.list];

    this.render();
  };

  render() {
    return this.ui.render();
  }
}

export default Facts;
