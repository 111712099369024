import React, { useEffect } from 'react';
import { Flex, Text, Button } from '@mantine/core';
import { useNavigate, Outlet } from 'react-router-dom';

import useReviewers from 'pages/Authors/hooks/useReviewers';
import { Main, TopBar, Content } from 'components/ui/Layout';

import ReviewerCard from './ReviewerCard';
import Loader from 'components/ui/Loader';

function List() {
  const navigate = useNavigate();
  const [data, { fetchList, deleteReviewer }] = useReviewers();

  useEffect(() => {
    fetchList();
  }, []);

  const handleAdd = () => {
    navigate('/reviewers/add');
  };

  const handleEdit = (id: string) => {
    navigate(`/reviewers/${id}/edit`);
  };

  const handleEditAdditional = (id: string) => {
    navigate(`/reviewers/${id}/edit-additional`);
  };

  if (!data) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  return (
    <Main>
      <TopBar>
        <Text fz='h1' fw='500'>
          Авторы
        </Text>
        <Button color='green' onClick={handleAdd}>
          Добавить автора
        </Button>
      </TopBar>

      <Content>
        <Flex gap='md' wrap='wrap'>
          {data.map((reviewer) => (
            <ReviewerCard
              key={reviewer.id}
              reviewer={reviewer}
              onDelete={deleteReviewer}
              onEdit={handleEdit}
              onEditAdditional={handleEditAdditional}
            />
          ))}
        </Flex>
      </Content>

      <Outlet />
    </Main>
  );
}

export default List;
