import React from 'react';
import PropTypes from 'prop-types';

function Paragraph({ data }) {
  return (
    <div className='paragraph_wrapper' dangerouslySetInnerHTML={{ __html: data.text }} />
  );
}

Paragraph.propTypes = {
  data: PropTypes.object
};

export default Paragraph;
