import React from 'react';
import { Title, Box } from '@mantine/core';

interface HeaderData {
  text: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

interface HeaderProps {
  data: HeaderData;
  className?: string;
}

const headerStyles = {
  1: {
    fontSize: 42,
    lineHeight: '48px'
  },
  2: {
    fontSize: 30,
    lineHeight: '32px'
  },
  3: {},
  4: {},
  5: {},
  6: {}
};

function Header({ data, className, ...rest }: HeaderProps) {
  return (
    <Box
      className={className}
      mt={28}
      fz='20px'
    >
      <Title
        order={data.level}
        dangerouslySetInnerHTML={{ __html: data.text }}
        style={(theme) => ({
          fontWeight: 600,
          ...headerStyles[data.level]
        })}
        {...rest}
      />
    </Box>
  );
}

export default Header;
