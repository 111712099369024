import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Tabs, Box } from '@mantine/core';

import { Main, TopBar } from 'components/ui/Layout';
import SuccessModal from './SuccessModal';
import SanatoryForm from './SanatoryForm/SanatoryForm';
import { SANATORY, OSTROVOK } from './constants';
import OstrovokForm from './OstrovokForm/OstrovokForm';
import { getProviderTranslate } from '../List/getProviderTranslate';

function AddObject() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  return (
    <Main>
      <Tabs color='green' defaultValue={SANATORY}>
        <TopBar>
          <Box>
            <Text fz={14} c='var(--mantine-color-gray-6)'>
              {t('Objects.New.Title')}
            </Text>
            <Text fz={30}>{t('Objects.New.Subtitle')}</Text>
          </Box>
          <Tabs.List>
            <Tabs.Tab fz='20px' value={SANATORY}>
              {getProviderTranslate(SANATORY)}
            </Tabs.Tab>
            <Tabs.Tab fz='20px' value={OSTROVOK}>
              {getProviderTranslate(OSTROVOK)}
            </Tabs.Tab>
          </Tabs.List>
          <Box />
        </TopBar>

        <Tabs.Panel value={SANATORY}>
          <SanatoryForm provider={SANATORY} setOpenModal={setOpenModal} setModalData={setModalData} />
        </Tabs.Panel>
        <Tabs.Panel value={OSTROVOK}>
          <OstrovokForm provider={OSTROVOK} setOpenModal={setOpenModal} setModalData={setModalData} />
        </Tabs.Panel>
      </Tabs>
      <SuccessModal setOpenModal={setOpenModal} openModal={openModal} modalData={modalData} />
    </Main>
  );
}

export default AddObject;
