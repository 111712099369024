import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import useReviewers from '../../hooks/useReviewers';
import { Main, TopBar, Content } from 'components/ui/Layout';
import Top from './Top';
import Body from './Body';

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef();

  const [value, { fetchOne, update }] = useReviewers();

  useEffect(() => {
    fetchOne(id);
  }, [id]);

  if (!value.length) {
    return null;
  }

  const handleSubmit = async () => {
    if (!contentRef.current) {
      return;
    }
    // @ts-ignore */
    const content = contentRef.current.editor.getContent();
    const { error } = await update(id, {
      ...value[0],
      content
    });

    if (!error) {
      navigate('/reviewers');
    }
  };

  const handleReset = () => {
    navigate('/reviewers');
  };

  return (
    <Main>
      <TopBar>
        <Top reviewer={value[0]} onSubmit={handleSubmit} onReset={handleReset} />
      </TopBar>
      <Content>
        <Body reviewer={value[0]} contentRef={contentRef} />
      </Content>
    </Main>
  );
}

export default Edit;
