import Ui from './Ui';
import './index.css';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import Uploader from '../utils/Uploader';
import { showError, clearError } from '../utils/errorHandlers';

const DEFAULT_DATA = {
  title: 'Введите заголовок',
  items: [
    {
      description: 'Введите описание',
      title: 'Введите заголовок',
      image: {}
    }
  ]
};

class Treatments {
  static get toolbox() {
    return {
      icon: '<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"><path d="M32.12,10H3.88A1.88,1.88,0,0,0,2,11.88V30.12A1.88,1.88,0,0,0,3.88,32H32.12A1.88,1.88,0,0,0,34,30.12V11.88A1.88,1.88,0,0,0,32.12,10ZM32,30H4V12H32Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M8.56,19.45a3,3,0,1,0-3-3A3,3,0,0,0,8.56,19.45Zm0-4.6A1.6,1.6,0,1,1,7,16.45,1.6,1.6,0,0,1,8.56,14.85Z" class="clr-i-outline clr-i-outline-path-2"></path><path d="M7.9,28l6-6,3.18,3.18L14.26,28h2l7.46-7.46L30,26.77v-2L24.2,19a.71.71,0,0,0-1,0l-5.16,5.16L14.37,20.5a.71.71,0,0,0-1,0L5.92,28Z" class="clr-i-outline clr-i-outline-path-3"></path><path d="M30.14,3h0a1,1,0,0,0-1-1h-22a1,1,0,0,0-1,1h0V4h24Z" class="clr-i-outline clr-i-outline-path-4"></path><path d="M32.12,7V7a1,1,0,0,0-1-1h-26a1,1,0,0,0-1,1h0V8h28Z" class="clr-i-outline clr-i-outline-path-5"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>',
      title: 'Оздоровление'
    };
  }

  static get sanitize() {
    return true;
  }

  constructor({ data, config }) {
    this.data = _cloneDeep(_isEmpty(data) ? DEFAULT_DATA : data);

    const title = this.data.title;
    const items = this.data.items;
    this._error = this.data.error || null;

    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      captionPlaceholder: 'Caption',
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined,
      actions: config.actions || []
    };

    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response, index) => this.onUpload(response, index),
      onError: (error) => console.log(error)
    });

    this.ui = new Ui({
      title,
      items,
      onTemplate: this._onTemplate,
      onDrag: this.onPaste,
      onAdd: this._onAdd,
      onRemove: this._onRemove,
      onSelectFile: (index) => {
        this.uploader.uploadSelectedFile(index);
      }
    });
  }

  _onTemplate = (el, index) => {
    this.data.items[index].image = el.image;
    this.data.items[index].title = el.title;
    this.ui.items = this.data.items;

    this.render();
  }

  onUpload = (data, index) => {
    this.data.items[index].image = data;
    this.ui.items = this.data.items;

    this.render();
  }

  save() {
    const errors = this._validate();
    const { items, title } = this.ui.getData();

    return { items, title, errors };
  }

  _validate = () => {
    const { ui } = this;
    const { items, title } = ui.getData();
    const errors = [];

    if (!title) {
      showError(errors, 'Оздоровление. Заголовок не может быть пустым', ui.displayErrorTitle);
    } else {
      clearError(ui.displayErrorTitle);
    }

    items.forEach((item, index) => {
      if (!item.title) {
        showError(errors, `Оздоровление. Заголовок блока(${index}) не может быть пустым`, ui.displayErrorItemTitle, index);
      } else {
        clearError(ui.displayErrorItemTitle, index);
      }

      if (!item.description) {
        showError(errors, `Оздоровление. Описание блока(${index}) не может быть пустым`, ui.displayErrorItemDescription, index);
      } else {
        clearError(ui.displayErrorItemDescription, index);
      }

      if (Object.values(item.image).length === 0) {
        showError(errors, `Оздоровление. Изображение блока(${index}) не может быть пустым`, ui.displayErrorItemImage, index);
      } else {
        clearError(ui.displayErrorItemImage, index);
      }
    });

    return errors;
  }

  static get enableLineBreaks() {
    return true;
  }

  _onRemove = (index) => {
    const { items } = this.ui.getData();

    this.data.items = items.filter((_, i) => index !== i);

    this.ui.items = this.data.items;

    this.render();
  }

  _onAdd = () => {
    const { items } = this.ui.getData();

    this.data.items = [
      ...items,
      {
        description: 'Введите описание',
        title: 'Введите заголовок',
        image: {}
      }
    ];

    this.ui.items = this.data.items;

    this.render();
  }

  _updateTitle = (title) => {
    this.data.title = title;
  }

  uploadFile(file, index) {
    this.uploader.uploadByFile(file, index);
  }

  onPaste = (ev, index) => {
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();

          if (file.type?.includes('image')) {
            this.uploadFile(file, index);
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        if (ev.dataTransfer.files[i].type?.includes('image')) {
          this.uploadFile(ev.dataTransfer.files[i], index);
        }
      }
    }
  }

  render() {
    return this.ui.render();
  }
}

export default Treatments;
