import React, { useState } from 'react';
import { Box, Text } from '@mantine/core';
import { IconCircleArrowRightFilled, IconCircleArrowLeftFilled } from '@tabler/icons-react';

type FactsProps = {
  data: any;
};
function Facts({ data }: FactsProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const facts = data.list;

  const handleNext = () => {
    setActiveIndex(activeIndex + 1);
  };

  const handlePrev = () => {
    setActiveIndex(activeIndex === 0 ? facts.length - 1 : activeIndex - 1);
  };

  return (
    <Box bg='#eef9e4' maw='833px' mt='65px' pos='relative' p='32px' mih='287px'>
      <Text fz='36px' fw='600' dangerouslySetInnerHTML={{ __html: data.title }} />
      <Text fz='20px' mt='16px' dangerouslySetInnerHTML={{ __html: facts[activeIndex % facts.length] }} />

      <Box style={{ cursor: 'pointer' }} pos='absolute' left='-8px' top='50%'>
        <IconCircleArrowLeftFilled size='40px' onClick={handlePrev} />
      </Box>

      <Box style={{ cursor: 'pointer' }} pos='absolute' right='-8px' top='50%'>
        <IconCircleArrowRightFilled size='40px' onClick={handleNext} />
      </Box>
    </Box>
  );
}

export default Facts;
