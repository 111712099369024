import React from 'react';
import { createRoot } from 'react-dom/client';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import { MantineProvider } from '@mantine/core';
import { Provider } from 'react-redux';
import store from 'store';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import theme from './theme';
import i18nConfig from './config/i18n';
import App from 'pages/App';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './main.css';
import reportWebVitals from './reportWebVitals';

import SubdomainLanguageDetect from './SubdomainLanguageDetect';

declare global {
  interface Window {
    i18n: typeof i18n;
  }
}

i18n
  .use(LanguageDetector)
  .use(ICU)
  .use(initReactI18next)
  .init({
    ...i18nConfig,
    keySeparator: false
  });

dayjs.locale('ru');
window.i18n = i18n;

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <SubdomainLanguageDetect />
        <MantineProvider theme={theme}>
          <App />
        </MantineProvider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
