import React, { useEffect, useState } from 'react';

import SelectForm from 'components/ui/SelectForm';
import categoriesApi from 'apis/CategoriesApi/CategoriesApi';
import { type IOption } from 'types';

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  required?: boolean;
};

const CategoriesSelect = ({ label, placeholder, name, required = false }: Props) => {
  const [categories, setCategories] = useState<IOption[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await categoriesApi.fetchList();

        Array.isArray(data) &&
          setCategories(
            data.map((option) => ({
              value: option.tag,
              label: option.name
            }))
          );
      } catch (e) {
        console.log(e, 'err');
      }
    };

    fetch();
  }, []);

  return (
    <SelectForm
      width='100%'
      name={name}
      label={label}
      options={categories}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default CategoriesSelect;
