import React from 'react';
import dateFormat from 'utils/dateFormat';
import { Flex, Table, Text, Button } from '@mantine/core';

import Switch from 'components/ui/Switch';
import CardPopover from 'components/ui/Card/Popover';

import { type BlogCardsProps, type IArticleResponse } from 'types';

function ListCard({
  sitePath,
  blog,
  onEdit,
  onPublish,
  onDelete,
  onEditForm
}: Omit<BlogCardsProps, 'type' | 'data'> & { blog: IArticleResponse }) {
  const { title, published, published_at, category } = blog;

  return (
    <Table.Tr style={{ '--table-border-color': 'var(--mantine-color-gray-3)' }}>
      <Table.Td maw={category ? '300px' : '200px'}>
        <Text fz='sm'>{title.ru}</Text>
      </Table.Td>

      <Table.Td maw='200px'>
        {published_at && (
          <Text fz='sm' c={published ? 'var(--mantine-color-green-5)' : 'var(--mantine-color-gray-6)'}>
            {published ? `Опубликовано ${dateFormat(published_at)}` : `Снято с публикации ${dateFormat(published_at)}`}
          </Text>
        )}
      </Table.Td>

      {category && (
        <Table.Td>
          <Text fz='sm'>{category.name}</Text>
        </Table.Td>
      )}

      <Table.Td>
        <Flex gap='xs' align='center'>
          <Button color='gray' onClick={() => onEdit(blog.id)} size='xs'>
            Редактировать
          </Button>

          <CardPopover blog={blog} sitePath={sitePath} onEditForm={onEditForm} onDelete={onDelete} />
        </Flex>
      </Table.Td>

      <Table.Td>
        <Switch value={published} onChange={(checked: boolean) => onPublish(blog.id, checked)} />
      </Table.Td>
    </Table.Tr>
  );
}

export default ListCard;
