import Ui from './Ui';
import './index.css';
import _isEmpty from 'lodash/isEmpty';

import getTranslate from 'utils/getTranslate';

import { showError, clearError } from '../utils/errorHandlers';
import templateData from './templateData';

class Procedures {
  static get toolbox() {
    return {
      icon: '<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"><path d="M32.12,10H3.88A1.88,1.88,0,0,0,2,11.88V30.12A1.88,1.88,0,0,0,3.88,32H32.12A1.88,1.88,0,0,0,34,30.12V11.88A1.88,1.88,0,0,0,32.12,10ZM32,30H4V12H32Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M8.56,19.45a3,3,0,1,0-3-3A3,3,0,0,0,8.56,19.45Zm0-4.6A1.6,1.6,0,1,1,7,16.45,1.6,1.6,0,0,1,8.56,14.85Z" class="clr-i-outline clr-i-outline-path-2"></path><path d="M7.9,28l6-6,3.18,3.18L14.26,28h2l7.46-7.46L30,26.77v-2L24.2,19a.71.71,0,0,0-1,0l-5.16,5.16L14.37,20.5a.71.71,0,0,0-1,0L5.92,28Z" class="clr-i-outline clr-i-outline-path-3"></path><path d="M30.14,3h0a1,1,0,0,0-1-1h-22a1,1,0,0,0-1,1h0V4h24Z" class="clr-i-outline clr-i-outline-path-4"></path><path d="M32.12,7V7a1,1,0,0,0-1-1h-26a1,1,0,0,0-1,1h0V8h28Z" class="clr-i-outline clr-i-outline-path-5"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>',
      title: 'Процедуры'
    };
  }

  static get sanitize() {
    return true;
  }

  constructor({ data, config }) {
    const { lang } = config;
    const { t } = getTranslate(lang);
    this.t = t;

    this.defaultData = {
      list: [
        {
          title: t('Procedures.Data.Title'),
          includes: [
            t('Procedures.Data.Includes')
          ]
        }
      ]
    };

    this._data = _isEmpty(data) ? this.defaultData : data;
    const list = this._data.list;

    this.ui = new Ui({
      t,
      list,
      toList: this._toList,
      onParseFull: this._onParseFull,
      onParsePart: this._onParsePart,
      onAdd: this._onAdd,
      onRemove: this._onRemove,
      onAddInclude: this._onAddInclude,
      onRemoveInclude: this._onRemoveInclude,
      onApplyTemplate: this._applyTemplate
    });
  }

  _onParseFull = () => {
    const list = this.ui.getParseData();

    this.ui.list = list;

    this.render();
  };

  _onParsePart = () => {
    const { list } = this.ui.getData();
    const data = this.ui.getParseData();

    this.ui.list = [
      ...list,
      ...data
    ];

    this.render();
  };

  _applyTemplate = () => {
    this.ui.list = templateData.list;

    this.render();
  };

  save() {
    const errors = this._validate();
    const { list } = this.ui.getData();

    return { list, errors };
  }

  static get enableLineBreaks() {
    return true;
  }

  _onAddInclude = (index) => {
    const { list } = this.ui.getData();

    list[index].includes = [
      ...list[index].includes,
      ...this.defaultData.list[0].includes
    ];

    this.ui.list = list;

    this.render();
  };

  extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  _toList = (index, parentIndex) => {
    const { list, listDevelop } = this.ui.getData();

    const arr = listDevelop[parentIndex].includes[index]
      .split('\n')
      .map((el) => this.extractContent(el))
      .filter((el) => el.length > 0);

    list[parentIndex].includes = [
      ...list[parentIndex].includes.slice(0, index),
      ...arr,
      ...list[parentIndex].includes.slice(index + 1)
    ];

    this.ui.list = list;

    this.render();
  };

  _onRemoveInclude = (index, parentIndex) => {
    const { list } = this.ui.getData();
    list[parentIndex].includes = list[parentIndex].includes.filter((data, ind) => ind !== index);

    this.ui.list = list;

    this.render();
  };

  _onRemove = (index) => {
    const { list } = this.ui.getData();

    this.ui.list = list.filter((_, i) => index !== i);

    this.render();
  };

  _onAdd = () => {
    const { list } = this.ui.getData();

    this.ui.list = [
      ...list,
      ...this.defaultData.list
    ];

    this.render();
  };

  _validate = () => {
    const { ui } = this;
    const { list } = ui.getData();
    const errors = [];

    list.forEach((item, index) => {
      if (!item.title) {
        showError(errors, this.t('Procedures.Error.Title', { number: index + 1 }), ui.displayErrorItemTitle, index);
      } else {
        clearError(ui.displayErrorItemTitle, index);
      }

      if (item.includes.length === 0) {
        showError(errors, this.t('Procedures.Error.Includes', { number: index + 1 }), ui.displayErrorItemIncludes, index);
      } else {
        clearError(ui.displayErrorItemIncludes, index);
      }
    });

    return errors;
  };

  render() {
    return this.ui.render();
  }
}

export default Procedures;
