import React, { useState } from 'react';
import { Modal, Flex } from '@mantine/core';

import BestReview from './BestReview';
import Header from './Header';
import Map from './Map';
import Gallery from './Gallery';
import { IObjectData, IObjectDataFull } from 'types';

import LanguageSelect from 'components/ui/LanguageSelect';
import Content from './Content';

import { fromBlocksMapper } from 'utils/hotelMapper';
import Blogcard from './Blogcard';
import NewYear from './NewYearBlock';
import Documents from './Documents';

const languages = ['ru', 'en'];

type Props = {
  onClose: () => void;
  object: IObjectData;
  hotel: any;
  fetchHotel: (lang: string) => void;
};

function Preview({ onClose, object, hotel, fetchHotel }: Props) {
  const [currentLanguage, setCurrentLanguage] = useState<string>(languages[0]);
  const mappedObject: IObjectDataFull = fromBlocksMapper(object, currentLanguage);
  const blocks = mappedObject.body_translations[currentLanguage]?.blocks;
  const newYearBlock = blocks?.find((block) => block.type === 'newYear');
  const documentsBlock = blocks?.find((block) => block.type === 'documents');

  const handleChangeLanguage = (val: string): void => {
    setCurrentLanguage(val);

    fetchHotel(val);
  };

  return (
    <Modal opened onClose={onClose} size={1284}>
      <Flex direction='column'>
        <LanguageSelect languages={languages} onChange={handleChangeLanguage} language={currentLanguage} />

        <Flex mt='xs' gap='xs'>
          <Flex direction='column' maw='810px' miw='810px'>
            <Header hotel={hotel} object={mappedObject} />

            <Gallery hotel={hotel} />

            <BestReview object={mappedObject} />

            <Content hotel={hotel} object={mappedObject} currentLanguage={currentLanguage} key={currentLanguage} />
          </Flex>

          <Flex direction='column' gap='xs'>
            {mappedObject.blogCard && <Blogcard data={mappedObject.blogCard} />}

            {documentsBlock && <Documents data={mappedObject.documents} />}

            <Map hotel={hotel} object={mappedObject} currentLanguage={currentLanguage} />

            {newYearBlock && <NewYear />}
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}
export default Preview;
