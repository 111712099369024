export function showError(errors, text, callbackFunc, index) {
  const componentRef = callbackFunc(true, index);

  errors.push({
    text,
    componentRef
  });
}

export function clearError(callbackFunc, index) {
  callbackFunc(false, index);
}
