import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Text, Select, Flex } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import cx from 'clsx';

import { Main, TopBar, Content } from 'components/ui/Layout';
import Loader from 'components/ui/Loader';
import classes from './Hotels.module.css';
import useAvailabilities from 'hooks/useAvailabilities';

import Hotel from './Hotel';
import { IAvailabilitiesResponse } from 'types/availabilities';
import { IOption } from 'types';

const HotelRow = React.memo(Hotel);

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const options: IOption[] = [
  {
    label: 'Свободные номера',
    value: 'free'
  },
  {
    label: 'Занятые номера',
    value: 'busy'
  },
  {
    label: 'Квота',
    value: 'quota'
  },
  {
    label: 'Стоимость за сутки',
    value: 'price_per_day_min_in_rub'
  }
];

const sourceOptions: IOption[] = [
  {
    label: 'Группа «Согаз»',
    value: '5a27ea5273e8b0489f2768cb'
  },
  {
    label: 'Sanatory.ru',
    value: '5ba103be73e8b035cb40c8da'
  }
];

function Hotels({ className }: Props) {
  const { t } = useTranslation();

  const [value, setValue] = useState('free');
  const from = dayjs().startOf('month');
  const to = from.add(11, 'month').endOf('month');
  const [date, setDate] = useState<[Date, Date]>([from.toDate(), to.toDate()]);
  const [source, setSource] = useState<string>('5a27ea5273e8b0489f2768cb');

  const [{ data, isPending, isPendingMore }, { fetch, fetchMore }]: any = useAvailabilities();

  const from_date = dayjs(date[0]).format('YYYY-MM-DD');
  const to_date = dayjs(date[1]).format('YYYY-MM-DD');

  useEffect(() => {
    if (!date[0] || !date[1]) return;

    const fetchFunction = async (params: any) => {
      await fetch(params);
    };

    fetchFunction({ from_date, to_date, source_id: source });
  }, [date, source]);

  const handleChange = (val: any) => {
    setValue(val);
  };

  const handleChangeSource = (val: any) => {
    setSource(val);
  };

  const handleChangeDate = (val: any) => {
    setDate(val);
  };

  const handleScroll = (e: any) => {
    const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 2000;

    if (bottom && !isPendingMore && !isPending) {
      fetchMore({ from_date, to_date, source_id: source });
    }
  };

  return (
    <Main className={cx(className, classes.root)}>
      <TopBar>
        <Text fz='24px' fw='500'>
          {t('Available.Title')}
        </Text>

        <Flex gap='md' align='center'>
          <DatePickerInput
            w='250px'
            label='Дата отчета'
            valueFormat='D MMM YYYY'
            clearable
            numberOfColumns={2}
            value={date}
            locale='ru'
            onChange={handleChangeDate}
            type='range'
          />

          <Select
            data={sourceOptions}
            label='Источник продаж'
            value={source}
            className={classes.source}
            onChange={handleChangeSource}
          />

          <Select
            data={options}
            label='Ражим отображения'
            value={value}
            onChange={handleChange}
          />
        </Flex>
      </TopBar>

      <Content onScroll={handleScroll}>
        {isPending && (
          <Flex h='100vh' w='100%' align='center'>
            <Loader />
          </Flex>
        )}

        {data && data.map((d: IAvailabilitiesResponse) => (
          <HotelRow
            data={d}
            variant={value}
            key={d.hotel.id}
          />
        ))}

        {isPendingMore && <Box><Loader /></Box>}
      </Content>

      <Outlet />
    </Main>
  );
}

export default Hotels;
