import { createSlice } from '@reduxjs/toolkit';

import { authenticateAction, loginAction, logoutAction } from './actions';
import loginAdapter, { initialState } from './adapter';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, loginAdapter.loginPending)
      .addCase(loginAction.fulfilled, loginAdapter.loginFulfilled)
      .addCase(loginAction.rejected, loginAdapter.loginRejected)
      .addCase(logoutAction.fulfilled, loginAdapter.logoutFulfilled)
      .addCase(logoutAction.pending, loginAdapter.logoutPending)
      .addCase(logoutAction.rejected, loginAdapter.logoutRejected)
      .addCase(authenticateAction.fulfilled, () => {});
  }
});

export default authSlice.reducer;
