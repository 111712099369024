import React from 'react';
import { Box, Flex, Text } from '@mantine/core';

import classes from './RatingElement.module.css';
import { IObjectReviewCategory } from 'types';

type Props = {
  element: IObjectReviewCategory;
};

function BestReviewElement({ element }: Props) {
  return (
    <Flex gap='12px' align='center'>
      <Text>{element.name}</Text>

      <Box className={classes.slider}>
        <Box className={classes.sliderLine} style={{ '--rating': element.rating } as React.CSSProperties} />
      </Box>

      <Text>{element.rating}</Text>
    </Flex>
  );
}
export default BestReviewElement;
