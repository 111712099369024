import React from 'react';
import { Box, Flex, Text } from '@mantine/core';

import SelectedRegion from './SelectedRegion';
import { IRegionDocument } from 'types';
import RegionSelectForm from 'components/ui/RegionSelectForm';
import { useTranslation } from 'react-i18next';

type Object = {
  area_id: string;
  type: string;
  name: string;
  radius: number;
};

type Props = React.PropsWithChildren<{
  name?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: ({ area_id, type, name }: Object) => void;
  onChangeRadius: (radius: number, index: number) => void;
  onDelete: (id: string) => void;
  searchAdditionals: any[];
  disabled: boolean;
}>;

function CustomRegionSelect({ onSelect, onDelete, searchAdditionals, onChangeRadius, disabled, name }: Props) {
  const { t } = useTranslation();
  const handleSelect = async (regionObj: IRegionDocument) => {
    regionObj &&
      onSelect({
        type: 'region',
        area_id: String(regionObj.place_id),
        name: regionObj.label_ru,
        radius: 50
      });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const selectedRegionComponents = searchAdditionals
    ?.filter(({ type }) => type === 'region')
    // eslint-disable-next-line @typescript-eslint/ban-types
    ?.map((region: Object, index) => (
      <SelectedRegion
        key={region.area_id}
        region={region}
        handleDelete={onDelete}
        onChangeRadius={onChangeRadius}
        index={index}
        disabled={disabled}
      />
    ));

  return (
    <Box>
      <Text fz={14}>Показывать также объекты областей</Text>
      <RegionSelectForm name={name} onSelect={handleSelect} clearAfterSelect placeholder={t('Common.Name.Placeholder')} />
      <Flex direction='column' gap='xs' mt='md'>{selectedRegionComponents}</Flex>
    </Box>
  );
}

export default CustomRegionSelect;
