import getTranslate from 'utils/getTranslate';
import cancellationDeadline from './cancellationDeadline';

function getCancellationRules({ cancellation_policy }) {
  const { t } = getTranslate();
  const { policy_rules } = cancellation_policy;

  let days = 0;

  if (policy_rules.length > 0 && policy_rules[0].deadline.unit === 'day') {
    days = policy_rules[0].deadline.amount;
  }

  if (cancellation_policy.has_full_refund) {
    return [t('Tariffs.Cancellation.FullRefund')];
  }

  if (cancellation_policy.has_non_refund) {
    return [t('Tariffs.Cancellation.NonRefund')];
  }

  return cancellation_policy.policy_rules.map(rule => {
    return cancellationDeadline(rule);
  });
}

export default getCancellationRules;
