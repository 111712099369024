import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Text } from '@mantine/core';

import Form from 'components/ui/Form';
import AuthorsForm from 'pages/Authors/components/Form';
import { extendDefaultValues, validationSchema } from 'pages/Authors/components/Form/schema';
import { toastify } from 'utils/toastify';
import useReviewers from '../../hooks/useReviewers';
import { AuthorRequest } from 'types/authors';
import { getFormattedFormData } from '../../utils';

function Add() {
  const navigate = useNavigate();
  const [, { create }] = useReviewers();

  const handleSubmit = async (formData: AuthorRequest & { specializations: string[] }) => {
    const formattedData = getFormattedFormData(formData);

    try {
      const { data, error } = await create(formattedData);

      if (!error) {
        toastify('success', 'Автор создан');
        navigate(`/reviewers/${data.id}/edit`);
      }
    } catch (error) {
      console.error('Error creating reviewer:', error);
      toastify('error', 'Возникла ошибка');
    }
  };

  const handleReset = () => {
    navigate('/reviewers/');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  return (
    <Modal
      size='lg'
      opened
      centered
      onClose={handleReset}
      title={
        <Text fz='20' fw='500'>
          Добавить автора
        </Text>
      }
    >
      <Form
        schema={validationSchema()}
        defaultValues={extendDefaultValues({})}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <AuthorsForm />
      </Form>
    </Modal>
  );
}

export default Add;
