const likeIcon = `
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4.40247V11.4025C0 11.8857 0.391731 12.2775 0.875
      12.2775H3.5V3.52747H0.875C0.391731 3.52747 0 3.9192 0 4.40247Z"
    />

    <path
      d="M12.3842 4.40228H9.79932C9.57331 4.40228 9.46009 4.25147
      9.42206 4.18652C9.38404 4.12201 9.30713 3.94983 9.41651
      3.75244L10.3278 2.11139C10.5278 1.75207 10.5483 1.32953
      10.3846 0.952266C10.221 0.574581 9.89801 0.300716 9.49853
      0.201168L8.85595 0.0405234C8.69958 0.00121677 8.53381
      0.0512046 8.42486 0.172116L4.93597 4.04809C4.5741 4.45099
      4.375 4.97052 4.375 5.51141V10.0898C4.375 11.2959 5.35638
      12.2773 6.5625 12.2773L10.9149 12.2768C11.8975 12.2768
      12.7653 11.615 13.0246 10.6678L13.9586 6.3817C13.9859 6.2625
      14 6.14031 14 6.01812C14 5.12731 13.275 4.40227 12.3841
      4.40227L12.3842 4.40228Z"
    />
  </svg>
`;

const hotelSnippet = {
  name: 'Hotel',
  html: `
    <div class='hotel-snippet'>
      <div class='img-container'>
        <img />
      </div>

      <div class='content-container'>
        <div class='header-container'>
          <p>Введите название</p>
        </div>

        <div class='additional-info-container'>
          <div class='raiting-container'>
            ${likeIcon}

            <div class='arx-text raiting-value'>10</div>
          </div>

          <div class='address-container'>
            <div class='arx-text address-value'>Введите город</div>
          </div>
        </div>

        <div class='description-container'>
          <div class='arx-text description-value'>Введите описание</div>
        </div>

        <div class='bottom-container'>
          <div class='bottom-left-container'>
            <div class='tariff-container'>
              <div class='price-container'>
                <span>от</span>

                <div class='arx-text price-value'>0000</div>

                <span>₽</span>
              </div>

              <div class='guests-and-period-container'>
                <div class='arx-text period-value'>за 7 дней</div>

                <span class='guests-and-period-divider'>/</span>

                <div class='arx-text guests-value'>1 гость</div>
              </div>
            </div>
          </div>

          <div class='bottom-right-container'>
            <div class='arx-text show-rooms-button'>Показать номера</div>
          </div>
        </div>
      </div>
    </div>
  `
};

export default hotelSnippet;
