import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Modal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useAppDispatch } from 'store';
import { toastify } from 'utils/toastify';
import { SANATORY } from '../../AddObject/constants';
import { getProviderTranslate } from '../../List/getProviderTranslate';
import migrateObjectsAction from 'store/objectsSlice/actions/migrateObjectsActions';
import SuccessModal from '../../AddObject/SuccessModal';
import useSources from '../../AddObject/SanatoryForm/useSources';
import { SANATORY_PROVIDER_FIELDS, validationSchema } from './schema';
import Form from 'components/ui/Form';
import InputForm from 'components/ui/InputForm';
import DatePickerForm from 'components/ui/DatePickerForm';

type SanatoryProviderFormProps = {
    setShowModal: (b: boolean)=>void;
    showModal: boolean;
    id: string;
}

function SanatoryProviderModal({ setShowModal, showModal, id }:SanatoryProviderFormProps) {
  const { t } = useTranslation();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useAppDispatch();

  // @ts-ignore
  const [sources, { fetch }] = useSources();

  useEffect(() => {
    fetch();
  }, []);

  const handleSubmit = async (data:any) => {
    const formatedData = {
      ...data,
      provider: SANATORY,
      sources: _.map(data.sources, (source, index) => ({
        ...source,
        // @ts-ignore
        insurance_id: sources?.[index].id,
        contracts: _.filter(source?.contracts, (contract) => contract?.commission_in_percent !== undefined).map((contract) => ({
          ...contract

        }))
      })).filter(Boolean)
    };

    const result = await dispatch(migrateObjectsAction({ object: formatedData, id }));

    // @ts-ignore
    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      setShowModal(false);
      setOpenSuccessModal(true);
      setModalData(result?.payload?.meta);
    }
  };

  const handleReset = () => {
    setShowModal(false);
    setOpenSuccessModal(false);
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  return (
    <>
      <Modal
        withCloseButton={false} centered closeOnClickOutside={false} onClose={() => setShowModal(false)}
        opened={showModal}
      >
        <Form
          schema={validationSchema()}
          onSubmit={handleSubmit}
          onError={handleError}
          onReset={handleReset}
        >

          <Text fz='30px'>{t('Objects.Provider.Ostrovok.Title')}</Text>
          <Text fz='30px'>{getProviderTranslate(SANATORY)}</Text>
          <Box my='lg'>
            <InputForm
              name={SANATORY_PROVIDER_FIELDS.EMAIL}
              label={t('Objects.New.Form.Email')}
              placeholder={t('Objects.New.Form.Email.Placeholder')}
              required
            />
          </Box>

          <Flex gap='md' direction='column'>
            {/* @ts-ignore */}
            {sources?.map(({ name, id }, index) => {
              return (
                <Flex key={id} gap='md' direction='column'>
                  <Text fz='20px'>{name}</Text>
                  <Flex gap='md'>
                    <InputForm
                      width='100%'
                      name={`${SANATORY_PROVIDER_FIELDS.SOURCES}.${index}.${SANATORY_PROVIDER_FIELDS.CONTRACTS}.${index}.${SANATORY_PROVIDER_FIELDS.NUMBER}`}
                      label={t('Objects.New.Form2.Number')}
                      placeholder={t('Objects.New.Form2.Number.Placeholder')}
                      required
                    />

                    <DatePickerForm
                      required
                      format='DD.MM.YYYY'
                      width='100%'
                      placeholder='дд.мм.гггг'
                      label={t('Objects.New.Form2.Date')}
                      name={`${SANATORY_PROVIDER_FIELDS.SOURCES}.${index}.${SANATORY_PROVIDER_FIELDS.CONTRACTS}.${index}.${SANATORY_PROVIDER_FIELDS.DATE}`}
                    />

                    <InputForm
                      width='100%'
                      name={`${SANATORY_PROVIDER_FIELDS.SOURCES}.${index}.${SANATORY_PROVIDER_FIELDS.CONTRACTS}.${index}.${SANATORY_PROVIDER_FIELDS.COMMISSION}`}
                      label={t('Objects.New.Form2.Commission')}
                      placeholder={t('Objects.New.Form2.Commission.Placeholder')}
                      required
                      type='number'
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>

          <Flex mt='lg' align='center' gap='md'>
            <Button type='submit' color='green'>
              {t('Action.Save')}
            </Button>
            <Button type='reset' color='gray'>
              {t('Action.Cancel')}
            </Button>
          </Flex>
        </Form>
      </Modal>
      <SuccessModal setOpenModal={setOpenSuccessModal} openModal={openSuccessModal} modalData={modalData} />
    </>
  );
}

export default SanatoryProviderModal;
