import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesDelete } from 'apis/ArticlesApi';
import { IArticleResponse } from 'types';

const ACTION = 'articles/delete';

const deleteArticle = createAsyncThunk(
  ACTION,
  async (data: IArticleResponse): Promise<IArticleResponse> => {
    await articlesDelete(data.id);

    return data;
  }
);

export default deleteArticle;
