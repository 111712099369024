import React from 'react';
import { Box, Flex, Text, Accordion } from '@mantine/core';

type WhatToSeeProps = {
    data:any
}

function WhatToSee({ data }:WhatToSeeProps) {
  const tabs = data.list.map((tab, index) => (
    <Accordion.Item key={index} value={tab.title}>
      <Accordion.Control bg='var(--mantine-color-gray-2)'>
        <Text fz='18px' fw='500' dangerouslySetInnerHTML={{ __html: tab.title }} />
      </Accordion.Control>
      <Accordion.Panel bg='var(--mantine-color-gray-2)'>
        <Text dangerouslySetInnerHTML={{ __html: tab.description }} />
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Box mt='56px'>
      <Text fz='36px' fw='600' dangerouslySetInnerHTML={{ __html: data.title }} />
      <Accordion>
        <Flex direction='column' gap='16px'>
          {tabs}
        </Flex>
      </Accordion>
    </Box>
  );
}

export default WhatToSee;
