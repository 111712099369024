import connection from 'connection/instance';

const API_BASE = '/api/filters/timezones';

const sortByFormattedName = (data) =>
  data.sort((a, b) => a?.formatted_name.localeCompare(b?.formatted_name));

const handleRequest = async (method, url) => {
  try {
    const response = await connection[method](url);
    const sortedData = sortByFormattedName(response.data.data);
    return { data: sortedData, error: undefined };
  } catch (e) {
    return { data: undefined, error: e };
  }
};

class TimezonesApi {
  fetchList() {
    return handleRequest('get', API_BASE);
  }
}

export default new TimezonesApi();
