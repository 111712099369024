import React, { useState } from 'react';
import { Box, Text, Group, ActionIcon, Anchor } from '@mantine/core';
import { IconBook } from '@tabler/icons-react';

import { useTranslation } from 'react-i18next';

interface LinkData {
  title: string;
  description: string;
  list: Array<any>; // Здесь нужно определить тип для элементов списка
}

interface LinksProps {
  data: LinkData;
}

function Links({ data }: LinksProps) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);
  const elementsComponent = data.list.map((element, index) => (
    <Anchor
      key={index}
      href={element.href}
      data-counter
      mt='6px'
      c='var(--mantine-color-blue-5)'
      style={{ cursor: 'pointer' }}
      display='flex'
    >
      <Text dangerouslySetInnerHTML={{ __html: element.title }} />
    </Anchor>
  ));

  const buttonText = opened ? t('Blog.Links.Action.Open') : t('Blog.Links.Action.Close');

  return (
    <Group align='flex-start' mt={40}>
      <Box>
        <ActionIcon size={44} radius='xl'>
          <IconBook size={24} />
        </ActionIcon>
      </Box>

      <Box ml={14} mt={6}>
        <Text fz='24px' fw='600' dangerouslySetInnerHTML={{ __html: data.title }} />

        <Text dangerouslySetInnerHTML={{ __html: data.description }} mt='16px' />

        <Text onClick={() => setOpened(!opened)} c='#2e7cd6' fz='18px' fw='600' style={{ cursor: 'pointer' }}>
          {buttonText}
        </Text>

        {opened && <Box mt={20}>{elementsComponent}</Box>}
      </Box>
    </Group>
  );
}

export default Links;
