import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Modal } from '@mantine/core';

import { useAppDispatch, useAppSelector } from 'store';
import { fetchOneArticlesAction, updateArticleFormAction } from 'store/articlesSlice/actions';
import { articleSelector } from 'store/articlesSlice/selectors';

import Form from 'components/ui/Form';
import AddBlogForm, { validationSchema } from 'pages/BlogNew//components/AddBlogForm';
import { getFormattedFormData } from 'pages/BlogNew/utils';

import type { ArticleFormFields } from 'types';

function EditForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, article] = useAppSelector(articleSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchOneArticlesAction(id));
  }, []);

  const handleSubmit = async (formData: ArticleFormFields) => {
    const formattedData = getFormattedFormData(formData);

    await dispatch(
      updateArticleFormAction({
        article: formattedData,
        id
      })
    );

    navigate('/articles');
  };

  const handleReset = () => {
    navigate('/articles');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  if (!article) {
    return null;
  }

  return (
    <Modal
      size='lg'
      title={<Text fz='24px'>{t('Blog.Form.Edit')}</Text>}
      centered
      onClose={handleReset}
      withCloseButton={false}
      opened
    >
      <Form schema={validationSchema()} onSubmit={handleSubmit} onError={handleError} onReset={handleReset}>
        <AddBlogForm article={article} />
      </Form>
    </Modal>
  );
}

export default EditForm;
