import { startAppListening } from '../../listenerMiddleware';

import { toastify } from 'utils/toastify';

import { loginAction } from '../actions';

startAppListening({
  actionCreator: loginAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось войти в систему');
  }
});
