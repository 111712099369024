import { Box } from '@mantine/core';
import React from 'react';

type Props = React.PropsWithChildren;

function CommonPart({ children }: Props) {
  return <Box mt='md'>{children}</Box>;
}

export default CommonPart;
