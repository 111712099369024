export interface IMenuItem {
  label: string;
  exact: boolean;
  items: IMenuItemChild[];
}

export interface IMenuItemChild {
  label: string;
  exact: boolean;
  href: string;
}

const items: IMenuItem[] = [
  {
    label: 'Блог',
    exact: true,
    // href: '/blog',
    items: [
      {
        label: 'Статьи',
        exact: false,
        href: '/articles'
      },
      {
        label: 'Авторы',
        exact: false,
        href: '/reviewers'
      }
    ]
  },
  {
    label: 'Регионы',
    items: [
      {
        label: 'Статьи',
        exact: false,
        href: '/regions'
      }
    ],
    exact: true
  },
  {
    label: 'Заболевания',
    items: [
      {
        label: 'Статьи',
        exact: false,
        href: '/diseases'
      }
    ],
    exact: true
  },
  {
    label: 'Области поиска',
    items: [
      {
        label: 'Список',
        exact: false,
        href: '/search_area'
      }
    ],
    exact: true
  },
  {
    label: 'Объекты размещения',
    items: [
      {
        label: 'Объекты размещения',
        exact: false,
        href: '/objects'
      }
    ],
    exact: true
  },
  {
    label: 'Отчеты',
    items: [
      {
        label: 'Календарь доступности',
        exact: false,
        href: '/reports/availability'
      }
    ],
    exact: true
  },
  {
    label: 'Парсинг',
    items: [
      {
        label: 'Тарифы',
        exact: false,
        href: '/parsing'
      }
    ],
    exact: true
  }
];

export default items;
