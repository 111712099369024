function hotelMapper({ id, images, description, address, name, rating, star, slug, price, review, price_text, videos, ...rest }) {
  return {
    address,
    name,
    review,
    price_text,
    slug,
    id,
    description,
    price,
    rating,
    star,
    image: images.length > 0 && images[0],
    videos
  };
}

export default hotelMapper;
