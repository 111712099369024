import React from 'react';
import { Flex, Text } from '@mantine/core';

type Props = {
    data: any;
}
function About({ data }: Props) {
  return (
    <Flex direction='column' gap='24px' mt='65px' maw='936px'>
      <Text fz='36px' fw='600' dangerouslySetInnerHTML={{ __html: data.title }} />
      <Text fz='20px' dangerouslySetInnerHTML={{ __html: data.description }} />
    </Flex>
  );
}

export default About;
