import { useState, useCallback } from 'react';
import { IRegionDocument } from 'types';
import { regionFetch } from 'apis/RegionsApi';

type useRegions = {
  (): [
    IRegionDocument[],
    {
      fetch: (q: string) => void;
      clear: () => void;
    }
  ];
};

const useRegions: useRegions = () => {
  const [regions, setRegions] = useState<IRegionDocument[]>([]);

  const fetch = useCallback((q: string) => {
    regionFetch(q).then((data) => {
      setRegions(data.hits.map((region) => region.document));
    });
  }, []);

  const clear = useCallback(() => {
    setRegions([]);
  }, []);

  return [
    regions,
    {
      fetch,
      clear
    }
  ];
};

export default useRegions;
