import _uniqId from 'lodash/uniqueId';

export default class Ui {
  constructor({ rating, t }) {
    this.rating = rating;
    this.t = t;

    this.uniqId = _uniqId('stars_');

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      title: make('h2', this.CSS.title, {
        innerHTML: t('Stars.Title')
      }),
      container: make('div', this.CSS.container)
    };

    this.nodes.wrapper.appendChild(this.nodes.title);
  }

  get CSS() {
    return {
      wrapper: 'stars',
      title: 'stars_title',
      radioButton: 'stars_radio',
      label: 'stars_label',
      radioWrapper: 'stars_radio_wrapper',
      container: 'stars_container'
    };
  }

  getData() {
    const titleElement = this.nodes.wrapper.querySelector(`.${this.CSS.title}`);

    return {
      title: titleElement.innerHTML,
      rating: this.nodes.wrapper.querySelector(`input[name="${this.uniqId}"]:checked`).value
    };
  }

  render() {
    this.renderContainer();
    this.nodes.wrapper.appendChild(this.nodes.container);

    return this.nodes.wrapper;
  }

  renderContainer() {
    for (let i = 0; i < 6; i++) {
      const radio = make('input', this.CSS.radioButton, {
        type: 'radio',
        name: this.uniqId,
        value: i,
        checked: this.rating === i,
        id: `${this.uniqId}${i}`
      });

      const label = make('label', this.CSS.label, {
        for: `${this.uniqId}${i}`,
        innerHTML: i
      });

      const radioWrapper = make('div', this.CSS.radioWrapper);

      radioWrapper.appendChild(label);
      radioWrapper.appendChild(radio);

      this.nodes.container.appendChild(radioWrapper);
    }
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
