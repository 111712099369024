import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum ADD_DISEASE_FIELDS {
  TITLE_FIELD_RU = 'title_ru',
  SUB_TITLE_FIELD_RU = 'sub_title_ru',
  SEO_TITLE_FIELD_RU = 'seo_title_ru',
  SEO_DESCRIPTION_FIELD_RU = 'seo_description_ru',
  META_TAGS_FIELD= 'meta_tags',
  TYPE_FIELD= 'type',
}

export const validationSchema = () =>
  yup.object({
    [ADD_DISEASE_FIELDS.TITLE_FIELD_RU]: yup.string().required('Обязательное поле'),
    [ADD_DISEASE_FIELDS.SUB_TITLE_FIELD_RU]: yup.string().required('Обязательное поле')
    // [ADD_DISEASE_FIELDS.SEO_TITLE_FIELD_RU]: yup.string().required('Обязательное поле'),
    // [ADD_DISEASE_FIELDS.SEO_DESCRIPTION_FIELD_RU]: yup.string().required('Обязательное поле')
  });

export const defaultValues = {
  [ADD_DISEASE_FIELDS.TITLE_FIELD_RU]: '',
  [ADD_DISEASE_FIELDS.SUB_TITLE_FIELD_RU]: '',
  [ADD_DISEASE_FIELDS.SEO_TITLE_FIELD_RU]: '',
  [ADD_DISEASE_FIELDS.SEO_DESCRIPTION_FIELD_RU]: ''
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
