import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesUpdate } from 'apis/ArticlesApi';

const ACTION = 'articles/update_form';

const updateArticleFormAction = createAsyncThunk(
  ACTION,
  articlesUpdate
);

export default updateArticleFormAction;
