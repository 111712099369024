import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesUnpublish } from 'apis/ArticlesApi';
import { IArticleResponse } from 'types';

const ACTION = 'articles/unpublish';

const unpublishArticle = createAsyncThunk(ACTION, async (id: IArticleResponse['id']): Promise<IArticleResponse> => {
  return await articlesUnpublish(id);
});

export default unpublishArticle;
