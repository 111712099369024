import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const languages = {
  ru: 'ru',
  com: 'en'
};

function SubdomainLanguageDetect() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const { hostname } = window.location;

    const names = hostname.split('.');

    const domain = names[names.length - 1];

    languages[domain] && setTimeout(() => i18n.changeLanguage(languages[domain]), 0);
  }, []);
  return null;
}

export default SubdomainLanguageDetect;
