import isNil from 'lodash/isNil';

function firstName({ first_name }) {
  return {
    first_name: (isNil(first_name) ? '' : first_name)
  };
}

function lastName({ last_name }) {
  return {
    last_name: (isNil(last_name) ? '' : last_name)
  };
}

function middleName({ middle_name }) {
  return {
    middle_name: (isNil(middle_name) ? '' : middle_name)
  };
}

function avatar({ avatar }) {
  return {
    avatar: (isNil(avatar) ? '' : avatar)
  };
}

function startOfPractice({ start_of_practice }) {
  return {
    start_of_practice: (isNil(start_of_practice) ? '' : start_of_practice)
  };
}

function specializations({ specializations }) {
  return {
    specializations: (isNil(specializations) ? [] : specializations)
  };
}

function awards({ awards }) {
  return {
    awards: (isNil(awards) ? [] : awards)
  };
}

function seoTitle({ seo_title }) {
  return {
    seo_title: (isNil(seo_title) ? '' : seo_title)
  };
}

function seoDescription({ seo_description }) {
  return {
    seo_description: (isNil(seo_description) ? '' : seo_description)
  };
}

function metaTags({ meta_tags }) {
  return {
    meta_tags: (isNil(meta_tags) ? [] : meta_tags)
  };
}

function formValues(value) {
  if (value === null) return null;

  return {
    ...firstName(value),
    ...lastName(value),
    ...middleName(value),
    ...avatar(value),
    ...startOfPractice(value),
    ...specializations(value),
    ...awards(value),
    ...seoTitle(value),
    ...seoDescription(value),
    ...metaTags(value)
  };
}

export default formValues;
