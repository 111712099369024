import React, { useEffect, useState } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { Text, Combobox, InputBase, useCombobox, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import useRegions from './hook/useRegions';
import useHotels from './hook/useHotels';
import classes from './RegionSelect.module.css';

type Props = React.PropsWithChildren<{
  onSelect?: (region: any) => void;
  type?: string;
  placeholder?: string;
  value?: any;
  defaultName?: string;
  width?: string;
}>;

function RegionSelect({ onSelect, placeholder, value, type = 'regions', defaultName, width = '100%' }: Props) {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const [{ regions }, { fetch, clear }] = type === 'regions' ? useRegions() : useHotels();
  const values = value ? [value] : [];

  const names = _uniqBy([...values, ...regions], 'place_id').map((h) => ({
    label: h.label_ru,
    value: h.place_id,
    description: h.description_ru
  }));

  useEffect(() => {
    if (defaultName) fetch(defaultName);
  }, []);

  const handleSelect = (region: string) => {
    const regionObj = regions?.find((r) => r.place_id === region);

    if (regionObj) {
      onSelect && onSelect(regionObj);
    }
  };

  const handleSearchChange = (q: string) => {
    setInputValue(q);
    if (q.length > 2) {
      fetch(q);
    } else {
      clear();
    }
  };

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        const selectedItem = names.find(item => item.value === val);
        setInputValue(selectedItem?.label || '');
        handleSelect(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          w={width}
          rightSection={<Combobox.Chevron />}
          value={inputValue}
          onChange={(event) => {
            const newValue = event.currentTarget.value;
            setInputValue(newValue);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            handleSearchChange(newValue);
          }}
          placeholder={placeholder || t('SearchArea.RegionSelect.Placeholder')}
          rightSectionPointerEvents='none'
        />
      </Combobox.Target>

      <Combobox.Dropdown className={classes.options}>
        <Combobox.Options>
          {inputValue.length > 2
            ? (
                names.length > 0
                  ? (
                      names.map((item) => (
                        <Combobox.Option value={item.value} key={item.value}>
                          <Flex direction='column' gap='4px'>
                            <Text size='sm'>{item.label}</Text>
                            <Text size='xs' c='dimmed'>{item.description}</Text>
                          </Flex>
                        </Combobox.Option>
                      ))
                    )
                  : (
                    <Combobox.Empty>Ничего не найдено</Combobox.Empty>
                    )
              )
            : null}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default RegionSelect;
