import { createAsyncThunk } from '@reduxjs/toolkit';

import { payloadIsValid } from 'utils/jwt';
import logoutAction from './logout';
import { RootState } from 'store';

const ACTION = 'auth/authenticate';

const authenticateAction = createAsyncThunk(ACTION, (_, thunkAPI) => {
  const {
    auth: { client }
  } = thunkAPI.getState() as RootState;

  if (payloadIsValid(client?.client.payload)) {
    return;
  }

  thunkAPI.dispatch(logoutAction());
});

export default authenticateAction;
