import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { Text, Table, Breadcrumbs, Anchor, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Loader from 'components/ui/Loader';

import { Main, TopBar, Content } from 'components/ui/Layout';

import { STATUSES } from 'types';
import { fetchParsingTariffsAction } from 'store/parsingSlice/actions';
import { parsingTariffsAllSelector } from 'store/parsingSlice/selectors/tariffs';
import classes from './Tariffs.module.css';

const format = 'DD.MM.YYYY';

function Tariffs() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [state, data]: [STATUSES, any, STATUSES?] = useAppSelector(parsingTariffsAllSelector);

  useEffect(() => {
    id && dispatch(fetchParsingTariffsAction(id));
  }, [id]);

  if (state !== STATUSES.FULFILLED) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  const handleClick = (tariff: any) => {
    navigate(`/parsing/${id}/tariff/${tariff._id}/`);
  };

  const rows = data.tariffs.map((element: any) => {
    return (
      <Table.Tr key={element.uuid} className={classes.table_row} onClick={() => handleClick(element)}>
        <Table.Td>
          <Text fz={14}>{element.name_translations.ru}</Text>

          <Text fz={12} c='dimmed'>
            {element.room_type_object.name_translations.ru}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text fz={14} lineClamp={2}>
            {element.description_translations.ru}
          </Text>
        </Table.Td>

        <Table.Td fz={14}>Sanatory.ru</Table.Td>

        <Table.Td fz={14}>
          {dayjs(element.available_period.from).format(format)} - {dayjs(element.available_period.to).format(format)}
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Main>
      <TopBar>
        <Breadcrumbs separator={<Text fz={25}>/</Text>}>
          <Anchor href='/parsing/'>{t('Parsing.Title')}</Anchor>

          <Text>{t('Tariffs.Title')}</Text>
        </Breadcrumbs>
      </TopBar>

      <Content>
        <Table bg='white' verticalSpacing='xl' horizontalSpacing='xl' className={classes.table} fz='md'>
          <Table.Thead bg='var(--mantine-color-blue-1)'>
            <Table.Tr>
              <Table.Th h='70px'>{t('Tariffs.Table.Field1')}</Table.Th>
              <Table.Th>{t('Tariffs.Table.Field2')}</Table.Th>
              <Table.Th>{t('Tariffs.Table.Field3')}</Table.Th>
              <Table.Th>{t('Tariffs.Table.Field4')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Content>

      <Outlet />
    </Main>
  );
}

export default Tariffs;
