import { createAsyncThunk } from '@reduxjs/toolkit';

import { objectsUpdate } from 'apis/ObjectsApi';

const ACTION = 'objects/update_form';

const updateObjectFormAction = createAsyncThunk(
  ACTION,
  objectsUpdate
);

export default updateObjectFormAction;
