import connection from 'connection/instance';

class ChatGPTApi {
  constructor() {
    this.baseUrl = '/api/hotels';
  }

  async handleRequest(method, url, data = null) {
    try {
      const response = await connection[method](url, data);
      return { data: response.data, error: undefined };
    } catch (error) {
      return { data: undefined, error };
    }
  }

  async createPrompt({ hotelId, prompt }) {
    return this.handleRequest('post', `${this.baseUrl}/${hotelId}/hotel_gpt_description`, { data: { prompt } });
  }

  async createTranslate({ text, lang }) {
    return this.handleRequest('post', `${this.baseUrl}/translate`, { data: { text, lang } });
  }

  async fetchPrompt(hotelId) {
    const response = await this.handleRequest('get', `${this.baseUrl}/${hotelId}/hotel_gpt_prompt`);
    return { data: response.data?.data, error: response.error };
  }
}

export default new ChatGPTApi();
