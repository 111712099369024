import pasteEvent from '../utils/pasteEvent';

export default class Ui {
  constructor({ title, list, onAdd, onRemove }) {
    this.title = title;
    this.list = list;
    this.onAdd = onAdd;
    this.onRemove = onRemove;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      listElement: make('div', [this.CSS.listElement]),
      title: make('h2', this.CSS.title, {
        contentEditable: true,
        innerHTML: this.title
      }),
      listContainer: make('ul', this.CSS.listContainer),
      button: this.createAddButton()
    };

    this.nodes.title.addEventListener('paste', pasteEvent);
  }

  createAddButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = 'Добавить';

    button.addEventListener('click', () => {
      this.onAdd();
    });

    return button;
  }

  get CSS() {
    return {
      wrapper: 'facts',
      listElement: 'facts_element',
      title: 'facts_title',
      listContainer: 'facts_list',
      remove: 'facts_settings_remove',
      button: 'facts_add',
      removeIcon: 'facts_element_remove',
      listText: 'facts_element_text'
    };
  }

  getData() {
    const list = this.nodes.wrapper.getElementsByClassName(this.CSS.listText);
    const titleElement = this.nodes.wrapper.querySelector(`.${this.CSS.title}`);

    return {
      list: Array.from(list).map((el) => el.innerHTML),
      title: titleElement.innerHTML
    };
  }

  displayErrorItem = (flag, index, cssClass) => {
    const item = this.nodes.wrapper.getElementsByClassName(this.CSS.listElement)[index];

    flag ? item.classList.add('error') : item.classList.remove('error');

    return item;
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  }

  renderList() {
    return this.list.map((element, index) => {
      const listElement = make('li', this.CSS.listElement);

      const listText = make('div', this.CSS.listText, {
        contentEditable: true,
        innerHTML: element
      });

      listText.addEventListener('paste', pasteEvent);

      const removeIcon = make('div', this.CSS.removeIcon);

      removeIcon.addEventListener('click', () => {
        this.onRemove(index);
      });

      listElement.appendChild(listText);
      listElement.appendChild(removeIcon);

      return listElement;
    });
  }

  render() {
    const list = this.renderList();

    this.clear(this.nodes.listContainer);

    list.forEach((element) => {
      this.nodes.listContainer.appendChild(element);
    });

    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.listContainer);
    this.nodes.wrapper.appendChild(this.nodes.button);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
