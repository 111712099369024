import { createSlice } from '@reduxjs/toolkit';

import articlesAdapter, { initialState } from './adapter';

import {
  clearObjectOneAction,
  fetchObjectAction,
  fetchObjectMoreAction,
  fetchOneObjectAction,
  saveObjectAction,
  updateObjectAction
} from './actions';

const topicsSlice = createSlice({
  name: 'hotels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneObjectAction.pending, articlesAdapter.objectsOneFetchPending)
      .addCase(fetchOneObjectAction.fulfilled, articlesAdapter.objectsOneFetchFulfilled)
      .addCase(fetchObjectAction.pending, articlesAdapter.objectsFetchPending)
      .addCase(fetchObjectAction.fulfilled, articlesAdapter.objectsFetchFulfilled)
      .addCase(updateObjectAction.fulfilled, articlesAdapter.objectSave)
      .addCase(saveObjectAction, articlesAdapter.objectSave)
      .addCase(fetchObjectMoreAction.pending, articlesAdapter.objectsFetchMorePending)
      .addCase(fetchObjectMoreAction.fulfilled, articlesAdapter.objectsFetchMoreFulfilled)
      .addCase(clearObjectOneAction, articlesAdapter.objectsOneClear);
  }
});

export default topicsSlice.reducer;
