import React from 'react';
import PropTypes from 'prop-types';

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

function Top({ hotel }) {
  const handleTitleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}`);
  };

  return (
    <div className='hotelCard_wrapper-top'>
      <div>
        <div className='hotelCard_nameContainer'>
          <div className='hotelCard_name' onClick={handleTitleClick}>
            {hotel.name}
          </div>
        </div>

        {hotel.address?.region && (
          <div className='hotelCard_addressContainer'>
            {hotel.address.region}
          </div>
        )}

      </div>
    </div>
  );
}

Top.propTypes = {
  hotel: PropTypes.object
};

export default Top;
