import React, { useEffect } from 'react';
import { Badge, Button, Flex, Tabs } from '@mantine/core';
import _cloneDeep from 'lodash/cloneDeep';
import { useFormContext } from 'react-hook-form';

import { searchAreaSortsOptions } from '../../../../../constants';
import { SEARCH_AREA_FIELDS } from '../schema';
import { ISearchArea, IOption } from 'types';

import SelectForm from 'components/ui/SelectForm';
import InputForm from 'components/ui/InputForm';

import RegionSelect from './RegionSelect';
import HotelRegionSelect from './HotelRegionSelect';
import CatalogSelect from './CatalogSelect';

type Props = React.PropsWithChildren<{
  searchArea: ISearchArea;
}>;

const options: IOption[] = [
  {
    value: 'coordinates',
    label: 'по координатам'
  },
  {
    value: 'area',
    label: 'по границам'
  }
];

function FormComponent({ searchArea }: Props) {
  const { is_searchable, search_radius, search_mode, search_additionals, slug, sort_basis } = searchArea;

  // Выбранное значение сортировки, по идее оно всегда должно приходить с сервера
  // так как в текущй форме, нельзя его не отправить
  const sortOptions = searchAreaSortsOptions.find(
    (sort) => sort.direction === sort_basis.direction && sort.sort_by === sort_basis.sort_by
  )?.value;

  const { setValue, watch } = useFormContext();

  useEffect(() => {
    if (!searchArea) {
      return;
    }
    setValue(SEARCH_AREA_FIELDS.SEARCH_MODE, search_mode);
    setValue(SEARCH_AREA_FIELDS.SEARCH_RADIUS, search_radius);
    setValue(SEARCH_AREA_FIELDS.SEARCH_ADDITIONALS, search_additionals);
    setValue(SEARCH_AREA_FIELDS.SLUG, slug);
    setValue(SEARCH_AREA_FIELDS.IS_SEARCHABLE, is_searchable);
    setValue(SEARCH_AREA_FIELDS.SORT, sortOptions);
  }, [searchArea, setValue]);

  const searchAdditionals = watch(SEARCH_AREA_FIELDS.SEARCH_ADDITIONALS) || [];
  const catalogSlug = watch(SEARCH_AREA_FIELDS.SLUG);

  const handleSelect = (obj: any) => {
    setValue(SEARCH_AREA_FIELDS.SEARCH_ADDITIONALS, [...searchAdditionals, obj]);
  };

  const handleSelectCatalog = (tag: string | undefined) => {
    setValue(SEARCH_AREA_FIELDS.SLUG, tag);
  };

  const handleDelete = (id: string) => {
    setValue(
      SEARCH_AREA_FIELDS.SEARCH_ADDITIONALS,
      searchAdditionals?.filter((region: any) => region.area_id !== id)
    );
  };

  const handleChangeRadius = (radius: number, index: number) => {
    const areas = _cloneDeep(searchAdditionals);
    areas[index].radius = radius;
    setValue(SEARCH_AREA_FIELDS.SEARCH_ADDITIONALS, areas);
  };

  return (
    <Flex direction='column' gap='lg'>
      <Flex gap='md'>
        <SelectForm name={SEARCH_AREA_FIELDS.SEARCH_MODE} label='Режим поиска' options={options} />
        <InputForm
          name={SEARCH_AREA_FIELDS.SEARCH_RADIUS}
          label='Радиус поиска в км'
          required
          disabled={watch(SEARCH_AREA_FIELDS.SEARCH_MODE) === 'area'}
        />
      </Flex>

      <SelectForm name={SEARCH_AREA_FIELDS.SORT} label='Сортировка' options={searchAreaSortsOptions} />

      <Tabs color='green' defaultValue='region'>
        <Tabs.List grow>
          <Tabs.Tab
            value='region'
            rightSection={
              <Badge color='green' variant='filled' size='xs'>
                {Array.isArray(searchAdditionals)
                  ? searchAdditionals.filter((s: any) => s.type === 'region').length
                  : 0}
              </Badge>
            }
          >
            Регионы
          </Tabs.Tab>

          <Tabs.Tab
            value='hotel'
            rightSection={
              <Badge color='green' variant='filled' size='xs'>
                {Array.isArray(searchAdditionals)
                  ? searchAdditionals.filter((s: any) => s.type === 'hotels').length
                  : 0}
              </Badge>
            }
          >
            Объекты размещения
          </Tabs.Tab>

          <Tabs.Tab
            value='links'
            rightSection={
              <Badge color='green' variant='filled' size='xs'>
                {catalogSlug ? 1 : 0}
              </Badge>
            }
          >
            Каталог
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='region' pt='xs'>
          <RegionSelect
            onChangeRadius={handleChangeRadius}
            searchAdditionals={searchAdditionals}
            onSelect={handleSelect}
            onDelete={handleDelete}
            name={SEARCH_AREA_FIELDS.SEARCH_ADDITIONALS}
            disabled={watch(SEARCH_AREA_FIELDS.SEARCH_MODE) === 'area'}
          />
        </Tabs.Panel>

        <Tabs.Panel value='hotel' pt='xs'>
          <HotelRegionSelect
            searchAdditionals={searchAdditionals}
            onSelect={handleSelect}
            onDelete={handleDelete}
            name={SEARCH_AREA_FIELDS.SEARCH_ADDITIONALS}
            onChangeRadius={handleChangeRadius}
          />
        </Tabs.Panel>

        <Tabs.Panel value='links' pt='xs'>
          <CatalogSelect onSelect={handleSelectCatalog} catalogSlug={catalogSlug} />
        </Tabs.Panel>
      </Tabs>

      <Button color='green' type='submit'>
        Сохранить
      </Button>
    </Flex>
  );
}

export default FormComponent;
