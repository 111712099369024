import { createSlice } from '@reduxjs/toolkit';

import articlesAdapter, { initialState } from './adapter';

import {
  fetchOneSearchAreaAction,
  fetchSearchAreaAction,
  fetchSearchAreaMoreAction,
  updateSearchArea,
  catalogFetchAction
} from './actions';

// Actions
const searchAreaSlice = createSlice({
  name: 'searchArea',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchAreaAction.pending, articlesAdapter.searchAreaFetchPending)
      .addCase(fetchSearchAreaAction.fulfilled, articlesAdapter.searchAreaFetchFulfilled)
      .addCase(catalogFetchAction.fulfilled, articlesAdapter.catalogFetchFullfilled)
      .addCase(fetchOneSearchAreaAction.pending, articlesAdapter.searchAreaOneFetchPending)
      .addCase(fetchOneSearchAreaAction.fulfilled, articlesAdapter.searchAreaOneFetchFulfilled)
      .addCase(updateSearchArea.fulfilled, articlesAdapter.searchAreaUpdateFulfilled)
      .addCase(fetchSearchAreaMoreAction.pending, articlesAdapter.searchAreaFetchMorePending)
      .addCase(fetchSearchAreaMoreAction.fulfilled, articlesAdapter.searchAreaFetchMoreFulfilled);
  }
});

export default searchAreaSlice.reducer;
