import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@mantine/core';
import { IconCar, IconTrain, IconBus, IconPlane } from '@tabler/icons-react';

import { distanceTextFormation } from 'utils/distance';

type Props = {
  distances: any[]
}

type Distances = {
    [key: string]: DistanceTemp
}

type DistanceTemp = {
    icon: any,
    text: string
}

function Distances({ distances }: Props) {
  const { t } = useTranslation();
  const obj: Distances = {
    plane: {
      icon: <IconPlane size={25} />,
      text: t('Distances.FromAirport')
    },
    bus: {
      icon: <IconBus size={25} />,
      text: t('Distances.FromBusStop')
    },
    train: {
      icon: <IconTrain size={25} />,
      text: t('Distances.FromRailwayStation')
    },
    transfer: {
      icon: <IconCar size={25} />,
      text: t('Distances.FromBusStop')
    }
  };
  const distancesElement = distances.map((distance, indexEl) => {
    const [distanceText] = useMemo(() => {
      const { text, value } = distanceTextFormation(distance.name);
      return [text, value || distance.value];
    }, [distance]);

    const index: string = distance.icon;

    const distanceElement: DistanceTemp = obj[index] || distance[0];
    return (
      <Flex key={indexEl} justify='space-between'>

        {distanceElement.icon}

        <Text dangerouslySetInnerHTML={{ __html: distanceText }} fz='14px' maw='250px' />

        <Text fz='14px' fw='500'>
          {distance.value} {distance.unit}
        </Text>
      </Flex>
    );
  });

  return (

    <Flex direction='column' gap='16px'>
      {distancesElement}
    </Flex>

  );
}

export default Distances;
