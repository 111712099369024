import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Paper, Text, NavLink } from '@mantine/core';

import { IconToggleRight } from '@tabler/icons-react';
import classes from './Blog.module.css';

const site = process.env.REACT_APP_SITE;

type BlogcardProps = {
  data: any
}

function BlogCard({ data }:BlogcardProps) {
  const { t } = useTranslation();

  return (
    <Paper p='xs' bg='var(--mantine-color-orange-2)'>
      <Flex align='center' gap='xs' w='100%'>
        <Box fz='50px'>
          <IconToggleRight />
        </Box>

        <Flex wrap='wrap'>
          <Text>
            {t('Blogcard.CommonText')}
          </Text>
          <NavLink className={classes.link} label={data.title} target='_blank' href={`${site}/blog/${data.blog.slug}/`} />
        </Flex>
      </Flex>
    </Paper>
  );
}
export default BlogCard;
