import React, { useState, useEffect } from 'react';
import { Switch as MantineSwitch, useMantineTheme, rem } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

import ConditionalComponent from '../../ConditionalComponent';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

function Switch({ value: initialValue, onChange }: Props) {
  const [value, setValue] = useState(initialValue);

  const theme = useMantineTheme();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    onChange(checked);
    setValue(checked);
  };

  return (
    <MantineSwitch
      checked={value}
      onChange={handleChange}
      color='green'
      size='md'
      thumbIcon={
        <ConditionalComponent
          condition={value}
          fallback={
            <IconX
              style={{
                width: rem(12),
                height: rem(12)
              }}
              stroke={3}
            />
          }
        >
          <IconCheck
            style={{
              width: rem(12),
              height: rem(12)
            }}
            color={theme.colors.green[6]}
            stroke={3}
          />
        </ConditionalComponent>
      }
    />
  );
}

export default Switch;
