import { RootState } from 'store';
import { STATUSES } from 'types';
import _orderBy from 'lodash/orderBy';
import { IObjectData } from 'types/object';

export const objectSelector = (state: RootState): [STATUSES, IObjectData, any] => {
  return [
    state.hotels.objectState,
    state.hotels.object,
    state.hotels.hotel
  ];
};
