import React from 'react';

import symbols from './helpers/currencySymbols';
import SelectForm from 'components/ui/SelectForm';

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  required?: boolean;
};

const CurrencySelect = ({ label, placeholder, name, required }: Props) => {
  const symbolsArray = Object.entries(symbols).map(([name, value]) => ({ name, value }));

  const selectCategoryOptions = symbolsArray.map((option) => ({
    value: option.name,
    label: option.name
  }));

  return (
    <SelectForm required={required} width='100%' name={name} label={label} options={selectCategoryOptions as []} placeholder={placeholder} />
  );
};

export default CurrencySelect;
