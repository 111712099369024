import React from 'react';
import { Accordion, Box, Flex, Text, Badge } from '@mantine/core';

import CommonPart from '../CommonPart';
import getTranslate from 'utils/getTranslate';
import { IObjectDataFull } from 'types';

type Props = {
  object: IObjectDataFull;
  currentLanguage: string;
};

function ProgrammsContent({ object, currentLanguage }: Props) {
  const { t } = getTranslate(currentLanguage);

  const programs = object.body_translations[currentLanguage]?.blocks.find((b) => b.type === 'programs').data.list;

  const treatmentComponents = programs.map((program: any, index: number) => {
    const includes = program.includes.map((pr: any, index: number) => (
      <Box pt='xs' key={index}>
        <Text dangerouslySetInnerHTML={{ __html: pr }} />
      </Box>
    ));
    return (
      <Accordion.Item key={program.value} value={program.title}>
        <Accordion.Control bg='var(--mantine-color-gray-2)'>
          <Text fz='18px' fw='500'>
            {program.title}
          </Text>
        </Accordion.Control>
        <Accordion.Panel bg='var(--mantine-color-gray-2)'>
          <Flex direction='column' gap='6px'>
            <Badge>{`Продолжительность ${program.duration}`}</Badge>
            <Text fz={14} dangerouslySetInnerHTML={{ __html: program.description }} />
            {program.includes.length !== 0 && (
              <Text my='16px' fw='500'>
                {t('HotelPage.TreatmentPrograms.IncludedText')}
              </Text>
            )}
            <Box style={{ columnCount: 2 }}>{includes}</Box>
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    );
  });

  return (
    <CommonPart>
      <Text dangerouslySetInnerHTML={{ __html: t('HotelPage.Content.Programms.Description') }} />
      <Accordion mt='xs'>
        <Flex direction='column' gap={20}>
          {treatmentComponents}
        </Flex>
      </Accordion>
    </CommonPart>
  );
}

export default ProgrammsContent;
