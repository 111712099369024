import hotelSnippet from './blocks/snippets/hotel';
import bigHotelCardSnippet from './blocks/snippets/big-hotel-card';
import sourcesLinkSnippet from './blocks/snippets/sources-link';

const styles = (props) => ({
  css: [
    `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/css/arx-content.min.css`,
    `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/css/arx-frame.min.css`,
    `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/plugins/style/style.css`,
    `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/plugins/themes/reviewer.css`,
    // Blocks
    `${process.env.PUBLIC_URL}/vendors/custom/styles/article-editor/blocks/documents.css`,
    `${process.env.PUBLIC_URL}/vendors/custom/styles/article-editor/blocks/carousel.css`,
    // Snippets
    `${process.env.PUBLIC_URL}/vendors/custom/styles/article-editor/blocks/snippets/sources-link.css`,
    `${process.env.PUBLIC_URL}/vendors/custom/styles/article-editor/blocks/snippets/hotel.css`,
    `${process.env.PUBLIC_URL}/vendors/custom/styles/article-editor/blocks/snippets/big-hotel-card.css`
  ],
  styles: {
    paragraph: {
      note: {
        title: `
          <span
            style="background-color: #F5F5F7; color: #4C4C4C; padding: 12px; border-radius: 2px; display: block;"
          >
            Note
          </span>
        `,
        classname: 'st-note'
      }
    }
  }
});

const plugins = (props) => ({
  plugins: ['style', 'counter', 'documents', 'carousel']
});

const editor = (props) => ({
  editor: {
    padding: true,
    minHeight: '640px',
    sync: true
  }
});

const toolbar = (props) => ({
  toolbar: {
    // stickyTopOffset: -80
  }
});

const snippets = (props) => ({
  snippets: {
    hotel: hotelSnippet,
    bigHotelCard: bigHotelCardSnippet,
    'sources-link': sourcesLinkSnippet
  }
});

const image = ({ uploadImgUrl, selectImgUrl }) => ({
  image: {
    upload: uploadImgUrl,
    select: selectImgUrl,
    url: false,
    link: false,
    data: {
      origin_width: 960,
      origin_height: 456,
      thumb_width: 240,
      thumb_height: 114
    }
  }
});

// const autosave = ({ autoSaveUrl }) => ({
//   autosave: {
//     url: autoSaveUrl,
//     name: 'content',
//     method: 'put'
//   }
// });

const templates = ({ title }) => ({
  templates: {
    blog: {
      name: 'Блог',
      html: `
        <div>
          <h1>${title}</h1>
          <figure>
            <img src="https://cdn.sanatory.ru/fill/960/456/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19hdHRhY2ht/ZW50cy9pbWFnZS94/dWNlbC9meXJ5Zy9r/eW5pYi9yb25lbC9o/eWtlei9maWd5Yy9m/eWN1Zy9jYXZpZi9t/dXh5eC8xY2U0YjAx/MWVlN2Q1NDIyZDEw/YmMyYjg3MmUwZTcy/Ni5wbmc.jpg" data-image="h700hwo4gc0n"></figure>
            <p>Санаторий «Нива» расположен в Ессентуках — самом известном городе Кавказских Минеральных Вод, куда едут отдохнуть и поправить здоровье гости со всей страны. Здравница специализируетс
              я на лечении заболеваний желудка и кишечника, сахарного диабета, позвоночника и суставов, а также на общем оздоровлении организма. Лечение в «Ниве» сочетает климатотерапию, современные медицинские технологии и спа-процедуры. Эффект от терапии подкрепляет диетическое питание, физическая нагрузка и высокое качество местной экологии.
            </p>
            <p>Санаторий «Нива» расположен в центре курортной зоны Ессентуков, рядом с лечебным парком — в нем находятся питьевые бюветы со знаменитой минеральной водой № 4 и 17. В непосредственной близости от жилого корпуса расположена лучшая в Европе грязелечебница и верхние минеральные ванны. Санаторий располагает высокотехнологичной лечебной базой и по праву считается одной из лучших здравниц КавМинВод.</p>
            <p>Санаторий «Нива» расположен в Ессентуках — самом известном городе Кавказских Минеральных Вод, куда едут отдохнуть и поправить здоровье гости со всей страны. Здравница специализируется на лечении заболеваний желудка и кишечника, сахарного диабета, позвоночника и суставов, а также на общем оздоровлении организма. Лечение в «Ниве» сочетает климатотерапию, современные медицинские технологии и спа-процедуры. Эффект от терапии подкрепляет диетическое питание, физическая нагрузка и высокое качество местной экологии.</p>
            <p>Санаторий «Нива» расположен в центре курортной зоны Ессентуков, рядом с лечебным парком — в нем находятся питьевые бюветы со знаменитой минеральной водой № 4 и 17. В непосредственной близости от жилого корпуса расположена лучшая в Европе грязелечебница и верхние минеральные ванны. Санаторий располагает высокотехнологичной лечебной базой и по праву считается одной из лучших здравниц КавМинВод.</p>
          <figure>
          <img src="https://cdn.sanatory.ru/fill/960/456/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19hdHRhY2ht/ZW50cy9pbWFnZS94/ZWRvbi9ndWd5aC9w/aXBvbC9iaWZvci9k/eXJpdC96b2x5ay9y/YXNhbS9wdWNlaC9u/eXhveC9mOWE0ZWQ5/YmMyZDA1OThkNmQ1/ZWI1Y2NmZWNmNTVh/MS5wbmc.jpg" data-image="a8rjlhg8z146"></figure>
          <p>Санаторий «Нива» расположен в Ессентуках — самом известном городе Кавказских Минеральных Вод, куда едут отдохнуть и поправить здоровье гости со всей страны. Здравница специализируется на лечении заболеваний желудка и кишечника, сахарного диабета, позвоночника и суставов, а также на общем оздоровлении организма. Лечение в «Ниве» сочетает климатотерапию, современные медицинские технологии и спа-процедуры. Эффект от терапии подкрепляет диетическое питание, физическая нагрузка и высокое качество местной экологии.</p>
          <p class="st-note">Перенести сроки или отменить бронирование можно по телефону:
            <a href="tel:+78003504740">8 (800) 350-47-40</a>(бесплатно) или написав нам на почту:
            <a href="mailto:hello@sanatory.ru">hello@sanatory.ru</a>
          </p>
          <p>Санаторий «Нива» расположен в центре курортной зоны Ессентуков, рядом с лечебным парком — в нем находятся питьевые бюветы со знаменитой минеральной водой № 4 и 17. В непосредственной близости от жилого корпуса расположена лучшая в Европе грязелечебница и верхние минеральные ванны. Санаторий располагает высокотехнологичной лечебной базой и по праву считается одной из лучших здравниц КавМинВод.</p>

          <div class="sources-link">
            <div class="left-container">
              <div class="icon">
                <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.67107 0C3.67042 0 3.66969 0 3.66905 0C3.44205 0 3.22856 0.0884853 3.06734 0.249294C2.90435 0.411881 2.81458 0.628367 2.81458 0.858833V13.7369C2.81458 14.2091 3.20027 14.5943 3.67446 14.5955C5.67351 14.6003 9.0227 15.0169 11.3332 17.4348V3.95752C11.3332 3.79744 11.2923 3.64705 11.2151 3.52261C9.31878 0.46869 5.67464 0.0046869 3.67107 0Z" fill="#2E7CD5"/>
                  <path d="M21.1856 13.737V0.858833C21.1856 0.628367 21.0958 0.411881 20.9328 0.249294C20.7716 0.0884853 20.5579 0 20.3312 0C20.3305 0 20.3297 0 20.3291 0C18.3256 0.00476771 14.6814 0.46877 12.785 3.52269C12.7079 3.64713 12.6671 3.79752 12.6671 3.9576V17.4348C14.9775 15.0169 18.3267 14.6003 20.3258 14.5955C20.7999 14.5943 21.1856 14.2091 21.1856 13.737Z" fill="#2E7CD5"/>
                  <path d="M23.1414 2.9707H22.5187V13.7377C22.5187 14.9435 21.5363 15.9267 20.3287 15.9296C18.6331 15.9337 15.8373 16.2652 13.8572 18.1393C17.2817 17.3008 20.8918 17.8459 22.9492 18.3147C23.2061 18.3732 23.4715 18.3128 23.6773 18.1487C23.8824 17.9851 24.0001 17.7405 24.0001 17.4779V3.82937C24.0002 3.35592 23.6149 2.9707 23.1414 2.9707Z" fill="#2E7CD5"/>
                  <path d="M1.48138 13.7377V2.9707H0.858672C0.385295 2.9707 0 3.35592 0 3.82937V17.4777C0 17.7403 0.117657 17.9849 0.322749 18.1485C0.528407 18.3125 0.793621 18.3731 1.05092 18.3145C3.1083 17.8455 6.71842 17.3006 10.1428 18.139C8.1628 16.2651 5.36698 15.9336 3.67138 15.9296C2.46385 15.9267 1.48138 14.9435 1.48138 13.7377Z" fill="#2E7CD5"/>
                </svg>
              </div>
            </div>

            <div class="right-container">
              <p class="title">
                Ссылки на источники
              </p>

              <p class="description">
                При создании статей наши авторы всегда используют научную литературу
              </p>

              <div class="adm-link-block">
                Список ссылок на источники
              </div>

              <div class="link-block">
                <p class="link-close">
                  Посмотреть все ссылки
                </p>

                <p class="link-open">
                  Скрыть все ссылки
                </p>
              </div>

              <dl>
                <dt>Диетология простым языком. Основы правильного питания</dt>

                <dd>https://zen.yandex.ru/media/id/5cc17bf5bfff6400b302</dd>
              </dl>
            </div>
          </div>
        </div>
      `
    },
    reviewerPage: {
      name: 'Автор',
      html: `
        <div>
          <h2>Общая информация</h2>

          <div class='specialization'>
            <h5>Специализация</h5>

            <div>
              <p>Врач диетолог</p>
              <p>Врач эндокрилог</p>
            </div>
          </div>

          <h5>Стаж</h5>
          <p>19 лет, врачебную практику начала с 2002 года.</p>

          <h5>Медицинское учреждение</h5>
          <p>Московский государственный хирургический университет им. А.И.Евдокимова</p>

          <h5>Образование</h5>
          <p>2000 - Башкирский государственный медицинский университет, диплом “Врач-эндокринолог”</p>
          <p>2001 - Башкирский государственный медицинский университет, повышение квалификации по специальности «Клиническая электрокардиография»</p>
          <p>2015 - Московский государственный медицинский университет, повышение квалификации по специальности «Терапия»</p>
          <p>2017 - Швейцарская Университетская Клиника, повышение квалификации по специальности “Эндокринология”</p>

          <h5>Ученые степени</h5>
          <p>2009 - Уральская государственная Академия, присуждена ученая степень кандидата медицинских наук</p>

          <hr>

          <h5>Дипломы и сертификаты</h5>

          <hr>

          <h5>О враче</h5>
          <p>Санаторий «Нива» расположен в Ессентуках — самом известном городе Кавказских Минеральных Вод, куда едут отдохнуть и поправить здоровье гости со всей страны. Здравница специализируется на лечении заболеваний желудка и кишечника, сахарного диабета, позвоночника и суставов, а также на общем оздоровлении организма. Лечение в «Ниве» сочетает климатотерапию, современные медицинские технологии и спа-процедуры. Эффект от терапии подкрепляет диетическое питание.</p>
          <p>Санаторий «Нива» расположен в Ессентуках — самом известном городе Кавказских Минеральных Вод, куда едут отдохнуть и поправить здоровье гости со всей страны. Здравница специализируется на лечении заболеваний желудка и кишечника, сахарного диабета, позвоночника и суставов, а также на общем оздоровлении организма. Лечение в «Ниве» сочетает климатотерапию, современные медицинские технологии и спа-процедуры. Эффект от терапии подкрепляет диетическое питание.</p>
        </div>
      `
    }
  }
});

// Documents plugin settings
const documents = ({ uploadImgUrl, selectImgUrl }) => ({
  documents: {
    upload: uploadImgUrl,
    select: selectImgUrl,
    data: {
      origin_width: 930,
      origin_height: 1100,
      thumb_width: 186,
      thumb_height: 220
    }
  }
});

// Carousel plugin settings
const carousel = ({ uploadImgUrl, selectImgUrl }) => ({
  carousel: {
    upload: uploadImgUrl,
    select: selectImgUrl,
    data: {
      origin_width: 930,
      origin_height: 1100,
      thumb_width: 186,
      thumb_height: 220
    }
  }
});

const format = () => ({
  format: ['p', 'h2', 'h3', 'ul', 'ol']
});

const custom = () => ({
  custom: {
    js: [
      {
        src: `${process.env.PUBLIC_URL}/vendors/custom/js/article-editor/blocks/carousel.js`
      }
    ]
  }
});

const settings = (props) => ({
  ...styles(props),
  ...plugins(props),
  ...editor(props),
  ...toolbar(props),
  ...image(props),
  // ...autosave(props),
  ...templates(props),
  ...snippets(props),
  ...custom(props),
  ...documents(props),
  ...carousel(props),
  ...format(props)
});

export default settings;
