import ajax from '@codexteam/ajax';
import connection from 'connection/instance';

export default class Uploader {
  constructor({ config, onUpload, onError }) {
    this.config = config;
    this.onUpload = onUpload;
    this.onError = onError;
  }

  async uploadSelectedFile() {
    const files = await ajax.selectFiles({ accept: this.config.types });
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      var file = files[i];

      // Check the file type.
      if (!file.type.match('image.*')) {
        continue;
      }

      // Add the file to the request.
      formData.append('file[]', file, file.name);
    }

    const { data } = await connection.post('/api/pages/62d7e64940d80200019e908e/attachments', formData);

    this.onUpload(data[file.name]);
  }
}
