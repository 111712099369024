import React from 'react';
import { Flex, List, rem, Text, ThemeIcon } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

type IProps = {
    data: any
}

function Diseases({ data }:IProps) {
  const isBig = data?.list?.length > 2;
  return (
    <Flex direction='column' mt='65px' gap='16px'>
      <Text fz='36px' fw='600' dangerouslySetInnerHTML={{ __html: data.title }} />
      {data.description && <Text mt='16px' fz='20px' dangerouslySetInnerHTML={{ __html: data.description }} />}

      <List style={{ columnCount: isBig ? 2 : 0 }} spacing='xs' size='sm' center>
        {data.list.map((el, index) => (
          <List.Item
            key={index}
            icon={
              <ThemeIcon color='teal' size={24} radius='xl'>
                <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
                     }
          >
            <Text dangerouslySetInnerHTML={{ __html: el }} />
          </List.Item>
        ))}
      </List>
    </Flex>
  );
}

export default Diseases;
