import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesPublish } from 'apis/ArticlesApi';
import { IArticleResponse } from 'types';

const ACTION = 'articles/publish';

const publishArticle = createAsyncThunk(ACTION, async (id: IArticleResponse['id']): Promise<IArticleResponse> => {
  return await articlesPublish(id);
});

export default publishArticle;
