import { AxiosResponse } from 'axios';
import { IUserReqBody, IUserResponse } from 'types';
import instance from 'connection/instance';
import { verifyJWT } from 'utils/jwt';
import { setDefaultHeader } from 'utils/setDefaultHeaders';
import { removeFromLocalStorage, writeToLocalStorage } from 'utils/storage';
import { unsetDefaultHeader } from 'utils/unsetDefaultHeader';

export const login = async (data: IUserReqBody): Promise<IUserResponse> => {
  try {
    const response: AxiosResponse<IUserResponse> = await instance.post('/api/auth/sign_in', { client: data });
    const jwt = await verifyJWT(response);

    await setDefaultHeader(jwt);

    const user = { ...response.data, ...jwt };
    writeToLocalStorage('user', user);

    return user;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export const logout = async (): Promise<void> => {
  try {
    await instance.delete('/api/auth/sign_out');
    removeFromLocalStorage('user');
    unsetDefaultHeader();
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};
