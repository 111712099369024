import React from 'react';
import PropTypes from 'prop-types';

import Stars from './Stars.js';

function Top({ hotel }) {
  return (
    <div className='hotelCard_wrapper-top'>
      <div>
        <div className='hotelCard_nameContainer'>
          <div className='hotelCard_name' dangerouslySetInnerHTML={{ __html: hotel.name }} />

          {hotel.star > 3 && (
            <div className='hotelCard_starsContainer'>
              <Stars
                size='medium'
                stars={hotel.star}
              />
            </div>
          )}
        </div>

        {hotel.address?.location && (
          <div className='hotelCard_addressContainer'>
            {hotel.address.location}
          </div>
        )}
      </div>
    </div>
  );
}

Top.propTypes = {
  hotel: PropTypes.object
};

export default Top;
