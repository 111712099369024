import { createAsyncThunk } from '@reduxjs/toolkit';

import { objectsCreate } from 'apis/ObjectsApi';
import { IObjectRequest, IObjectResponse, IObjectResponseData, IObjectUserMeta } from 'types';

const ACTION = 'objects/create';

const createObjectAction = createAsyncThunk(
  ACTION,
  async (hotel: IObjectRequest): Promise<IObjectResponseData<IObjectResponse, IObjectUserMeta>> => {
    const data: IObjectResponseData<IObjectResponse, IObjectUserMeta> = await objectsCreate(hotel);

    return data;
  }
);

export default createObjectAction;
