type Program = {
  content: string,
  title: string,
  duration: string,
  description: string
}

function programsMapper(programs: Program[]) {
  return {
    type: 'programs',
    data: {
      description: 'Ознакомьтесь с программами лечения, в случае необходимости мы бесплатно проконсультируем по любой программе.',
      list: programs.map((p) => {
        const { duration, description, title, content } = p;
        const htmlContent = new DOMParser().parseFromString(content, 'text/html');
        const elements = htmlContent.querySelectorAll('li');
        const includes = Array.from(elements).map((e) => e.innerText);

        return {
          description,
          title,
          includes,
          duration
        };
      })
    }
  };
}

export default programsMapper;
