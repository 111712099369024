import { PayloadAction } from '@reduxjs/toolkit';
import { SHOW_TYPE, STATUSES } from 'types';
import _cloneDeep from 'lodash/cloneDeep';
import { IObjectData, IObjectResponse } from 'types/object';

const DEFAULTS = {
  state: STATUSES.IDLE,
  stateMore: STATUSES.IDLE,
  objectState: STATUSES.IDLE,
  objects: [],
  object: {
    content: {
      ru: {
        blocks: []
      },
      en: {
        blocks: []
      }
    }
  },
  type: SHOW_TYPE.LIST
};

type Params = {
  object: IObjectData;
  hotel: any;
};

export interface IAdapter {
  state: STATUSES;
  stateMore: STATUSES;
  objects: IObjectResponse[];
  object: IObjectData;
  objectState: STATUSES;
  hotel?: any;
}

class Adapter {
  public getInitialState(props?: IAdapter): IAdapter {
    return { ...DEFAULTS, ...props };
  }

  public objectsFetchPending(state: IAdapter): IAdapter {
    const currentState = { state: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public objectsFetchFulfilled(state: IAdapter, action: PayloadAction<IObjectResponse[]>): IAdapter {
    const objects: IObjectResponse[] = action.payload;

    const currentState = {
      state: STATUSES.FULFILLED,
      objects
    };

    return { ...state, ...currentState };
  }

  public objectsFetchMorePending(state: IAdapter): IAdapter {
    const currentState = { stateMore: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public objectsFetchMoreFulfilled(state: IAdapter, action: PayloadAction<IObjectResponse[]>): IAdapter {
    const objects: IObjectResponse[] = action.payload;

    const currentState = {
      stateMore: STATUSES.FULFILLED,
      objects: [...state.objects, ...objects]
    };

    return { ...state, ...currentState };
  }

  public objectsOneFetchPending(state: IAdapter): IAdapter {
    const currentState = { objectState: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public objectsOneFetchFulfilled(state: IAdapter, action: PayloadAction<Params>): IAdapter {
    const object: IObjectData = action.payload.object;
    const hotel: any = action.payload.hotel;

    const currentState = {
      objectState: STATUSES.FULFILLED,
      object,
      hotel
    };

    return { ...state, ...currentState };
  }

  public objectsOneClear(state: IAdapter): IAdapter {
    const currentState = {
      object: DEFAULTS.object,
      hotel: null,
      objectState: STATUSES.IDLE
    };

    return { ...state, ...currentState };
  }

  public objectsUpdateFulfilled(state: IAdapter, action: PayloadAction<IObjectResponse>): IAdapter {
    const article = action.payload;
    const ind = state.objects.findIndex((art) => art.id === article.id);
    const objects = _cloneDeep(state.objects);
    objects[ind] = article;

    const currentState = {
      state: STATUSES.FULFILLED,
      objects
    };

    return {
      ...state,
      ...currentState
    };
  }

  public objectSave(state: IAdapter, action: PayloadAction<IObjectData>): IAdapter {
    const object: IObjectData = action.payload;

    const currentState = {
      object
    };

    return {
      ...state,
      ...currentState
    };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
