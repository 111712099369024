import React, { useState } from 'react';
import { Flex, Modal, Button, Box } from '@mantine/core';

import IFrame from 'utils/iframe';
import Group from './Group';
import LanguageSelect from 'components/ui/LanguageSelect/LanguageSelect';

const languages = [
  'ru',
  'en'
];

const hideEnContent = process.env.REACT_APP_HIDE_EN_CONTENT || true;

type Props = {
  data: any;
  onClose: ()=>void
};

function Turbo({ onClose, data: { content } }: Props) {
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const groups = content[currentLanguage]?.blocks?.map((block) => <Group key={block.id} group={block} />);
  const [mobile, setMobile] = useState(false);
  const [desktop, setDesktop] = useState(true);

  const toggleMob = () => {
    setMobile(true);
    setDesktop(false);
  };

  const toggleDesk = () => {
    setMobile(false);
    setDesktop(true);
  };

  const onChange = (lang) => {
    if (currentLanguage !== lang) {
      setCurrentLanguage(lang);
    }
  };

  return (
    <Modal size='90vw' opened onClose={onClose}>
      {!hideEnContent && <LanguageSelect languages={languages} onChange={onChange} language={currentLanguage} />}
      <Flex align='center' justify='center' gap='6px'>
        <Button color='green' onClick={toggleMob}>Mobile</Button>
        <Button color='blue' onClick={toggleDesk}>Desktop</Button>
      </Flex>

      <Flex
        mt='xs'
        miw='98vw'
        mih='100vh'
          // @ts-ignore
        turbo='true'
      >

        <IFrame mobile={mobile}>
          {mobile ? <Box>{groups}</Box> : <Box mih='100vh' maw='788px' m='0 auto'>{groups}</Box>}
        </IFrame>
      </Flex>

    </Modal>
  );
}

export default Turbo;
