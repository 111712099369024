import { startAppListening } from '../../listenerMiddleware';

import { toastify } from 'utils/toastify';

import {
  fetchArticlesAction,
  deleteArticleAction,
  updateArticleAction,
  updateArticleFormAction
} from '../actions';
import { IArticleResponse } from 'types';
import createArticleAction from '../actions/createArticleAction';

startAppListening({
  actionCreator: fetchArticlesAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось получить данные');
  }
});

startAppListening({
  actionCreator: deleteArticleAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось удалить статью');
  }
});

startAppListening({
  actionCreator: deleteArticleAction.fulfilled,
  effect: async (action, listenerApi) => {
    const data: IArticleResponse = action.payload;
    toastify('success', `Статья ${data.title.ru} успешно удалена`);
  }
});

startAppListening({
  actionCreator: updateArticleAction.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', 'Статья успешно обновлена');
  }
});

startAppListening({
  actionCreator: createArticleAction.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', 'Статья успешно создана');
  }
});

startAppListening({
  actionCreator: createArticleAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось создать статью');
  }
});

startAppListening({
  actionCreator: updateArticleFormAction.fulfilled,
  effect: async (action, listenerApi) => {
    const data: IArticleResponse = action.payload;
    toastify('success', `Статья ${data.title.ru} успешно обновлена`);
  }
});
