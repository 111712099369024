import React from 'react';
import { Box, Flex, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

import CommonPart from '../CommonPart';

type Props = {
  hotel: any;
};

function Treatments({ hotel }: Props) {
  const specializationElements = hotel.specializations.map((treatment: any, index: number) => (
    <Flex key={index} p='4px' gap='4px'>
      <IconCheck color='green' string={16} />
      <Text>{treatment.name}</Text>
    </Flex>
  ));

  const isColumn: boolean = specializationElements.length > 1;

  return (
    <CommonPart>
      <Box style={{ columnCount: isColumn ? 2 : 0 }}>{specializationElements}</Box>
    </CommonPart>
  );
}

export default Treatments;
