import React from 'react';

import SelectForm from 'components/ui/SelectForm';
import useOptions from './hooks/useOptions';

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  required?: boolean;
};

const MetaTagsSelect = ({ label, placeholder, name, required = false }: Props) => {
  const options = useOptions();

  return (
    <SelectForm
      width='100%'
      name={name}
      label={label}
      options={options}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default MetaTagsSelect;
