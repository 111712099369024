import React from 'react';
import { Text, Flex } from '@mantine/core';

type Props = React.PropsWithChildren<{
  label: string;
  description: string;
}>;

function InfoBlock({ label, description }: Props) {
  return (
    <Flex direction='column' gap='4px'>
      <Text c='var(--mantine-color-gray-8)'>
        {label}
      </Text>
      <Text fz={14} c='var(--mantine-color-gray-6)'>
        {description}
      </Text>
    </Flex>
  );
}

export default InfoBlock;
