import React from 'react';
import { Badge, Flex, Text } from '@mantine/core';
import { IconThumbUpFilled } from '@tabler/icons-react';

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

type ITopProps = {
  hotel: any;
};

function Top({ hotel }: ITopProps) {
  const handleTitleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}`);
  };

  return (
    <Flex direction='column'>
      <Text style={{ cursor: 'pointer' }} fz='22px' fw='bold' onClick={handleTitleClick}>
        {hotel.name}
      </Text>

      <Flex align='center' gap='12px' mt='12px'>
        {hotel.rating > 0 && (
          <Badge size='md' color='green' leftSection={<IconThumbUpFilled size='16' />}>
            <Text fz='14'>{hotel.rating}</Text>
          </Badge>
        )}

        {hotel.address?.region && (
          <Text fz='14px' fw='500' c='var(--mantine-color-blue-5)'>
            {hotel.address.region}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

export default Top;
