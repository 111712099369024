import { createAsyncThunk } from '@reduxjs/toolkit';

import { roomTypesFetch } from 'apis/ParsingApi';

const ACTION = 'parsing/room_types';

const parsingRoomTypesFetchAction = createAsyncThunk(
  ACTION,
  roomTypesFetch
);

export default parsingRoomTypesFetchAction;
