import React, { useEffect, useState } from 'react';

import type { IOption } from 'types';
import specializationsApi from 'apis/SpecializationsApi/SpecializationsApi';
import MultiSelectForm from 'components/ui/MultiSelectForm';

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  required?: boolean;
};

const SpecializationsSelect = ({ label, placeholder, name, required = false }: Props) => {
  const [specializations, setSpecializations] = useState<IOption[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await specializationsApi.fetchList();

        Array.isArray(data) &&
        setSpecializations(
          data.map((option) => ({
            value: option.tag,
            label: option.name
          }))
        );
      } catch (e) {
        console.log(e, 'err');
      }
    };

    fetch();
  }, []);

  return (
    <MultiSelectForm
      width='100%'
      name={name}
      label={label}
      options={specializations}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default SpecializationsSelect;
