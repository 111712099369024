import React, { useEffect, useState } from 'react';

import timezonesApi from 'apis/TimezonesApi/Timezones';
import SelectForm from 'components/ui/SelectForm';

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  defaultValue?: string;
  required?: boolean;
  width?: string;
};

const TimezonesSelect = ({ label, placeholder, name, defaultValue, required, width }: Props) => {
  const [zones, setZones] = useState<any[]>([]);

  const selectZonesOptions = zones.map((option) => ({
    value: option.name,
    label: option.name
  }));

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await timezonesApi.fetchList();

        Array.isArray(data) && setZones(data);
      } catch (e) {
        console.log(e, 'err');
      }
    };

    fetch();
  }, []);

  return (
    <SelectForm required={required} width={width} defaultValue={defaultValue} name={name} label={label} options={selectZonesOptions as []} placeholder={placeholder} />
  );
};

export default TimezonesSelect;
