import React, { useState } from 'react';
import { Button, Flex, Modal, Text, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';
import { toastify } from 'utils/toastify';
import { OSTROVOK } from '../../AddObject/constants';
import { getProviderTranslate } from '../../List/getProviderTranslate';
import migrateObjectsAction from 'store/objectsSlice/actions/migrateObjectsActions';
import SuccessModal from '../../AddObject/SuccessModal';
import Form from 'components/ui/Form';
import { extendDefaultValues, OSTROVOK_PROVIDER_FIELDS, validationSchema } from './schema';
import InputForm from 'components/ui/InputForm';

type OstrovokProviderFormProps = {
    setShowModal: (b: boolean)=>void;
    showModal: boolean;
    id: string;
}

function OstrovokProviderModal({ setShowModal, showModal, id }:OstrovokProviderFormProps) {
  const { t } = useTranslation();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useAppDispatch();

  const handleSubmit = async (data:any) => {
    const formatedData = {
      ...data,
      provider: OSTROVOK
    };

    const result = await dispatch(migrateObjectsAction({ object: formatedData, id }));

    // @ts-ignore
    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      setShowModal(false);
      setOpenSuccessModal(true);
      setModalData(result?.payload?.meta);
    }
  };

  const handleReset = () => {
    setShowModal(false);
    setOpenSuccessModal(false);
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  return (
    <>
      <Modal
        withCloseButton={false} centered closeOnClickOutside={false} onClose={() => setShowModal(false)}
        opened={showModal}
      >
        <Form
          schema={validationSchema()}
          defaultValues={extendDefaultValues({})}
          onSubmit={handleSubmit}
          onError={handleError}
          onReset={handleReset}
        >

          <Text fz='30px'>{t('Objects.Provider.Ostrovok.Title')}</Text>
          <Text fz='30px'>{getProviderTranslate(OSTROVOK)}</Text>
          <Box my='lg'>
            <InputForm
              name={OSTROVOK_PROVIDER_FIELDS.PROVIDER_ID}
              label={t('Objects.Form.Ostrovok.ProviderId')}
              placeholder={t('Objects.Form.Ostrovok.ProviderId.Placeholder')}
              required
            />
          </Box>
          <Flex mt='lg' align='center' gap='md'>
            <Button type='submit' color='green'>
              {t('Action.Save')}
            </Button>
            <Button type='reset' color='gray'>
              {t('Action.Cancel')}
            </Button>
          </Flex>
        </Form>
      </Modal>
      <SuccessModal setOpenModal={setOpenSuccessModal} openModal={openSuccessModal} modalData={modalData} />
    </>

  );
}

export default OstrovokProviderModal;
