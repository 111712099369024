import React from 'react';
import { Box } from '@mantine/core';

type InfoBlockProps = {
  data: any;
}

function InfoBlock({ data, ...rest }:InfoBlockProps) {
  return <Box fz='18px' mt='24px' p='32px' bg='#f5f5f7' {...rest} dangerouslySetInnerHTML={{ __html: data.title }} />;
}

export default InfoBlock;
