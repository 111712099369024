import React from 'react';
import dateFormat from 'utils/dateFormat';
import { Paper, Image, Badge, Flex, Text, Button, Box } from '@mantine/core';

import Switch from 'components/ui/Switch';
import CardPopover from 'components/ui/Card/Popover';
import ConditionalComponent from 'components/ConditionalComponent';

import { type BlogCardsProps, type IArticleResponse } from 'types';

import classes from './Default.module.css';

function DefaultCard({
  blog,
  onEdit,
  onPublish,
  onEditForm,
  sitePath,
  onDelete
}: Omit<BlogCardsProps, 'type' | 'data'> & { blog: IArticleResponse }) {
  const { title, published, published_at, image } = blog;

  return (
    <Paper withBorder shadow='md' p='xs' radius='md' className={classes.paper_root}>
      <Flex direction='column'>
        <Flex pos='relative' mb={image?.url ? '0' : 'auto'}>
          <ConditionalComponent condition={image && 'url' in image}>
            <Box
              style={{
                blockSize: '142px',
                inlineSize: '100%'
              }}
            >
              <Image src={image?.url} h='100%' style={{ 'aspect-ratio': '63/30' }} />
            </Box>
          </ConditionalComponent>
          {blog.category && (
            <Badge pos='absolute' left='10px' top='10px'>
              {blog.category.name}
            </Badge>
          )}
        </Flex>

        <Flex direction='column' p='16px' justify='space-between' flex={image?.url ? '1 1 100%' : 'none'}>
          <Text fz='sm' lineClamp={2} mb='auto'>
            {title.ru}
          </Text>

          {published_at && (
            <Text fz='xs' c={published ? 'green' : 'dark'} mt='sm' lineClamp={1} title={dateFormat(published_at)}>
              {published
                ? `Опубликовано ${dateFormat(published_at)}`
                : `Снято с публикации ${dateFormat(published_at)}`}
            </Text>
          )}

          <Flex align='center' mt='md'>
            <Flex align='center' gap='xs'>
              <Button color='gray' onClick={() => onEdit(blog.id)} size='xs'>
                Редактировать
              </Button>
              <CardPopover blog={blog} sitePath={sitePath} onEditForm={onEditForm} onDelete={onDelete} />
            </Flex>
            <Flex w='100%' justify='flex-end'>
              <Switch value={published} onChange={(checked: boolean) => onPublish(blog.id, checked)} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Paper>
  );
}

export default DefaultCard;
