import React, { useMemo, useState } from 'react';
import { Box, Flex, Select, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { filter } from 'fuzzaldrin-plus';

import { useAppSelector } from 'store';
import { catalogSelector } from 'store/searchAreaSlice/selectors/catalogSelector';

type Props = React.PropsWithChildren<{
  catalogSlug?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: (data: string | undefined) => void;
}>;

function CatalogSelect({ onSelect, catalogSlug }: Props) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const [data] = useAppSelector(catalogSelector);

  const values = useMemo(() => {
    let catalogValues;

    const chars = searchValue.trim();

    if (chars.length < 3) {
      catalogValues = data.slice(0, 30);
    } else {
      catalogValues = data.filter(element => {
        const result = filter([element.name], chars);
        return result.length === 1;
      });
    }

    return catalogValues.map(d => ({
      label: d.name,
      value: d.href
    }));
  }, [searchValue, data]);

  const handleSelect = async (catalogTag: string) => {
    onSelect(catalogTag);
  };

  const handleSearchChange = (q: string) => {
    setSearchValue(q);
  };

  return (
    <Box>
      <Text fz={14}>{t('SearchArea.Catalog.Title')}</Text>

      <Select
        onSearchChange={handleSearchChange}
        data={values.length > 0 ? values : [{ label: t('Common.Form.Empty'), value: '' }]}
        placeholder={t('Common.Name.Placeholder')}
        searchable
        clearable
        value={catalogSlug}
        searchValue={searchValue}
        onChange={handleSelect}
      />
      <Flex direction='column' gap='xs' mt='md' />
    </Box>
  );
}

export default CatalogSelect;
