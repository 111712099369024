import connection from 'connection/instance';

const API_BASE = '/api/filters/sources';

const handleRequest = async (method, url) => {
  try {
    const response = await connection[method](url);
    return { data: response.data.data, error: undefined };
  } catch (e) {
    return { data: undefined, error: e };
  }
};

class SourcesApi {
  fetchList() {
    return handleRequest('get', API_BASE);
  }
}

export default new SourcesApi();
