function longWithTimeFormat(date) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  };

  return new Intl.DateTimeFormat('ru-RU', options).format(date);
}

function longFormat(date) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  return new Intl.DateTimeFormat('ru-RU', options).format(date);
}

function dateFormat(dateStr, format = 'long') {
  let date = Date.parse(dateStr);
  date = new Date(date);

  switch (format) {
    case 'long':
      return longFormat(date);
    case 'long_with_time':
      return longWithTimeFormat(date);

    default:
      return longWithTimeFormat(date);
  }
}

export default dateFormat;
