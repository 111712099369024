import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import { IObjectResponse, IArticlesMeta, IObjectResponseData } from 'types';

import {
  IObjectDataFull,
  IObjectResponseDataOne,
  IObjectsMeta
} from 'types/object';
import { IParsingResponse } from 'types/parsing';

export interface IRoomTypesResponseData {
  room_types: any[]
}

export interface IObjectsParams {
  id: string;
  object: IObjectDataFull;
}

export interface IObjectParams {
  id: string,
  lang: string
}

export interface IParsingCreateRequest {
  uuid: string,
  hotel_code: string
}

const tariffDefaultParams = {
  stay_period: { min_days: 0 },
  release_period: { count: 0 },
  promo: { applied_percent_off: 0 }
};

export const parsingFetch = async (): Promise<IParsingResponse[]> => {
  const response: AxiosResponse<IObjectResponseData<IParsingResponse[], IObjectsMeta>> = await connection.get(
    '/api/travelline/parsing_requests'
  );

  return response.data.data;
};

export const roomTypesFetch = async (id: string): Promise<any[]> => {
  const response: AxiosResponse<IRoomTypesResponseData> = await connection.get(
    `/api/hotels/${id}/room_types`
  );

  return response.data.room_types;
};

export const parseAsync = async (id: string): Promise<IParsingResponse> => {
  const response: AxiosResponse<IObjectResponseData<IParsingResponse, IObjectsMeta>> = await connection.put(
    '/api/travelline/parsing_requests/parse_async',
    { uuid: id }
  );

  response.data.data.uuid = id;

  return response.data.data;
};

export const tariffsFetch = async (uuid: string) => {
  const response: AxiosResponse<any> = await connection.get(`/api/travelline/parsing_requests/${uuid}`);

  return response.data.data;
};

export const parsingCreate = async (params: IParsingCreateRequest) => {
  const response: AxiosResponse<IObjectResponseData<IParsingResponse, IArticlesMeta>> = await connection.post(
    '/api/travelline/parsing_requests',
    { ...params }
  );

  return response.data.data;
};

export const parsingTariffCreate = async (data: any) => {
  const { id, tariff } = data;
  const response: AxiosResponse<IObjectResponseData<IParsingResponse, IArticlesMeta>> = await connection.post(
    `/api/hotels/${id}/tariffs`,
    {
      tariff: {
        ...tariffDefaultParams,
        ...tariff
      }
    }
  );

  return response.data.data;
};

export const objectsUpdate = async (data: IObjectsParams) => {
  const response: AxiosResponse<IObjectResponseDataOne<IObjectResponse, IArticlesMeta>> = await connection.put(
    `/api/hotels/${data.id}`,
    { data: data.object }
  );

  return response.data.hotel;
};

export const objectsDelete = async (id: string) => {
  const response: AxiosResponse<IObjectResponseData<IObjectResponse, IArticlesMeta>> = await connection.delete(
    `/api/hotels/${id}`
  );

  return response.data.data;
};

export const objectsPublish = async (id: string) => {
  const response: AxiosResponse<IObjectResponseData<IObjectResponse, IArticlesMeta>> = await connection.put(
    `/api/hotels/${id}/publish`
  );

  return response.data.data;
};

export const objectsUnpublish = async (id: string): Promise<IObjectResponse> => {
  const response: AxiosResponse<IObjectResponseData<IObjectResponse, IArticlesMeta>> = await connection.put(
    `/api/hotels/${id}/unpublish`
  );

  return response.data.data;
};
