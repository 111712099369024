import { createAsyncThunk } from '@reduxjs/toolkit';

import { objectsMigrate } from 'apis/ObjectsApi';

const ACTION = 'objects/migrate';

const migrateObjectsAction = createAsyncThunk(

  ACTION,
  objectsMigrate

);

export default migrateObjectsAction;
