import React from 'react';
import { Grid } from '@mantine/core';

import Card from 'components/ui/Card';

import { type BlogCardsProps } from 'types';

import classes from './BlockCards.module.css';

function BlockCards({ data, ...props }: BlogCardsProps) {
  return (
    <Grid gutter='lg' className={classes.grid}>
      {data.map((blog) => (
        <Grid.Col key={blog.id} span='auto'>
          <Card blog={blog} {...props} />
        </Grid.Col>
      ))}
    </Grid>
  );
}

export default BlockCards;
