type Procedure = {
  content: string,
  title: string,
}

function proceduresMapper(programs: Procedure[]) {
  return {
    type: 'procedures',
    data: {
      list: programs.map((p) => {
        const { title, content } = p;
        const htmlContent = new DOMParser().parseFromString(content, 'text/html');
        const elements = htmlContent.querySelectorAll('li');
        const includes = Array.from(elements).map((e) => e.innerText);

        return {
          title,
          includes
        };
      })
    }
  };
}

export default proceduresMapper;
