import React, { useEffect, useState } from 'react';
import { Text, Combobox, InputBase, useCombobox, Flex } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import ConditionalComponent from 'components/ConditionalComponent';
import useRegions from 'components/ui/RegionSelectForm/hook/useRegions';
import { getPlaceById } from '../../utils';

import classes from './RegionSelectForm.module.css';

type Props = React.PropsWithChildren<{
  name: string;
  label: string;
  placeholder: string;
}>;

function FullRegionSelectForm({ name, placeholder, label }: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const [regions, { fetch }] = useRegions();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const [searchValue, setSearchValue] = useState(value?.label_ru || '');
        const [debouncedSearch] = useDebouncedValue(searchValue, 600);

        // Эффект для поиска регионов
        useEffect(() => {
          if (debouncedSearch && debouncedSearch.length > 2) {
            fetch(debouncedSearch);
          }
        }, [debouncedSearch]);

        // Эффект для синхронизации с value из формы
        useEffect(() => {
          setSearchValue(value?.label_ru || '');
        }, [value]);

        return (
          <Combobox
            store={combobox}
            onOptionSubmit={(val) => {
              const selectedItem = getPlaceById(regions, val);
              if (selectedItem) {
                setSearchValue(selectedItem.label_ru);
                onChange(selectedItem);
              }
              combobox.closeDropdown();
            }}
          >
            <Combobox.Target>
              <InputBase
                label={label}
                rightSection={<Combobox.Chevron />}
                value={searchValue}
                onChange={(event) => {
                  const newValue = event.target.value;
                  setSearchValue(newValue);
                  if (newValue === '') {
                    onChange(null);
                  }
                  combobox.openDropdown();
                  combobox.updateSelectedOptionIndex();
                }}
                onFocus={() => {
                  regions.length && combobox.openDropdown();
                }}
                onBlur={() => combobox.closeDropdown()}
                placeholder={placeholder || t('SearchArea.RegionSelect.Placeholder')}
                rightSectionPointerEvents='none'
              />
            </Combobox.Target>

            <Combobox.Dropdown className={classes.options}>
              <Combobox.Options>
                <ConditionalComponent
                  condition={regions.length > 0}
                  fallback={<Combobox.Empty>Nothing found</Combobox.Empty>}
                >
                  <>
                    {regions.map((item) => (
                      <Combobox.Option value={item.place_id} key={item.place_id}>
                        <Flex direction='column' gap='4px'>
                          <Text size='sm'>{item.label_ru}</Text>
                          <Text size='xs' c='dimmed'>
                            {item.description_ru}
                          </Text>
                        </Flex>
                      </Combobox.Option>
                    ))}
                  </>
                </ConditionalComponent>
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        );
      }}
    />
  );
}

export default FullRegionSelectForm;
