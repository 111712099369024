import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum SANATORY_PROVIDER_FIELDS {
  EMAIL = 'email',
  SOURCES = 'sources',
  NUMBER = 'number',
  COMMISSION = 'commission_in_percent',
  DATE = 'from_date',
  CONTRACTS = 'contracts'
}

export const validationSchema = () =>
  yup.object({
    [SANATORY_PROVIDER_FIELDS.EMAIL]: yup.string().email('Введите корректный e-mail').required('Обязательное поле'),
    [SANATORY_PROVIDER_FIELDS.SOURCES]: yup.array().of(
      yup.object().shape({
        [SANATORY_PROVIDER_FIELDS.CONTRACTS]: yup.array().of(
          yup.object().shape({
            [SANATORY_PROVIDER_FIELDS.NUMBER]: yup.string().required('Обязательное поле'),
            [SANATORY_PROVIDER_FIELDS.DATE]: yup.string().required('Обязательное поле'),
            [SANATORY_PROVIDER_FIELDS.COMMISSION]: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .required('Обязательное поле')
              .min(0, 'Значение должно быть не менее 0')
              .max(100, 'Значение должно быть не более 100')
          })
        )
      })
    )
  });

export const defaultValues = {};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
