import React from 'react';
import { Flex, Paper, Text } from '@mantine/core';

type LanguageSelectProps = {
  languages: string[];
  onChange: (lang: string) => void;
  language: string;
};

function LanguageSelect({ language, languages, onChange }: LanguageSelectProps) {
  const itemsComponent = languages.map((lan, index) => (
    <Paper
      style={{
        cursor: 'pointer',
        aspectRatio: 1
      }}
      w='32px'
      p='9px 4px 0 4px'
      radius='50%'
      bg={language === lan ? 'var(--mantine-color-green-4)' : 'var(--mantine-color-gray-4)'}
      key={index}
      onClick={() => onChange(lan)}
    >
      <Flex align='center' justify='center'>
        <Text fw='bold' tt='uppercase' c='white' style={{ lineHeight: 1 }}>
          {lan}
        </Text>
      </Flex>
    </Paper>
  ));

  return (
    <Flex align='center' gap='xs'>
      {itemsComponent}
    </Flex>
  );
}

export default LanguageSelect;
