import { type IOption } from 'types';

const TAGS: IOption[] = [
  {
    value: 'property',
    label: 'property'
  },
  {
    value: 'name',
    label: 'name'
  },
  {
    value: 'httpEquiv',
    label: 'httpEquiv'
  }
];

function useOptions() {
  return TAGS;
}

export default useOptions;
