import pasteEvent from '../utils/pasteEvent';
import _uniqueId from 'lodash/uniqueId';

export default class Ui {
  constructor({ list, onAdd, onAddInclude, t, onRemove, onRemoveInclude, description, toList }) {
    this.list = list;
    this.toList = toList;
    this.description = description;
    this.onAdd = onAdd;
    this.onAddInclude = onAddInclude;
    this.onRemoveInclude = onRemoveInclude;
    this.onRemove = onRemove;
    this.t = t;

    this.nodes = {
      description: make('div', this.CSS.description, {
        contentEditable: true,
        innerHTML: this.description
      }),
      listField: make('div', this.CSS.listField, {
        contentEditable: true
      }),
      wrapper: make('div', this.CSS.wrapper),
      listElement: make('div', [this.CSS.listElement]),
      title: make('h2', this.CSS.title, {
        innerHTML: t('Programs.Title')
      }),
      includesContainer: make('div', this.CSS.listIncludes),
      includesTitle: make('div', this.CSS.listIncludesTitle),
      includesElement: make('div', this.CSS.listIncludesElement, {
        contentEditable: true
      }),
      listContainer: make('div', this.CSS.listContainer),
      button: this.createAddButton(this.onAdd)
    };

    this.nodes.title.addEventListener('paste', pasteEvent);
    this.nodes.description.addEventListener('paste', pasteEvent);
    this.nodes.listField.addEventListener('paste', pasteEvent);
  }

  createAddButton(callback, params) {
    const button = make('div', this.CSS.button, {
      innerHTML: this.t('Common.Add')
    });

    button.addEventListener('click', () => {
      callback(params);
    });

    return button;
  }

  get CSS() {
    return {
      wrapper: 'programs',
      listField: 'programs_listField',
      description: 'programs_description',
      listElement: 'programs_element',
      title: 'programs_title',
      listContainer: 'programs_list',
      remove: 'programs_settings_remove',
      listIcon: 'programs_list_icon',
      button: 'programs_add',
      removeIcon: 'programs_element_remove',
      listText: 'programs_element_text',
      listTitle: 'programs_element_title',
      listDuration: 'programs_element_duration',
      listIncludes: 'programs_includes',
      listIncludesTitle: 'programs_includes_title',
      listIncludesElement: 'programs_includes_element',
      listIncludesText: 'programs_includes_text'
    };
  }

  getData() {
    const list = this.nodes.wrapper.getElementsByClassName(this.CSS.listElement);
    const titleElement = this.nodes.wrapper.querySelector(`.${this.CSS.title}`);
    const description = this.nodes.description;

    return {
      list: Array.from(list).map((el) => {
        const title = el.querySelector(`.${this.CSS.listTitle}`);
        const description = el.querySelector(`.${this.CSS.listText}`);
        const includes = el.getElementsByClassName(this.CSS.listIncludesElement);
        const duration = el.querySelector(`.${this.CSS.listDuration}`);

        return {
          title: title.innerHTML,
          id: _uniqueId(),
          description: description.innerHTML,
          includes: Array.from(includes).map((el) => el.innerText),
          duration: duration.innerHTML
        };
      }),
      listDevelop: Array.from(list).map((el) => {
        const includes = el.getElementsByClassName(this.CSS.listIncludesElement);

        return {
          includes: Array.from(includes).map((el) => el.innerHTML)
        };
      }),
      title: titleElement.innerHTML,
      description: description.innerHTML
    };
  }

  renderList() {
    return this.list.map((element, index) => {
      const listElement = make('div', this.CSS.listElement);

      const listText = make('div', this.CSS.listText, {
        contentEditable: true,
        innerHTML: element.description
      });

      const listTitle = make('h3', this.CSS.listTitle, {
        contentEditable: true,
        innerHTML: element.title
      });

      const listDuration = make('div', this.CSS.listDuration, {
        contentEditable: true,
        innerHTML: element.duration
      });

      listDuration.setAttribute('data-before', this.t('Programs.Data.List.Duration'));

      listText.addEventListener('paste', pasteEvent);
      listTitle.addEventListener('paste', pasteEvent);
      listDuration.addEventListener('paste', pasteEvent);

      const removeIcon = make('div', this.CSS.removeIcon);

      removeIcon.addEventListener('click', () => {
        this.onRemove(index);
      });

      listElement.appendChild(listTitle);
      listElement.appendChild(listDuration);
      listElement.appendChild(listText);
      listElement.appendChild(removeIcon);
      listElement.appendChild(this.renderListPrograms(element, index));

      return listElement;
    });
  }

  renderListPrograms(element, elementIndex) {
    const listIncludesContainer = make('div', this.CSS.listIncludes);
    const listIncludesTitle = make('div', this.CSS.listIncludesTitle, {
      innerHTML: this.t('Programs.List.Includes.Title')
    });

    listIncludesTitle.addEventListener('paste', pasteEvent);

    listIncludesContainer.appendChild(listIncludesTitle);
    const button = this.createAddButton(this.onAddInclude, elementIndex);

    element.includes.forEach((include, index) => {
      const removeIcon = make('div', this.CSS.removeIcon);
      const listIcon = make('div', this.CSS.listIcon, {
        innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.999 494.999"><path d="M105.031 119.481h284.938c32.988 0 59.742-26.743 59.742-59.741C449.71 26.743 422.957 0 389.968 0H105.031C72.043 0 45.289 26.743 45.289 59.74 45.289 92.738 72.043 119.481 105.031 119.481zM389.968 187.757H105.031c-32.987 0-59.742 26.747-59.742 59.744 0 32.994 26.755 59.741 59.742 59.741h284.938c32.988 0 59.742-26.747 59.742-59.741C449.71 214.504 422.957 187.757 389.968 187.757zM389.968 375.518H105.031c-32.987 0-59.742 26.747-59.742 59.741 0 32.997 26.755 59.74 59.742 59.74h284.938c32.988 0 59.742-26.743 59.742-59.74C449.71 402.265 422.957 375.518 389.968 375.518z"/></svg>'
      });

      removeIcon.addEventListener('click', () => {
        this.onRemoveInclude(index, elementIndex);
      });

      listIcon.addEventListener('click', () => {
        this.toList(index, elementIndex);
      });

      const listIncludesElement = make('div', this.CSS.listIncludesElement);

      const listIncludesText = make('div', this.CSS.listIncludesText, {
        contentEditable: true,
        innerHTML: include
      });

      listIncludesText.addEventListener('paste', pasteEvent);

      listIncludesElement.appendChild(listIncludesText);
      listIncludesElement.appendChild(listIcon);
      listIncludesElement.appendChild(removeIcon);

      listIncludesContainer.appendChild(listIncludesElement);
    });

    listIncludesContainer.appendChild(button);

    return listIncludesContainer;
  }

  render() {
    const list = this.renderList();

    this.clear(this.nodes.listContainer);

    list.forEach((element) => {
      this.nodes.listContainer.appendChild(element);
    });

    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.description);
    this.nodes.wrapper.appendChild(this.nodes.listContainer);
    this.nodes.wrapper.appendChild(this.nodes.button);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  }

  displayErrorDescription = (flag) => {
    flag ? this.nodes.description.classList.add('error') : this.nodes.description.classList.remove('error');

    return this.nodes.description;
  }

  displayErrorItemTitle = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listTitle);
  }

  displayErrorItemDescription = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listText);
  }

  displayErrorItemDuration = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listDuration);
  }

  displayItemElementError = (flag, index, cssClass) => {
    const item = this.getItem(index);
    const element = item.querySelector(`.${cssClass}`);

    flag ? element.classList.add('error') : element.classList.remove('error');

    return element;
  }

  getItem = (index) => {
    return this.nodes.wrapper.getElementsByClassName(this.CSS.listElement)[index];
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
