import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

function Bottom({ hotel }) {
  const { t } = useTranslation();

  return (
    <div className='hotelCard_bottom_wrapper'>
      <div className='hotelCard_bottom_priceContainer'>
        <a href={`${site}/hotel/${hotel.slug}#roomTypes`} target='_blank' className='hotelCard_bottom_button' rel='noreferrer'>
          {t('HotelCard.Action.SelectRoom')}
        </a>
        {hotel.review?.total > 0 && (
          <a href={`${site}/hotel/${hotel.slug}/reviews`} target='_blank' className='hotelCard_reviews' rel='noreferrer'>
            {hotel.review.total} отзывов
          </a>
        )}
      </div>

      <div className='hotelCard_bottom_description' dangerouslySetInnerHTML={{ __html: hotel.description }} />
    </div>
  );
}

Bottom.propTypes = {
  hotel: PropTypes.object
};

export default Bottom;
