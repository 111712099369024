import React from 'react';
import { Box, Flex, Text, Badge } from '@mantine/core';
import { IconStarFilled, IconThumbUpFilled } from '@tabler/icons-react';

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

type TopProps = {
  hotel: any;
};
function Top({ hotel }: TopProps) {
  const amenitiesComponent = hotel.amenities.map((am, index) => (
    <Badge color='var(--mantine-color-gray-4)' key={index}>
      {am.text}
    </Badge>
  ));

  const handleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}/reviews`);
  };

  return (
    <Flex gap='12px' w='100%'>
      <Box>
        <Flex align='center'>
          <Text fz='28px' dangerouslySetInnerHTML={{ __html: hotel.name }} />
          {hotel.star > 3 && (
            <Flex gap='8px'>
              {Array.from({ length: hotel.star }).map((_, idx) => (
                <IconStarFilled key={idx} color='orange' />
              ))}
            </Flex>
          )}
        </Flex>

        {hotel.address?.location && <Box mt='12px'>{hotel.address.location}</Box>}

        <Flex gap='8px' wrap='wrap' my='12px'>
          {amenitiesComponent}
        </Flex>
      </Box>

      <Box miw='96px' maw='96px'>
        {hotel.rating > 0 && (
          <Badge size='md' color='green' leftSection={<IconThumbUpFilled size='16' />}>
            <Text fz='14'>{hotel.rating}</Text>
          </Badge>
        )}

        {hotel.review?.total > 0 && (
          <Text c='var(--mantine-color-blue-5)' mt='12px' style={{ cursor: 'pointer' }} onClick={handleClick}>
            {hotel.review.total} отзывов
          </Text>
        )}
      </Box>
    </Flex>
  );
}

export default Top;
