import React from 'react';
import { Text, Box, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Rate from '../Rate';
import { IPeriod } from 'types/parsing';

type Props = React.PropsWithChildren<{
  periods: IPeriod[];
}>;

function Rates({ periods }: Props) {
  const { t } = useTranslation();

  const periodsComponent = periods.map((period, index) => {
    const date: string = [
      dayjs(period.start_date).format('DD.MM.YYYY'),
      dayjs(period.end_date).format('DD.MM.YYYY')
    ].join(' - ');

    return (
      <Flex key={index} direction='column' gap='4px'>
        <Text fw={600} fz={14}>
          {date}
        </Text>

        <Box ml='lg'>
          {period.rates.map((rate, index) => (
            <Rate rate={rate} key={index} />
          ))}
        </Box>
      </Flex>
    );
  });

  return (
    <Flex direction='column' gap='24px'>
      <Text c='var(--mantine-color-gray-8)'>{t('Tariffs.Periods.Title')}</Text>

      <Box>{periodsComponent}</Box>
    </Flex>
  );
}

export default Rates;
