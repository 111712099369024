import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum AUTHORS_FIELDS {
  FIRST_NAME_FIELD = 'first_name',
  LAST_NAME_FIELD = 'last_name',
  MIDDLE_NAME_FIELD = 'middle_name',
  AVATAR_FIELD = 'avatar',
  START_OF_PRACTICE_FIELD = 'start_of_practice',
  SPECIALIZATIONS_FIELD = 'specializations',
  SEO_TITLE_FIELD = 'seo_title',
  SEO_DESCRIPTION_FIELD = 'seo_description',
  NAME_FIELD = 'name',
  TAG_FIELD = 'tag',
  CONTENT_FIELD = 'content'
}

export const validationSchema = () =>
  yup.object({
    [AUTHORS_FIELDS.FIRST_NAME_FIELD]: yup.string().required('Обязательное поле'),
    [AUTHORS_FIELDS.LAST_NAME_FIELD]: yup.string().required('Обязательное поле'),
    [AUTHORS_FIELDS.SEO_TITLE_FIELD]: yup.string().required('Обязательное поле'),
    [AUTHORS_FIELDS.SEO_DESCRIPTION_FIELD]: yup.string().required('Обязательное поле')
  });

export const defaultValues = {};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
