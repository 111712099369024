import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, Add, Edit, EditForm } from './containers';

function Diseases() {
  return (
    <Routes>
      <Route path='/:id/edit' element={<Edit />} />

      <Route path='/' element={<List />}>
        <Route path='/add' element={<Add />} />

        <Route path='/:id/editform' element={<EditForm />} />
      </Route>
    </Routes>
  );
}

export default Diseases;
