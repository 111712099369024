import connection from 'connection/instance';

const API_BASE = '/api/specializations';

const handleRequest = async (method, url) => {
  try {
    const response = await connection[method](url);
    return { data: response.data.data, error: undefined };
  } catch (e) {
    return { data: undefined, error: e };
  }
};

class SpecializationsApi {
  fetchList() {
    return handleRequest('get', API_BASE);
  }
}

export default new SpecializationsApi();
