import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import AddObject from './AddObject';

function Objects() {
  return (
    <Routes>
      <Route path='/:id/edit' element={<Edit />} />

      <Route path='/' element={<List />} />
      <Route path='/new' element={<AddObject />} />
    </Routes>
  );
}

export default Objects;
