import React from 'react';
import { Flex, Text } from '@mantine/core';

type Props = {
  group: any;
};

function Group({ group }: Props) {
  const elementsComponent = group?.children?.map((element: any) => (
    <Text mb='16px' key={element.id}>
      {element.name}
    </Text>
  ));

  return (
    <Flex direction='column' gap='4px'>
      <Text fz={20} fw='500'>
        {group.name}
      </Text>
      {elementsComponent}
    </Flex>
  );
}

export default Group;
