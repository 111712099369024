import React from 'react';
import { Box } from '@mantine/core';
import cx from 'clsx';

import classes from './BottomBar.module.css';

type Props = React.PropsWithChildren<{
  className?: string
}>

function BottomBar({ children, className }: Props) {
  return (
    <Box className={cx(className, classes.root)}>
      {children}
    </Box>
  );
}

export default BottomBar;
