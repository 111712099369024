import { type ArticleFormFields, type ArticleRequest } from 'types';

export const getFormattedFormData = (formData: ArticleFormFields) => {
  const formattedData: ArticleRequest = {
    type: formData?.type,
    category: {
      tag: formData.category
    },
    reviewer: {
      id: formData?.reviewer
    },
    title: {
      ru: formData?.title_ru || null,
      en: formData?.title_en || null
    },
    sub_title: {
      ru: formData?.title_ru || null,
      en: formData?.title_en || null
    },
    seo_title: {
      ru: formData?.seo_title_ru || null,
      en: formData?.seo_title_en || null
    },
    seo_description: {
      ru: formData?.seo_description_ru || null,
      en: formData?.seo_description_en || null
    },
    meta_tags: [
      {
        name: 'Description',
        property: null,
        content: formData?.seo_description_ru || null
      },
      ...formData.meta_tags
    ]
  };

  return formattedData;
};
