import { createAsyncThunk } from '@reduxjs/toolkit';
import { searchAreaUpdate } from 'apis/SearchAreaApi';

const ACTION = 'search_area/update';

const updateSearchArea = createAsyncThunk(
  ACTION,
  searchAreaUpdate
);

export default updateSearchArea;
