import React from 'react';
import { Flex } from '@mantine/core';

import Editor from 'components/forms/Editor';
import { AuthorResponse } from 'types/authors';

type Props = {
  reviewer: AuthorResponse;
  contentRef: any;
};

function Body({ reviewer, contentRef }: Props) {
  const { id, content } = reviewer;

  return (
    <Flex align='center' justify='center' m='0 auto'>
      <Editor
        content={content}
        autoSaveUrl={`/api/reviewers/${id}/autosave`}
        uploadImgUrl={`/api/reviewers/${id}/attachments`}
        selectImgUrl={`/api/reviewers/${id}/attachments`}
        contentRef={contentRef}
      />
    </Flex>
  );
}

export default Body;
