import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import { IRegionRequest, IRegionResponse } from 'types';

const API_BASE = '/api/ts/collections/regions/documents';

const createParams = (q?: string): IRegionRequest => ({
  q: q?.toLowerCase(),
  query_by: 'index_strings',
  sort_by: '_text_match:desc',
  num_typos: 1
});

const headers = {
  'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY
};

export const regionFetch = async (q?: string): Promise<IRegionResponse> => {
  const response: AxiosResponse<IRegionResponse> = await connection.get(
    `${API_BASE}/search`,
    { params: createParams(q), headers }
  );

  return response.data;
};
