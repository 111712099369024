import React from 'react';

import DefaultCard from './DefaultCard';
import ListCard from './ListCard';

import { type BlogCardsProps, type IArticleResponse } from 'types';

function Card({ type = 'default', ...rest }: Omit<BlogCardsProps, 'data'> & { blog: IArticleResponse }) {
  switch (type) {
    case 'list':
      return <ListCard {...rest} />;

    default:
      return <DefaultCard {...rest} />;
  }
}

export default Card;
