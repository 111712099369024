import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@mantine/core';

import RegionSelectForm from 'components/ui/RegionSelectForm';
import InputForm from 'components/ui/InputForm';
import { PARSING_FIELDS } from './schema';

type Props = React.PropsWithChildren<{
  loading: boolean;
}>;

function AddParsingForm({ loading }: Props) {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap='md'>
      <RegionSelectForm placeholder={t('SearchArea.Objects.Placeholder')} type='hotels' name={PARSING_FIELDS.PLACE} />
      <InputForm name={PARSING_FIELDS.HOTEL_CODE} placeholder={t('Parsing.Add.UUID.Placeholder')} />
      <Button color='green' type='submit' loading={loading}>
        {t('Common.Create')}
      </Button>
    </Flex>
  );
}

export default AddParsingForm;
