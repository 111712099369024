import { useState, useEffect, useRef } from 'react';

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.src = src;
    s.async = true;
    s.onerror = (err) => reject(err, s);

    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(src);
      }
    };

    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
}

async function loadGroups(groups) {
  const iteratee = (src) => loadScript(src);

  return Object.values(groups)
    .reduce(async (memo, group) => [
      ...(await memo),
      ...(await Promise.all(group.map(iteratee)))
    ], []);
}

function useScripts(groups) {
  const isMounted = useRef();

  const [state, setState] = useState(undefined);

  const fetch = async () => {
    if (isMounted.current === true) {
      return;
    }

    try {
      await loadGroups(groups);
      isMounted.current = true;
      setState('done');
    } catch {
      setState('error');
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const loaded = state === 'done';
  const error = state === 'error';

  return [loaded, error];
}

export default useScripts;
