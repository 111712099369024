import React, { useEffect, useState } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { Text, Combobox, InputBase, useCombobox, Flex } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import ConditionalComponent from 'components/ConditionalComponent';
import useRegions from './hook/useRegions';
import useHotels from './hook/useHotels';
import classes from './RegionSelectForm.module.css';

type Props = React.PropsWithChildren<{
  name: string;
  label?: string;
  placeholder?: string;

  onSelect?: (region: any) => void;
  type?: 'regions' | 'hotels';

  value?: any;
  defaultName?: string;
  required?: boolean;
  clearAfterSelect?: boolean;
}>;

function RegionSelectForm({
  name,
  onSelect,
  placeholder,
  value,
  type = 'regions',
  defaultName = '',
  label,
  clearAfterSelect,
  required
}: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const [inputValue, setInputValue] = useState(() => defaultName);
  const [debouncedInputValue] = useDebouncedValue(inputValue, 600);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const [regions, { fetch }] = type === 'regions' ? useRegions() : useHotels();
  const values = value ? [value] : [];

  const names = _uniqBy([...values, ...regions], 'place_id').map((h) => ({
    label: h.label_ru,
    value: h.place_id,
    description: h.description_ru
  }));

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 2) {
      fetch(debouncedInputValue);
    }
  }, [fetch, debouncedInputValue]);

  useEffect(() => {
    if (defaultName) {
      fetch(defaultName);
      setInputValue(defaultName);
    }
  }, [defaultName]);

  useEffect(() => {
    const selectedItem = names.find((item) => item.value === value);
    if (selectedItem) {
      setInputValue(selectedItem.label);
    }
  }, [value, names]);

  const handleSelect = (region: string) => {
    const regionObj = regions?.find((r) => r.place_id === region);

    if (regionObj) {
      onSelect && onSelect(regionObj);
    }
    if (clearAfterSelect) setTimeout(handleClear, 0);
  };

  const handleClear = () => {
    setInputValue('');
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Combobox
          store={combobox}
          onOptionSubmit={(val) => {
            const selectedItem = names.find((item) => item.value === val);
            onChange(val);
            setInputValue(selectedItem?.label || '');
            handleSelect(val);
            combobox.closeDropdown();
          }}
        >
          <Combobox.Target>
            <InputBase
              label={label}
              rightSection={<Combobox.Chevron />}
              value={inputValue}
              required={required}
              onChange={(event) => {
                const newValue = event.currentTarget.value;
                setInputValue(newValue);
                combobox.openDropdown();
                combobox.updateSelectedOptionIndex();
              }}
              placeholder={placeholder || t('SearchArea.RegionSelect.Placeholder')}
              rightSectionPointerEvents='none'
            />
          </Combobox.Target>

          <Combobox.Dropdown className={classes.options}>
            <Combobox.Options>
              <ConditionalComponent
                condition={names.length > 0}
                fallback={<Combobox.Empty>Nothing found</Combobox.Empty>}
              >
                <>
                  {names.map((item) => (
                    <Combobox.Option value={item.value} key={item.value}>
                      <Flex direction='column' gap='4px'>
                        <Text size='sm'>{item.label}</Text>
                        <Text size='xs' c='dimmed'>
                          {item.description}
                        </Text>
                      </Flex>
                    </Combobox.Option>
                  ))}
                </>
              </ConditionalComponent>
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      )}
    />
  );
}

export default RegionSelectForm;
