export type searchAreaSortsOptions = {
  value: string;
  label: string;
  sort_by: string;
  direction: number;
};

export const searchAreaSortsOptions: searchAreaSortsOptions[] = [
  {
    direction: 1,
    sort_by: 'price',
    label: 'от дешевых  к дорогим',
    value: 'toMax'
  },
  {
    direction: -1,
    sort_by: 'price',
    label: 'от дорогих к дешевым',
    value: 'toMin'
  },
  {
    direction: 1,
    sort_by: 'rating',
    label: 'по популярности',
    value: 'byRating'
  }
];
