import React from 'react';
import { Box, Text } from '@mantine/core';
import { getImage } from 'utils/getImage';

interface TreatmentProps {
  treatment: {
    image: {
      encoded_url: string;
    };
    title: string;
  };
  opened: boolean;
  onClick: (treatment: any) => void;
}

function Treatment({ treatment, opened, onClick }: TreatmentProps) {
  const image = getImage(treatment.image.encoded_url, { width: 588, height: 480 });

  return (
    <Box onClick={() => onClick(treatment)}>
      <Box
        style={{
          width: 294,
          userSelect: 'none',
          cursor: 'pointer',
          position: 'relative',
          height: 240,
          borderRadius: 4,
          backgroundImage: `url(${image.defaultSrc})`,
          backgroundSize: 'cover',
          padding: '22px 24px',
          '&::before': {
            content: opened ? '"Скрыть"' : '"Подробнее"',
            height: 'min-content',
            fontSize: 16,
            lineHeight: '20px',
            color: '#3582db',
            position: 'absolute',
            bottom: 19,
            left: 24
          }
        }}
      >
        <Text fz='18px' fw={600} dangerouslySetInnerHTML={{ __html: treatment.title }} />
      </Box>
    </Box>
  );
}

export default Treatment;
