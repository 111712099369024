export enum ContentType {
  Content = 'content',
  Object = 'object'
}

interface Contract {
  commission_in_percent: number;
  from_date: string;
  number: string;
}

interface Source {
  insurance_id: string;
  contracts: Contract[];
}

interface LegalEntity {
  inn: string;
  kpp: string;
}

interface SanatoryFormData {
  provider: string;
  timezone: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  location: string;
  legal_entity: LegalEntity;
  place_id: string;
  sources: Source[];
}

export interface SanatoryFormProps {
  id: string;
  provider: string;
  email: string;
  phone: string;
  provider_id: string;
  place_name: string;
  location: string;
  place_id: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  timezone: string;
  name: string;
  currency: string;
  legal_entity: LegalEntity;
  sources: Source[];
  address: {
    location: string;
    coordinates: {
      lat: number;
      lng: number;
    };
    country: string;
    city: string;
  };
  discount: number;
  instant_booking: boolean;
  s;
  cashback: boolean;
  external_provider: {
    tripadvisor_url: string;
  };
  videos: {
    url: string;
    youtube_id: string;
  }[];
}
