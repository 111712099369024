import { createTheme } from '@mantine/core';

const theme = createTheme({
  fontFamily: 'Roboto, sans-serif',
  colors: {
    green: [
      '#52ab18',
      '#52ab18',
      '#52ab18',
      '#52ab18',
      '#52ab18',
      '#52ab18',
      '#52ab18',
      '#52ab18',
      '#52ab18',
      '#52ab18'
    ]
  }
});

export default theme;
