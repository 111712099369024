import React from 'react';
import { IconDots } from '@tabler/icons-react';
import { Paper, Image, Text, Button, Flex, ActionIcon, Popover } from '@mantine/core';
import classes from '../../../../../components/ui/Card/Popover/Popover.module.css';
import type { AuthorResponse } from '../../../../../types/authors';

type Props = {
  reviewer: AuthorResponse;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onEditAdditional: (id: string) => void;
};

function ReviewerCard({ reviewer, onEdit, onDelete, onEditAdditional }: Props) {
  const { avatar, full_name, specializations } = reviewer;

  const specializationsLabels = specializations.reduce((accum, item, index) => {
    accum += item.name;

    if (specializations.length !== index + 1) {
      accum += ', ';
    }

    return accum;
  }, '');

  return (
    <Paper p='xs' radius='md' w='300px'>
      <Image h='154px' src={avatar?.url} />
      <Flex direction='column' mt='8px'>
        <Text fw='500'>{full_name}</Text>
        <Text c='var(--mantine-color-gray-5)'>{specializationsLabels}</Text>

        <Flex align='center' gap='4px' mt='8px' justify='flex-end'>
          <Button color='gray' onClick={() => onEdit(reviewer.id)} size='xs'>
            Редактировать
          </Button>

          <Popover position='bottom' withArrow shadow='md'>
            <Popover.Target>
              <ActionIcon color='gray'>
                <IconDots
                  style={{
                    width: '70%',
                    height: '70%'
                  }}
                />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown style={{ zIndex: 149 }} px='0' py='xs'>
              <Flex direction='column' gap='4px'>
                <Text className={classes.popover_item} onClick={() => onDelete(reviewer.id)} px='md' py='4px' fz='sm'>
                  Удалить
                </Text>
                <Text
                  className={classes.popover_item}
                  onClick={() => onEditAdditional(reviewer.id)}
                  px='md'
                  py='4px'
                  fz='sm'
                >
                  Изменить категорию
                </Text>
              </Flex>
            </Popover.Dropdown>
          </Popover>
        </Flex>
      </Flex>
    </Paper>
  );
}

export default ReviewerCard;
