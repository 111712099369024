import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Modal } from '@mantine/core';

import { useAppDispatch, useAppSelector } from 'store';
import { articleSelector } from 'store/articlesSlice/selectors';
import { fetchOneArticlesAction, updateArticleFormAction } from 'store/articlesSlice/actions';
import Form from 'components/ui/Form';

import AddDiseaseForm, { validationSchema } from 'pages/Diseases/components/AddDiseaseForm';
import { getFormattedFormData } from '../../utils';

import type { DiseaseFormFields } from 'types';

function EditForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, article] = useAppSelector(articleSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchOneArticlesAction(id));
  }, []);

  const handleSubmit = async (formData: DiseaseFormFields) => {
    const formattedData = getFormattedFormData(formData);

    dispatch(
      updateArticleFormAction({
        article: formattedData,
        id
      })
    );

    navigate('/diseases');
  };

  const handleReset = () => {
    navigate('/diseases');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  if (!article) {
    return null;
  }

  return (
    <Modal
      size='lg'
      title={<Text fz='24px'>{t('Disease.Form.Edit')}</Text>}
      centered
      onClose={handleReset}
      withCloseButton={false}
      opened
    >
      <Form
        schema={validationSchema()}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <AddDiseaseForm article={article} />
      </Form>
    </Modal>
  );
}

export default EditForm;
