import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { IUserResponse } from 'types';

const LOGIN_PATH = '/login';

export declare type PageProps = React.PropsWithChildren<{
  className?: string;
  isAuthenticated: boolean;
  currentUser: IUserResponse;
}>;

type Props = {
  children: (props: PageProps) => JSX.Element;
  isAuthenticated?: boolean;
  currentUser?: IUserResponse;
};

function PrivateRoute({ isAuthenticated = false, currentUser, children }: Props) {
  if (!isAuthenticated || !currentUser) {
    return <Navigate to={LOGIN_PATH} replace />;
  }

  return children({ isAuthenticated, currentUser });
}

export default PrivateRoute;
