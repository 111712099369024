import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesFetch } from 'apis/ArticlesApi';

const ACTION = 'articles/fetch';

const fetchArticles = createAsyncThunk(
  ACTION,
  articlesFetch
);

export default fetchArticles;
