import { toast } from 'react-toastify';

type ToastType = 'info' | 'error' | 'success';

const getToastColor = (type: ToastType) => {
  switch (type) {
    case 'error':
      return 'var(--mantine-color-red-6)'; // red
    case 'success':
      return 'var(--mantine-color-green-6)'; // green
    case 'info':
      return 'var(--mantine-color-blue-6)'; // blue
    default:
      return 'var(--mantine-color-gray-6)'; // gray
  }
};

export const toastify = (type: ToastType, text: string) => {
  toast[type](text, {
    position: 'top-left',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: {
      background: getToastColor(type),
      padding: '1rem 1.4rem',
      borderRadius: '4px',
      fontSize: '1.125rem',
      lineHeight: 1.4,
      maxWidth: '642px',
      width: '100%'
    }
  });
};
