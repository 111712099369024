import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Outlet, useParams } from 'react-router-dom';
import { Text, Breadcrumbs, Anchor, Box, Button, Divider, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import getCancellationRules from 'utils/getCancellationRules';
import InfoBlock from './InfoBlock';
import Rates from './Rates';
import Periods from './Periods';
import RoomTypes from './RoomTypes';
import { Main, TopBar, Content } from 'components/ui/Layout';

import { createParsingTariffAction, fetchParsingTariffsAction } from 'store/parsingSlice/actions';
import { parsingTariffsAllSelector } from 'store/parsingSlice/selectors';
import { STATUSES } from 'types';
import Loader from 'components/ui/Loader';
import parsingRoomTypesFetchAction from 'store/parsingSlice/actions/parsingRoomTypesFetchAction';
import { parsingRoomTypesSelector } from 'store/parsingSlice/selectors/roomTypes';

function Tariff() {
  const { t } = useTranslation();
  const [roomType, setRoomType] = useState<any>(null);

  const types: any = {
    base: t('Tariffs.Base'),
    tour: t('Tariffs.Tour'),
    package: t('Tariffs.Package')
  };

  const { id, tariffId } = useParams();

  const dispatch = useAppDispatch();
  const [state, data, tariffState]: [STATUSES, any, STATUSES?] = useAppSelector(parsingTariffsAllSelector);
  const [_, roomTypes]: [STATUSES, any] = useAppSelector(parsingRoomTypesSelector);

  useEffect(() => {
    id && dispatch(fetchParsingTariffsAction(id));
  }, [id]);

  useEffect(() => {
    data && dispatch(parsingRoomTypesFetchAction(data.place.place_id));
  }, [data]);

  const handleCreate = () => {
    dispatch(
      createParsingTariffAction({
        id: data.place.place_id.replace('hotel/', ''),
        tariff: { ...tariff, room_type: roomType }
      })
    );
  };

  if (state !== STATUSES.FULFILLED) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  const tariff = data.tariffs.find((tr: any) => tr._id === tariffId);

  const date: string = [
    dayjs(tariff.available_period.from).format('DD.MM.YYYY'),
    dayjs(tariff.available_period.to).format('DD.MM.YYYY')
  ].join(' - ');

  return (
    <Main>
      <TopBar>
        <Breadcrumbs separator={<Text fz={25}>/</Text>}>
          <Anchor href='/parsing/'>{t('Parsing.Title')}</Anchor>

          <Anchor href={`/parsing/${id}/`}>{t('Tariffs.Title')}</Anchor>

          <Text>{tariff.name_translations.ru}</Text>
        </Breadcrumbs>
      </TopBar>

      <Content>
        <Box bg='white' p='lg'>
          <Text fw={500} fz={18} c='var(--mantine-color-blue-5)'>
            {tariff.name_translations.ru}
          </Text>

          {tariff.available_period && (
            <Text fz={18} c='var(--mantine-color-gray-5)'>
              {date}
            </Text>
          )}

          <Divider my='md' />

          <Flex direction='column' gap='lg'>
            <RoomTypes roomTypes={roomTypes} onSelect={setRoomType} roomType={roomType} />
            <InfoBlock
              label={t('Tariffs.RoomTypes.TL.Title')}
              description={tariff.room_type_object.name_translations.ru}
            />

            <InfoBlock label={t('Tariffs.Status.Label')} description={t('Tariffs.Status.New')} />

            <InfoBlock label={t('Tariffs.Type.Label')} description={types[tariff.type]} />

            <InfoBlock label={t('Common.Description')} description={tariff.description_translations.ru} />

            <InfoBlock label={t('Tariffs.AvailablePeriod.Label')} description={date} />

            <InfoBlock label={t('Tariffs.Cancellation.Label')} description={getCancellationRules(tariff)} />

            <Rates rates={tariff.rates} />

            <Periods periods={tariff.periods} />
          </Flex>

          <Flex w='100%' justify='flex-end' mt='lg'>
            <Button
              color='green'
              onClick={handleCreate}
              loading={tariffState === STATUSES.PENDING}
              disabled={!roomType}
            >
              {t('Action.Save')}
            </Button>
          </Flex>
        </Box>
      </Content>

      <Outlet />
    </Main>
  );
}

export default Tariff;
