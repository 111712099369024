import { createSlice } from '@reduxjs/toolkit';

import articlesAdapter, { initialState } from './adapter';

import {
  createParsingAction,
  createParsingTariffAction,
  fetchParsingAction, fetchParsingTariffsAction
} from './actions';
import setParsingTariffAction from './actions/setParsingTariffAction';
import parseAsyncAction from './actions/parseAsyncAction';
import parsingRoomTypesFetchAction from './actions/parsingRoomTypesFetchAction';

const topicsSlice = createSlice({
  name: 'parsing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(fetchParsingAction.pending, articlesAdapter.objectsOneFetchPending)
      .addCase(fetchParsingAction.fulfilled, articlesAdapter.parsingFetchFulfilled)
      .addCase(fetchParsingTariffsAction.fulfilled, articlesAdapter.parsingTariffsFetchFulfilled)
      .addCase(fetchParsingTariffsAction.pending, articlesAdapter.parsingTariffsFetchPending)
      .addCase(createParsingAction.fulfilled, articlesAdapter.createParsingFullfilled)
      .addCase(setParsingTariffAction, articlesAdapter.setTariff)
      .addCase(parseAsyncAction.fulfilled, articlesAdapter.handleParseAsync)
      .addCase(parseAsyncAction.rejected, articlesAdapter.handleParseAsyncReject)
      .addCase(parsingRoomTypesFetchAction.fulfilled, articlesAdapter.roomTypesFetchFullfilled)
      .addCase(createParsingTariffAction.pending, articlesAdapter.createTariffParsingPending)
      .addCase(createParsingTariffAction.fulfilled, articlesAdapter.createTariffParsingFullfilled)
  }
});

export default topicsSlice.reducer;
