import React from 'react';
import { Text } from '@mantine/core';

type Props = {
  data : any
}
function Paragraph({ data, ...rest }:Props) {
  return <Text fz='20px' lh='34px' mt='24px' {...rest} dangerouslySetInnerHTML={{ __html: data.text }} />;
}

export default Paragraph;
