import React from 'react';
import { Text, Anchor, Container, Breadcrumbs, Flex } from '@mantine/core';
import { createSearchParams, Link } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { extendDefaultValues, validationSchema } from './schema';
import Form from 'components/ui/Form';
import { searchAreaSortsOptions } from '../../../../constants';
import { ISearchArea, ISearchAreaBreadcrumbs, ISearchAreaRequestParams } from 'types';

import { updateSearchArea } from 'store/searchAreaSlice/actions';
import FormComponent from './FormComponent';

type Props = React.PropsWithChildren<{
  searchArea: ISearchArea;
  onClose: () => void;
  breadcrumbs: ISearchAreaBreadcrumbs[];
}>;

const mainBreadcrumb: ISearchAreaBreadcrumbs = {
  name: 'Главная',
  id: 12001
};

function SearchAreaForm({ searchArea, onClose, breadcrumbs }: Props) {
  const dispatch = useAppDispatch();
  const handleSubmit = async (data: ISearchAreaRequestParams) => {
    const { direction, sort_by } = searchAreaSortsOptions.find((sort) => sort.value === data.sort_basis);

    await dispatch(
      updateSearchArea({
        id: searchArea.id,
        data: {
          ...data,
          sort_basis: {
            direction,
            sort_by
          }
        }
      })
    );

    onClose();
  };

  const handleReset = () => {
    onClose();
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  const buildSearchPath = (id?: number) => {
    if (!id) return '';

    return createSearchParams({
      osm_id: String(id)
    }).toString();
  };

  const items = [mainBreadcrumb, ...breadcrumbs].map((item, index) => {
    if (index !== breadcrumbs.length) {
      return (
        <Anchor
          component={Link}
          to={{
            search: buildSearchPath(item.osm_id),
            pathname: '/search_area'
          }}
          key={index}
        >
          {item.name}
        </Anchor>
      );
    }

    return (
      <Anchor key={index} variant='text'>
        {item.name}
      </Anchor>
    );
  });

  return (
    <Container>
      <Text size='xl' w={500}>
        {searchArea?.name.ru}
      </Text>

      <Flex my='sm'>
        <Breadcrumbs>{items}</Breadcrumbs>
      </Flex>

      <Form
        schema={validationSchema()}
        defaultValues={extendDefaultValues({})}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <FormComponent searchArea={searchArea} />
      </Form>
    </Container>
  );
}

export default SearchAreaForm;
