import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getImage } from 'utils/getImage';

import RightContainer from './RightContainer';
import useHotelPrice from 'hooks/useHotelPrice';

function HotelCardSmall({ data: { hotel } }) {
  const [{ price, isPending }, { fetch }] = useHotelPrice(hotel);

  if (!hotel.id) return;

  useEffect(() => {
    !isPending && !price && fetch();
  }, [isPending, price]);

  const img = getImage(hotel.image.encoded_url, { width: 600, height: 420 });

  return (

    <div className='hotelCard_wrapper'>
      <img className='hotelCard_image' src={img.defaultSrc} />
      <RightContainer hotel={hotel} price={price} isPending={isPending} />
    </div>

  );
}

HotelCardSmall.propTypes = {
  data: PropTypes.object
};

export default HotelCardSmall;
