import { createAsyncThunk } from '@reduxjs/toolkit';

import { parsingTariffCreate } from 'apis/ParsingApi';

const ACTION = 'parsing/createTariff';

const createParsingTariffAction = createAsyncThunk(
  ACTION,
  parsingTariffCreate
);

export default createParsingTariffAction;
