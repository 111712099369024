const documentsPlugin = {
  translations: {
    en: {
      documents: {
        documents: 'Documents',
        save: 'Save',
        cancel: 'Cancel',
        insert: 'Insert'
      }
    }
  },
  defaults: {
    upload: false,
    select: false,
    name: 'file',
    data: false,
    multiple: true
  },
  popups: {
    add: {
      title: '## documents.documents ##',
      width: '100%',
      footer: {
        insert: { title: '## documents.insert ##', command: 'documents.insert', type: 'primary' },
        cancel: { title: '## documents.cancel ##', command: 'popup.close' }
      }
    },
    edit: {
      title: '## documents.documents ##',
      width: '100%',
      footer: {
        save: { title: '## documents.save ##', command: 'documents.save', type: 'primary' },
        cancel: { title: '## documents.cancel ##', command: 'popup.close' }
      }
    }
  },
  start: function () {
    this.app.addbar.add('documents', {
      title: '## documents.documents ##',
      icon: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m1 6c.51283584 0 .93550716.38604019.99327227.88337887l.00672773.11662113v6c0 .4964612.44481521.9373059 1.049825.9938787l.13199318.0061213h5.81818182c.55228475 0 1 .4477153 1 1 0 .5128358-.38604019.9355072-.88337887.9932723l-.11662113.0067277h-5.81818182c-1.67882337 0-3.08194674-1.2331302-3.17671765-2.8277989l-.00510053-.1722011v-6c0-.55228475.44771525-1 1-1zm12.6-6c1.3254834 0 2.4 1.0745166 2.4 2.4v8.2c0 .0812991-.0040424.161654-.0119371.2408747.04537.286293-.0350323.5849432-.229131.8106316-.3901386.7980655-1.2102555 1.3484937-2.1589319 1.3484937h-8.2c-.0093721 0-.01873166-.0000537-.02807838-.0001609l-.37192162.0001604c-.26521649 0-.5195704-.1053563-.70710678-.2928927l.05843073.052268c-.4838201-.2353989-.8766931-.6286113-1.11166129-1.1126794-.19291421-.2239005-.27189946-.5180346-.2277519-.8010756-.00769603-.0805757-.01191076-.1626056-.01191076-.2456198v-8.2c0-1.3254834 1.0745166-2.4 2.4-2.4zm-1.596 8.632-.76.937.839 1.431h1.517c.0486011 0 .0951777-.0086678.1382683-.0245418zm-4.582-1.631-2.176 3.83.169.169h4.349l-.436-.744-.04995732-.0453903c-.12825052-.1334488-.2131256-.2958557-.25256531-.46760617zm6.178-5.001h-8.2c-.2209139 0-.4.1790861-.4.4v4.814l1.53963435-2.70800941c.35805102-.6301698 1.23187816-.67285287 1.65793101-.12170559l.07440231.11030062 1.89203233 3.23141438 1.0987033-1.35538405c.384092-.47423473 1.0895396-.491008 1.4981306-.06381668l.0827862.09833145 1.1563799 1.56386928v-5.569c0-.19329966-.1371128-.35457492-.319386-.39187342z"/></svg>',
      command: 'documents.popup'
    });
  },
  popup: function () {
    // create
    const stack = this.app.popup.add('slideshow', this.popups.add);

    // body
    const $body = stack.getBody();

    // box
    this.$box = this.dom('<div>').addClass(this.prefix + '-slideshow-items');
    $body.append(this.$box);

    // upload
    this.$upload = this.app.image.createUploadBox(this.opts.documents.upload, $body);

    // select box
    this.app.image.createSelectBox(this.opts.documents.select, $body, 'documents.insertFromSelect');

    // open
    stack.open();

    // build upload
    this._buildUpload(this.$upload, 'documents.insertByUpload');
  },
  edit: function (params, button) {
    // create
    const stack = this.app.popup.create('slideshow', this.popups.edit);

    // body
    const $body = stack.getBody();

    // data
    const current = this.app.block.get();
    const $block = current.getBlock();
    const $elms = $block.find('img');

    // box
    this.$box = this.dom('<div>').addClass(this.prefix + '-slideshow-items');
    $body.append(this.$box);

    // items
    $elms.each(function ($node) {
      this._buildBoxItem($node.attr('src'));
    }.bind(this));

    // upload
    this.$upload = this.app.image.createUploadBox(this.opts.documents.upload, $body);

    // select box
    this.app.image.createSelectBox(this.opts.documents.select, $body, 'documents.insertFromSelect');

    // open
    this.app.popup.open({ button: button });

    // build upload
    this._buildUpload(this.$upload, 'documents.insertByUpload');
  },
  insertByUpload: function (response) {
    for (const key in response) {
      this._buildBoxItem(response[key].url);
    }
  },
  insertFromSelect: function (e) {
    e.preventDefault();

    const $target = this.dom(e.target);
    this._buildBoxItem($target.attr('data-url'));
  },
  insert: function () {
    // instance
    const instance = this.app.create('block.documents');
    this.app.block.add({ instance: instance });

    // save
    this.save();
  },
  save: function () {
    const current = this.app.block.get();
    const $elms = this.$box.find('img');

    if ($elms.length === 0) {
      current.remove();
      this.app.popup.close();
      return;
    }

    const $block = current.getBlock();
    const $inner = $block.find('.documents-inner');

    // indicators
    // const blockId = current.getId();

    // clear
    $inner.html('');

    $elms.each(function ($node, i) {
      const src = $node.attr('src');

      const $item = this.dom('<div>').addClass('documents-item');
      const $img = this.dom('<img>').attr('src', src);
      const $controlsCont = this.dom('<div>').addClass('documents-item-controls-cont');
      const $zoomButton = this.dom('<div>').addClass('documents-item-control');

      const zoomIcon = `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3203 7.82031H9.39453V5.89453C9.39453 5.45312 9.03906 5.09766 8.59766 5.09766C8.15625 5.09766 7.80078 5.45312 7.80078 5.89453V7.82031H5.875C5.43359 7.82031 5.07812 8.17578 5.07812 8.61719C5.07812 9.05859 5.43359 9.41406 5.875 9.41406H7.80078V11.3398C7.80078 11.7812 8.15625 12.1367 8.59766 12.1367C9.03906 12.1367 9.39453 11.7812 9.39453 11.3398V9.41406H11.3203C11.7617 9.41406 12.1172 9.05859 12.1172 8.61719C12.1172 8.17578 11.7578 7.82031 11.3203 7.82031Z" fill="white"/>
          <path d="M8.60156 15.1836C4.97266 15.1836 2.03125 12.2461 2.03125 8.61719C2.03125 4.98828 4.97266 2.05078 8.60156 2.05078C12.2305 2.05078 15.168 4.99219 15.168 8.61719C15.168 12.2422 12.2266 15.1836 8.60156 15.1836ZM19.3359 18.2305L14.9102 13.8047C16.0703 12.3945 16.7695 10.5859 16.7695 8.61719C16.7695 4.10547 13.1133 0.445312 8.59766 0.445312C4.08203 0.445312 0.429688 4.10547 0.429688 8.61719C0.429688 13.1289 4.08594 16.7891 8.60156 16.7891C10.5703 16.7891 12.375 16.0938 13.7852 14.9336L18.2109 19.3594C18.4141 19.5664 19.0352 19.668 19.3398 19.3594C19.6484 19.0469 19.6484 18.543 19.3359 18.2305Z" fill="white"/>
        </svg>
      `;

      $zoomButton.append(zoomIcon);
      $controlsCont.append($zoomButton);
      $item.append($img);
      $item.append($controlsCont);
      $inner.append($item);
    }.bind(this));

    $inner.find('.documents-item').first().addClass('active');

    // close popup
    this.app.popup.close();
  },

  // private
  _removeItem: function (e) {
    const $target = this.dom(e.target).closest('.' + this.prefix + '-slideshow-item');

    $target.fadeOut(function ($node) {
      $node.remove();
    });
    // $target.fadeOut(function ($node) {
    //   $node.remove();
    // }.bind(this));
  },
  _buildBoxItem: function (src) {
    const $item = this.dom('<span>').addClass(this.prefix + '-slideshow-item');
    const $remover = this.dom('<span>').addClass(this.prefix + '-upload-remove');
    const $img = this.dom('<img>').attr('src', src);

    $remover.one('click', this._removeItem.bind(this));

    $item.append($img);
    $item.append($remover);
    this.$box.append($item);
  },
  _buildUpload: function ($item, callback) {
    if (!this.opts.documents.upload) return;

    const params = {
      box: true,
      placeholder: this.lang.get('image.upload-new-placeholder'),
      url: this.opts.documents.upload,
      name: this.opts.documents.name,
      data: this.opts.documents.data,
      multiple: this.opts.documents.multiple,
      success: callback,
      error: 'image.error'
    };

    this.app.create('upload', $item, params);
  }
};

export default documentsPlugin;
