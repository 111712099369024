import { getImage } from 'utils/getImage';
import pasteEvent from '../utils/pasteEvent';

export default class Ui {
  constructor({ title, items, onAdd, onTemplate, onRemove, onSelectFile, onDrag }) {
    this.title = title;
    this.onDrag = onDrag;
    this.onTemplate = onTemplate;
    this.items = items;
    this.onAdd = onAdd;
    this.onRemove = onRemove;
    this.onSelectFile = onSelectFile;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      listElement: make('div', [this.CSS.listElement]),
      title: make('h2', this.CSS.title, {
        contentEditable: true,
        innerHTML: this.title
      }),
      listContainer: make('div', this.CSS.listContainer),
      button: this.createAddButton()
    };

    this.nodes.title.addEventListener('paste', pasteEvent);

    this.templates = [
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvZDIxY2E1N2Y5/ZjFhMWJmMDY4YWYy/YmQ3OThlZDY4OTUu/cG5n'
        },
        title: 'Климат'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvMTFiNWMzYTRh/NWEwYzA2OTc3MTMx/ZjRjNGE0ODA4MzQu/cG5n'
        },
        title: 'Минеральные воды'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvNGRmMTliM2Mw/MDk0MDFhMjdiYzQw/ZjFmMDY1MjkxYzAu/cG5n'
        },
        title: 'Лечебные грязи'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvNDJkODk5/MGNmNjA1OTRkZTA0/YTgwZTMyOGE2ZTg2/MWEucG5n'
        },
        title: 'Море'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvNDJkODk5/MGNmNjA1OTRkZTA0/YTgwZTMyOGE2ZTg2/MWEucG5n'
        },
        title: 'Океан'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvZmU0OTRh/NGNhZDk1NmY0NzA4/MDAwOTY3NDhiMTli/ZmQucG5n'
        },
        title: 'Штольни'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvZjVlNWRk/NmVkZGM0NDE5MDE5/YWM0ZjRhYTI3ZGQw/OGYucG5n'
        },
        title: 'Термальная вода'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvMmVlYjEx/OGM4ZmIwMzZjNjE5/NjE1YjgwODE1YmQz/MWYucG5n'
        },
        title: 'Спелеотерапия'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvYThlODVl/MTVjNjUxYmUyMTY4/MjkyNmYyNjI3Zjgy/ZDMucG5n'
        },
        title: 'Морская вода'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvYjZlNTY1/ZWNmYjhkYmFlODVk/MmFkZWVmNTg5MDQ2/ZGYucG5n'
        },
        title: 'Пиранские соли'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvOWM1NzY2/YjRkNDVlZjUzOGM0/MjI1NjliNGYzOWUw/NDgucG5n'
        },
        title: 'Аюверда'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvOWJiMDE1/MDIzOTI1MjMzZGE3/NTdmMGQzYjhjMmY3/Y2MucG5n'
        },
        title: 'Гелиотерапия'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvYzgyNjFh/ZjYzYTZlNmZmOTc5/YzJjOGY0YTJmMWRk/OWUucG5n'
        },
        title: 'Карстовые пещеры'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvMjBhMjc2/YWNmYmY1MzY1Yjhk/MWNmYTUwNmI5Yzg0/ODMucG5n'
        },
        title: 'Минеральные источники'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvMjk5NTk4/ODU2OTkzNmI4ODA4/YTcxNGRlZWNkODkz/YzMucG5n'
        },
        title: 'Соли'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvZmRkZTVl/NmM0NjRkYzcwNTQ4/ZDhjZGVlNjVkODYz/YTEucG5n'
        },
        title: 'Шунгит'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvMjgwYTNj/MzgyZjRkZDhhYjMw/ODg0YWZmMGE3MGMw/MmYucG5n'
        },
        title: 'Пантовое сырье'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvNjMwZjVk/NWZlY2I2YzY2NzI5/YjlkMzhkYTZlMGNh/MTcucG5n'
        },
        title: 'Рапа'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvYjI3ZDE1/NzIxZGIxM2Q2ZDA3/MDM0Yjk2ZDVhN2Y4/MzYucG5n'
        },
        title: 'Кумыс'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvY2M1M2Ri/MjVmZWFkM2RhNDEz/MWUyY2EyZjFkY2Fk/YmEucG5n'
        },
        title: 'Геотермальные пары и газы горы Янтантау'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvZDU0MWI3/ZjMxNGU2NDQ3NGYw/MTlkZGMyNDhjZTUy/ODUucG5n'
        },
        title: 'Нафталан'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvOTI0MmIy/NzU4MTM5ODFjNGM5/MzljYWJjMmY5ZDY0/MTgucG5n'
        },
        title: 'Мофеты'
      },
      {
        image: {
          encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL2Fy/dGljbGUvNjM3ZTBm/NzdjYTMwNzYwMDAx/YmYyOTc3L2F0dGFj/aG1lbnQvYzc5OGI0/YTQ5ZjQ1MmRiNTZh/MTcwOTViMDZmYzkz/MjYucG5n'
        },
        title: 'Нафталанская нефть'
      }
    ];
  }

  createAddButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = 'Добавить';

    button.addEventListener('click', () => {
      this.onAdd();
    });

    return button;
  }

  get CSS() {
    return {
      wrapper: 'treatments',
      img: 'treatments_img',
      templateHint: 'treatments_template_hint',
      topContainer: 'treatments_top',
      templateContainer: 'treatments_templates',
      template: 'treatments_template',
      rightContainer: 'treatments_right',
      templateImg: 'treatments_template_img',
      templateText: 'treatments_template_text',
      listElement: 'treatments_element',
      title: 'treatments_title',
      listContainer: 'treatments_list',
      remove: 'treatments_settings_remove',
      button: 'treatments_add',
      removeIcon: 'treatments_element_remove',
      listText: 'treatments_element_text',
      listTitle: 'treatments_element_title'
    };
  }

  getData() {
    const title = this.nodes.title.innerHTML;
    const items = this.nodes.wrapper.getElementsByClassName(this.CSS.listElement);

    return {
      items: Array.from(items).map((el, index) => {
        const title = el.querySelector(`.${this.CSS.listTitle}`);
        const description = el.querySelector(`.${this.CSS.listText}`);

        return {
          title: title.innerHTML,
          description: description.innerHTML,
          image: this.items[index].image
        };
      }),
      title
    };
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  };

  displayErrorItem = (flag, index) => {
    const item = this.getItem(index);

    flag ? item.classList.add('error') : item.classList.remove('error');

    return item;
  };

  displayErrorItemTitle = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listTitle);
  };

  displayErrorItemDescription = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listText);
  };

  displayErrorItemImage = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.img);
  };

  displayItemElementError = (flag, index, cssClass) => {
    const item = this.getItem(index);
    const element = item.querySelector(`.${cssClass}`);

    flag ? element.classList.add('error') : element.classList.remove('error');

    return element;
  };

  getItem = (index) => {
    return this.nodes.wrapper.getElementsByClassName(this.CSS.listElement)[index];
  };

  renderList() {
    return this.items.map((element, index) => {
      const img = getImage(element.image.encoded_url, { width: 300, height: 300 });

      const listElement = make('div', this.CSS.listElement);
      const imgElement = make('img', this.CSS.img, {
        src: img.defaultSrc,
        key: img.defaultSrc
      });

      imgElement.onerror = function() {
        this.src = 'https://cdn.vivas.vn/wp-content/themes/unbound/images/No-Image-Found-400x264.png';
        this.onerror = null;
      };

      const topContainer = make('div', this.CSS.topContainer);
      const rightContainer = make('div', this.CSS.rightContainer);
      const templateContainer = make('div', this.CSS.templateContainer);

      const templateHint = make('div', this.CSS.templateHint, {
        innerHTML: 'Выберите из шаблона'
      });

      imgElement.addEventListener('drop', (event) => {
        this.onDrag(event, index);
      });

      const listText = make('div', this.CSS.listText, {
        contentEditable: true,
        innerHTML: element.description
      });

      const listTitle = make('h3', this.CSS.listTitle, {
        contentEditable: true,
        innerHTML: element.title
      });

      listTitle.addEventListener('paste', pasteEvent);
      listText.addEventListener('paste', pasteEvent);

      const removeIcon = make('div', this.CSS.removeIcon);

      removeIcon.addEventListener('click', () => {
        this.onRemove(index);
      });

      imgElement.addEventListener('click', () => {
        this.onSelectFile(index);
      });

      const tmps = this.renderTemplates(index);

      tmps.forEach(el => templateContainer.appendChild(el));

      rightContainer.appendChild(templateHint);
      rightContainer.appendChild(templateContainer);

      topContainer.appendChild(imgElement);
      topContainer.appendChild(rightContainer);

      listElement.appendChild(topContainer);
      listElement.appendChild(listTitle);
      listElement.appendChild(listText);
      listElement.appendChild(removeIcon);

      return listElement;
    });
  }

  renderTemplates(index) {
    return this.templates.map(el => {
      const template = make('div', this.CSS.template);
      const templateImg = make('img', this.CSS.templateImg, {
        src: getImage(el.image.encoded_url, { width: 300, height: 300 }).defaultSrc
      });

      templateImg.onerror = function() {
        this.src = 'https://cdn.vivas.vn/wp-content/themes/unbound/images/No-Image-Found-400x264.png'; 
        this.onerror = null;
      };

      const templateText = make('div', this.CSS.templateText, {
        innerHTML: el.title
      });

      template.appendChild(templateImg);
      template.appendChild(templateText);

      template.addEventListener('click', () => {
        this.onTemplate(el, index);
      });

      return template;
    });
  }

  render() {
    const list = this.renderList();

    this.clear(this.nodes.listContainer);

    list.forEach((element) => {
      this.nodes.listContainer.appendChild(element);
    });

    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.listContainer);
    this.nodes.wrapper.appendChild(this.nodes.button);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  createFileButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = this.config.buttonContent || `${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
