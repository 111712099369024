import { useState } from 'react';
import { IRegionDocument } from 'types';
import hotelsApi from 'apis/HotelsApi';

function useHotelsRegions() {
  const [regions, setHotels] = useState<IRegionDocument[]>([]);

  const fetch = async (q:string):Promise<any> => {
    const data:any = await hotelsApi.fetchHotels(q);
    setHotels(data.data.map((h:any) => h.document));
  };

  const clear = () => {
    setHotels([]);
  };
  return [{ regions }, { fetch, clear }] as const;
}

export default useHotelsRegions;
