import { Button, Modal, Text, Flex, ActionIcon } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconCopy } from '@tabler/icons-react';

import { toastify } from 'utils/toastify';

type SuccessModalProps = {
  setOpenModal: (open: boolean) => void;
  openModal: boolean;
  modalData: {
    email: string;
    password: string;
  };
};

function SuccessModal({ setOpenModal, openModal, modalData }: SuccessModalProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModal(false);
    navigate('/objects');
  };

  const handleCopyLogin = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(modalData?.email);
    toastify('info', 'Логин скопирован в буфер');
  };

  const handleCopyPass = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(modalData?.password);
    toastify('info', 'Пароль скопирован в буфер');
  };

  return (
    <Modal
      withCloseButton={false}
      centered
      closeOnClickOutside={false}
      onClose={() => setOpenModal(false)}
      opened={openModal}
    >
      <Text fz={30}>{t('Objects.Modal.Title')}</Text>
      <Flex direction='column' gap='lg'>
        <Flex align='center' gap='sm'>
          <Text fz={14} c='var(--mantine-color-gray-6)'>
            {t('Objects.Modal.Login')}
          </Text>
          <Text fz={14}>{modalData?.email}</Text>
          <ActionIcon variant='transparent' onClick={handleCopyLogin}>
            <IconCopy color='var(--mantine-color-gray-3)' />
          </ActionIcon>
        </Flex>
        <Flex align='center' gap='sm'>
          <Text fz={14} c='var(--mantine-color-gray-6)'>
            {t('Objects.Modal.Pass')}
          </Text>
          <Text fz={14}>{modalData?.password}</Text>
          <ActionIcon variant='transparent' onClick={handleCopyPass}>
            <IconCopy color='var(--mantine-color-gray-3)' />
          </ActionIcon>
        </Flex>
      </Flex>
      <Button mt='36px' color='green' onClick={handleClose}>
        {t('Action.Understand')}
      </Button>
    </Modal>
  );
}

export default SuccessModal;
