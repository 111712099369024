import pasteEvent from '../utils/pasteEvent';

export default class Ui {
  constructor({ title, list, onAdd, onRemove, description }) {
    this.title = title;
    this.list = list;
    this.description = description;
    this.onAdd = onAdd;
    this.onRemove = onRemove;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      listElement: make('div', [this.CSS.listElement]),
      title: make('h2', this.CSS.title, {
        contentEditable: true,
        innerHTML: this.title
      }),
      listContainer: make('ul', this.CSS.listContainer),
      button: this.createAddButton(),
      description: this.description
        ? make('div', this.CSS.description, { innerHTML: this.description, contentEditable: true })
        : null
    };

    this.nodes.title.addEventListener('paste', pasteEvent);
  }

  createAddButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = 'Добавить';

    button.addEventListener('click', () => {
      this.onAdd();
    });

    return button;
  }

  get CSS() {
    return {
      wrapper: 'list',
      listElement: 'list_element',
      title: 'list_title',
      listContainer: 'list_list',
      remove: 'list_settings_remove',
      button: 'list_add',
      removeIcon: 'list_element_remove',
      listText: 'list_element_text',
      inputWrapper: 'list_input_wrapper',
      checkbox: 'list_checkbox',
      label: 'list_label',
      description: 'list_description'
    };
  }

  getData() {
    const list = this.nodes.wrapper.getElementsByClassName(this.CSS.listText);
    const titleElement = this.nodes.wrapper.querySelector(`.${this.CSS.title}`);
    const description = this.nodes.description;

    return {
      list: Array.from(list).map((el) => el.innerHTML),
      title: titleElement.innerHTML,
      description: description?.innerHTML
    };
  }

  renderList() {
    return this.list.map((element, index) => {
      const listElement = make('li', this.CSS.listElement);

      const listText = make('div', this.CSS.listText, {
        contentEditable: true,
        innerHTML: element
      });

      listText.addEventListener('paste', pasteEvent);

      const removeIcon = make('div', this.CSS.removeIcon);

      removeIcon.addEventListener('click', () => {
        this.onRemove(index);
      });

      listElement.appendChild(listText);
      listElement.appendChild(removeIcon);

      return listElement;
    });
  }

  render() {
    const list = this.renderList();

    this.clear(this.nodes.listContainer);

    list.forEach((element) => {
      this.nodes.listContainer.appendChild(element);
    });

    this.nodes.wrapper.appendChild(this.nodes.title);

    if (this.nodes.description) {
      this.nodes.wrapper.appendChild(this.nodes.description);
    }
    this.nodes.wrapper.appendChild(this.nodes.listContainer);
    this.nodes.wrapper.appendChild(this.nodes.button);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  handleCheckboxChange = (event) => {
    const checked = event.target.checked;

    if (checked) {
      this.nodes.description = make('div', this.CSS.description, {
        contentEditable: true
      });
    } else {
      this.nodes.description = null;
      const description = this.nodes.wrapper.querySelector(`.${this.CSS.description}`);
      description.remove();
    }

    this.render();
  }

  displayErrorItem = (flag, index) => {
    const item = this.nodes.wrapper.getElementsByClassName(this.CSS.listElement)[index];

    flag ? item.classList.add('error') : item.classList.remove('error');

    return item;
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  }

  renderSettings() {
    const wrapper = make('div', [this.CSS.settingsWrapper], {});
    const checkbox = make('input', 'checkbox', {
      innerHTML: 'test',
      type: 'checkbox',
      id: 'list_checkbox',
      checked: !!this.nodes.description
    });

    const inputWrapper = make('div', this.CSS.inputWrapper);
    const label = make('label', this.CSS.label, {
      innerHTML: 'Описание'
    });

    checkbox.addEventListener('change', this.handleCheckboxChange);

    label.setAttribute('for', 'list_checkbox');

    inputWrapper.appendChild(label);
    inputWrapper.appendChild(checkbox);

    wrapper.appendChild(inputWrapper);

    return wrapper;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
