import connection from 'connection/instance';

class DiseasesApi {
  async fetchList(type) {
    const params = {
      data: {
        filter: {
          type
        },
        pagination: {
          limit: 500,
          page: 1
        }
      }
    };

    try {
      const { data } = await connection.get('/api/articles', { params });

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async fetchOne(id) {
    try {
      const { data } = await connection.get(`/api/articles/${id}`);

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async create(attributes) {
    try {
      const { data } = await connection.post('/api/articles', { data: attributes });
      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async update(id, attributes) {
    try {
      const { data } = await connection.put(
        `/api/articles/${id}`, { data: attributes }
      );

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async delete(id) {
    try {
      const { data } = await connection.delete(`/api/articles/${id}`);

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async publish(id) {
    try {
      const { data } = await connection.put(`/api/articles/${id}/publish`);
      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async unpublish(id) {
    try {
      const { data } = await connection.put(`/api/articles/${id}/unpublish`);
      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}

export default new DiseasesApi();
