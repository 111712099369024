
export default class Ui {
  constructor({ about, about_en, t, onGenerate, prompt, onUpdate }) {
    this.about = about;
    this.about_en = about_en;
    this.prompt = prompt;
    this.t = t;
    this.onGenerate = onGenerate;
    this.onUpdate = onUpdate;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      description_container: make('div', this.CSS.description_container),
      buttons_container: make('div', this.CSS.buttons_container),
      title: make('div', this.CSS.title, {
        innerHTML: t('Description.Title.ChatGPT')
      }),
      prompt: make('textarea', this.CSS.about, {
        placeholder: t('Description.Prompt')
      }),
      button: this.createGenerateButton(this.onGenerate),
      button_upd: this.createUpdateButton(this.onUpdate),
      about: make('textarea', this.CSS.about, {
        placeholder: t('Description.About')
      }),
      about_en: make('textarea', this.CSS.about, {
        placeholder: 'ennnn'
      }),
      svg_loader: make('div', this.CSS.svg_loader),
      svg_container: make('svg', this.CSS.svg_container, {
        height: 100,
        width: 100,
        viewBox: '0 0 100 100'
      })
    };
    this.nodes.svg_container.appendChild(this.nodes.svg_loader);

    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.prompt);
    this.nodes.buttons_container.appendChild(this.nodes.button);
    this.nodes.buttons_container.appendChild(this.nodes.button_upd);
    this.nodes.wrapper.appendChild(this.nodes.buttons_container);
    this.nodes.description_container.appendChild(this.nodes.about);
    this.nodes.description_container.appendChild(this.nodes.about_en);
    this.nodes.wrapper.appendChild(this.nodes.description_container);
  }

  createGenerateButton(callback, params) {
    const button = make('button', this.CSS.button, {
      innerHTML: this.t('Common.Generate')
    });

    button.addEventListener('click', () => {
      callback(params);
    });

    return button;
  }

  createUpdateButton(callback, params) {
    const button = make('button', this.CSS.update_button, {
      innerHTML: this.t('Common.UpdateGPT'),
      disabled: true
    });

    button.addEventListener('click', () => {
      callback(params);
    });

    return button;
  }

  createPendingButton() {
    this.nodes.button.innerHTML = this.t('Common.Loading');
    this.nodes.button.disabled = true;
    this.nodes.button_upd.disabled = true;
    this.nodes.buttons_container.removeChild(this.nodes.button_upd);
    this.nodes.wrapper.appendChild(this.nodes.svg_container);
    this.nodes.wrapper.removeChild(this.nodes.description_container);
  }

  createFullfilledButton() {
    this.nodes.button.innerHTML = this.t('Common.Generate');
    this.nodes.button.disabled = false;
    this.nodes.button_upd.disabled = false;
    this.nodes.buttons_container.appendChild(this.nodes.button_upd);
    this.nodes.wrapper.removeChild(this.nodes.svg_container);
    this.nodes.wrapper.appendChild(this.nodes.description_container);
  }

  get CSS() {
    return {
      wrapper: 'description_gpt',
      title: 'description_title_gpt',
      about: 'description_about_gpt',
      prompt: 'description_about_gpt',
      button: 'generate_button_gpt',
      update_button: 'update_button_gpt',
      svg_loader: 'svg_loader_gpt',
      svg_container: 'svg_container_gpt',
      description_container: 'description_container_gpt',
      buttons_container: 'buttons_container_gpt'
    };
  }

  setInputFocus() {
    this.nodes.prompt.focus();
  }

  getData() {
    const about = this.nodes.about;
    const about_en = this.nodes.about_en;
    const prompt = this.nodes.prompt;
    this.prompt = prompt.value;
    this.about = about.value;
    this.about_en = about_en.value;

    return {
      prompt: prompt.value,
      about: about.value,
      about_en: about_en.value
    };
  }

  render() {
    this.nodes.prompt.value = this.prompt;
    this.nodes.about.value = this.about;
    this.nodes.about_en.value = this.about_en;

    return this.nodes.wrapper;
  }

  displayErrorAbout = (flag) => {
    flag ? this.nodes.about.classList.add('error') : this.nodes.about.classList.remove('error');

    return this.nodes.about;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
