export default class Ui {
  constructor({ rating, name, location, purity, service, price, title, comment, total, t }) {
    this.name = name;
    this.rating = rating;
    this.title = title;
    this.location = location;
    this.purity = purity;
    this.service = service;
    this.price = price;
    this.comment = comment;
    this.total = total;
    this.t = t;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      title: make('h2', this.CSS.title, {
        innerHTML: this.t('Comments.Title')
      }),
      commentTitle: make('div', this.CSS.commentTitle, {
        contentEditable: true
      }),
      headerContainer: make('div', this.CSS.headerContainer),
      name: make('div', this.CSS.name, {
        contentEditable: true
      }),
      comment: make('div', this.CSS.comment, {
        contentEditable: true
      }),
      total: make('div', this.CSS.total, {
        contentEditable: true
      }),
      container: make('div', this.CSS.container)
    };
  }

  get CSS() {
    return {
      wrapper: 'comment',
      total: 'comment_total',
      headerContainer: 'comment_header_container',
      title: 'comment_title',
      radioButton: 'comment_radio',
      label: 'comment_label',
      value: 'comment_value',
      container: 'comment_container',
      locationSlider: 'comment_location_slider',
      locationContainer: 'comment_location_container',
      sliderContainer: 'comment_slider_container',
      puritySlider: 'comment_purity_slider',
      purityContainer: 'comment_purity_container',
      serviceSlider: 'comment_service_slider',
      serviceContainer: 'comment_service_container',
      priceSlider: 'comment_price_slider',
      priceContainer: 'comment_price_container',

      nameConatiner: 'comment_name_container',
      name: 'comment_name',
      ratingSlider: 'comment_rating_slider',
      comment: 'comment_text',
      commentTitle: 'comment_best_title'
    };
  }

  getData() {
    const rating = this.nodes.wrapper.querySelector(`.${this.CSS.ratingSlider}`);
    const location = this.nodes.wrapper.querySelector(`.${this.CSS.locationSlider}`);
    const purity = this.nodes.wrapper.querySelector(`.${this.CSS.puritySlider}`);
    const service = this.nodes.wrapper.querySelector(`.${this.CSS.serviceSlider}`);
    const price = this.nodes.wrapper.querySelector(`.${this.CSS.priceSlider}`);
    const total = this.nodes.wrapper.querySelector(`.${this.CSS.total}`);

    return {
      name: this.nodes.name.innerHTML,
      comment: this.nodes.comment.innerHTML,
      total: total.innerText,
      rating: rating.value,
      location: location.value,
      purity: purity.value,
      service: service.value,
      price: price.value,
      title: this.nodes.commentTitle.innerHTML
    };
  }

  render() {
    this.renderHeader();
    this.renderContainer();
    this.nodes.wrapper.appendChild(this.nodes.container);
    this.renderComment();

    return this.nodes.wrapper;
  }

  renderHeader() {
    this.nodes.total.innerHTML = this.total;
    this.nodes.headerContainer.appendChild(this.nodes.title);
    this.nodes.headerContainer.appendChild(this.nodes.total);
    this.nodes.wrapper.appendChild(this.nodes.headerContainer);
  }

  renderContainer() {
    const location = this.renderSlider(this.CSS.locationSlider, this.location, this.t('Comments.Location'), 0);
    const purity = this.renderSlider(this.CSS.puritySlider, this.purity, this.t('Comments.Purity'), 1);
    const service = this.renderSlider(this.CSS.serviceSlider, this.service, this.t('Comments.Service'), 2);
    const price = this.renderSlider(this.CSS.priceSlider, this.price, this.t('Comments.Price'), 3);

    this.nodes.container.appendChild(location);
    this.nodes.container.appendChild(purity);
    this.nodes.container.appendChild(service);
    this.nodes.container.appendChild(price);
  }

  renderComment() {
    this.nodes.name.innerHTML = this.name;
    this.nodes.comment.innerHTML = this.comment;
    this.nodes.commentTitle.innerHTML = this.title;
    const rating = this.renderSlider(this.CSS.ratingSlider, this.rating, this.t('Comments.Rating'), 4, 0.1);
    const container = make('div', this.CSS.nameConatiner);

    container.appendChild(this.nodes.name);
    container.appendChild(rating);

    this.nodes.wrapper.appendChild(container);
    this.nodes.wrapper.appendChild(this.nodes.commentTitle);
    this.nodes.wrapper.appendChild(this.nodes.comment);
  }

  renderSlider(slider, value, label, index, step = 1) {
    const locationContainer = make('div', this.CSS.sliderContainer);
    const valueComponent = make('div', this.CSS.value, {
      innerHTML: value
    });
    const labelComponent = make('div', this.CSS.label, {
      innerHTML: label
    });

    const location = make('input', slider, {
      type: 'range',
      min: 0,
      max: 10,
      step,
      value
    });

    location.addEventListener('input', (e) => {
      this.handleChangeValue(index, e.target.value);
    });

    locationContainer.appendChild(labelComponent);
    locationContainer.appendChild(location);
    locationContainer.appendChild(valueComponent);

    return locationContainer;
  }

  handleChangeValue = (index, data) => {
    this.nodes.wrapper.getElementsByClassName(this.CSS.value)[index].innerHTML = data;
  };

  displayErrorName = (flag) => {
    flag ? this.nodes.name.classList.add('error') : this.nodes.name.classList.remove('error');

    return this.nodes.name;
  };

  displayErrorComment = (flag) => {
    flag ? this.nodes.comment.classList.add('error') : this.nodes.comment.classList.remove('error');

    return this.nodes.comment;
  };

  displayErrorTitle = (flag) => {
    flag ? this.nodes.commentTitle.classList.add('error') : this.nodes.commentTitle.classList.remove('error');

    return this.nodes.commentTitle;
  };

  displayErrorDescription = (flag) => {
    flag ? this.nodes.description.classList.add('error') : this.nodes.description.classList.remove('error');

    return this.nodes.description;
  };
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
