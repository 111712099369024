import React from 'react';
import clsx from 'clsx';
import { Flex, Text, Popover, ActionIcon } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import showArticle from '../../utils/showArticle';

import { type BlogCardsProps, type IArticleResponse } from 'types';

import classes from './Popover.module.css';

type Props = Pick<BlogCardsProps, 'sitePath' | 'onEditForm' | 'onDelete'> & { blog: IArticleResponse };

const CardPopover = ({ blog, sitePath, onEditForm, onDelete }: Props) => {
  const { published, slug } = blog;
  const { t } = useTranslation();

  return (
    <Popover position='bottom' withArrow shadow='md'>
      <Popover.Target>
        <ActionIcon color='gray'>
          <IconDots
            style={{
              width: '70%',
              height: '70%'
            }}
          />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown style={{ zIndex: 149 }} px='0' py='xs'>
        <Flex direction='column' gap='4px'>
          <Text className={classes.popover_item} onClick={() => onDelete(blog)} px='md' py='4px' fz='sm'>
            {t('Action.Delete')}
          </Text>
          <Text className={classes.popover_item} onClick={() => onEditForm(blog.id)} px='md' py='4px' fz='sm'>
            {t('Action.EditForm')}
          </Text>
          <Text
            className={clsx(classes.popover_item, !published && classes.popover_item_disabled)}
            onClick={() => showArticle(published, sitePath, slug)}
            px='md'
            py='4px'
            fz='sm'
          >
            {t('Action.ShowSite')}
          </Text>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default CardPopover;
