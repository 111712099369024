import { createAsyncThunk } from '@reduxjs/toolkit';

import { objectsFetch } from 'apis/ObjectsApi';

const ACTION = 'objects/fetch';

const fetchArticles = createAsyncThunk(
  ACTION,
  objectsFetch
);

export default fetchArticles;
