import React from 'react';
import { Controller, useFormContext, type FieldErrors, type FieldValues } from 'react-hook-form';
import { Textarea, type TextareaProps } from '@mantine/core';
import cx from 'clsx';
import { IconAlertOctagon } from '@tabler/icons-react';
import ConditionalComponent from '../../ConditionalComponent';

import classes from './TextareaForm.module.css';

interface Props extends TextareaProps {
  width?: string;
  customError?: string | undefined | null;
}

const getError = (path: string, errors: FieldErrors<FieldValues>) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], errors);
};

const TextareaForm = ({
  name,
  label,

  className,
  placeholder,
  disabled,
  onBlur,
  width,
  description,
  leftSection,

  customError,
  required = false,
  minRows = 3,
  maxRows = 8
}: Props) => {
  const {
    formState: { errors }
  } = useFormContext<{ name: Props['name'] }>();

  const errorMsg = getError(name, errors) ? String(getError(name, errors)?.message) : undefined;

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <Textarea
            {...field}
            label={label}
            placeholder={placeholder}
            required={required}
            error={errorMsg || customError}
            w={width}
            leftSection={leftSection}
            errorProps={{ className: classes.errorLabel }}
            description={description}
            disabled={disabled}
            rightSection={
              <ConditionalComponent condition={!!errorMsg}>
                <IconAlertOctagon size={20} />
              </ConditionalComponent>
            }
            autosize
            minRows={minRows}
            maxRows={maxRows}
            className={cx(className, errorMsg && classes.error)}
            onBlur={onBlur}
            withAsterisk={required}
          />
        );
      }}
    />
  );
};

export default TextareaForm;
