import React from 'react';
import { Flex, ActionIcon } from '@mantine/core';

type ICardProps = {
  className?: string;
  type: string;
  onChange: (d: any) => void;
  types: any[];
};

function Card({ className, type, onChange, types }: ICardProps) {
  const handleClick = (selectedType) => () => {
    onChange && onChange(selectedType);
  };

  const typesComponent = types.map((typeCurrent, index) => {
    return (
      <ActionIcon
        fz='24px'
        color={typeCurrent.name === type ? 'green' : 'dark'}
        key={index}
        onClick={handleClick(typeCurrent.name)}
      >
        {typeCurrent.icon}
      </ActionIcon>
    );
  });

  return (
    <Flex align='center' gap='md' className={className}>
      {typesComponent}
    </Flex>
  );
}

export default Card;
