import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Flex, Button, ActionIcon } from '@mantine/core';
import { IconPlus, IconX } from '@tabler/icons-react';

import MetaTagsSelect from 'components/forms/MetaTagsSelect';
import InputForm from 'components/ui/InputForm';
import { useTranslation } from 'react-i18next';
import { AUTHORS_FIELDS } from '../schema';

import { AuthorResponse } from 'types/authors';

type Props = {
  author?: AuthorResponse;
};

function MetaTagsForm({ author }: Props) {
  const { t } = useTranslation();
  const arrFieldName = 'meta_tags';
  const { fields, append, remove } = useFieldArray({
    name: arrFieldName
  });

  useEffect(() => {
    if (author?.meta_tags?.length) {
      // Очищаем текущие поля и добавляем новые из автора
      remove();
      author.meta_tags.forEach((tag) => {
        append({
          tag: tag.tag,
          name: tag.name,
          content: tag.content
        });
      });
    }
  }, [author, append, remove]);

  const handleAddClick = (ev) => {
    ev.preventDefault();

    append({});
  };

  const handleRemoveClick = (index) => (ev) => {
    ev.preventDefault();

    remove(index);
  };

  return (
    <>
      {fields.map((item, index) => (
        <Flex key={item.id} align='center' gap='md'>
          <Flex direction='column'>
            <Flex gap='md'>
              <MetaTagsSelect
                name={`${arrFieldName}.${index}.${AUTHORS_FIELDS.TAG_FIELD}`}
                placeholder={t('Common.MetaTags.NamePlaceholder')}
                label={t('Common.MetaTags.Name')}
              />

              <InputForm
                name={`${arrFieldName}.${index}.${AUTHORS_FIELDS.NAME_FIELD}`}
                placeholder={t('Common.MetaTags.PropertyPlaceholder')}
                label={t('Common.MetaTags.Property')}
              />
            </Flex>

            <InputForm
              name={`${arrFieldName}.${index}.${AUTHORS_FIELDS.CONTENT_FIELD}`}
              placeholder={t('Common.MetaTags.ContentPlaceholder')}
              label={t('Common.MetaTags.Content')}
            />
          </Flex>
          <ActionIcon onClick={handleRemoveClick(index)} size='sm' variant='light' color='red'>
            <IconX size={24} />
          </ActionIcon>
        </Flex>
      ))}

      <Button onClick={handleAddClick} size='sm' leftSection={<IconPlus size={18} />} variant='light'>
        Добавить пару
      </Button>
    </>
  );
}

export default MetaTagsForm;
