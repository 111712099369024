import React from 'react';
import { Flex, Breadcrumbs, Text, Button, Anchor } from '@mantine/core';
import { AuthorResponse } from 'types/authors';

type Props = {
  reviewer: AuthorResponse;
  onSubmit: (reviewer: any) => void;
  onReset: (reviewer: any) => void;
};

function Top({ reviewer, onSubmit, onReset }: Props) {
  return (
    <Flex w='100%' justify='space-between'>
      <Flex direction='column'>
        <Breadcrumbs separator={<Text fz={25}>/</Text>}>
          <Anchor href='/reviewers/'>Авторы</Anchor>
          <Text>Редактирование автора</Text>
        </Breadcrumbs>
        <Text fz='20px' fw='500'>
          {reviewer.first_name}
        </Text>
      </Flex>
      <Flex gap='md' align='center'>
        <Button color='green' onClick={onSubmit}>
          Сохранить
        </Button>
        <Button color='gray' onClick={onReset}>
          Отменить
        </Button>
      </Flex>
    </Flex>
  );
}

export default Top;
