import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Flex, Text } from '@mantine/core';

import InputForm from 'components/ui/InputForm';
import { REGIONS_FIELDS } from './schema';
import TextareaForm from 'components/ui/TextareaForm';
import FullRegionSelectForm from './FullRegionSelectForm';
import MetaTagsForm from 'pages/BlogNew/components/MetaTagsForm';
import { IArticle } from 'types';

type IProps = {
  article?: IArticle;
};

function RegionsForm({ article }: IProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!article) {
      return;
    }
    setValue(REGIONS_FIELDS.TITLE_FIELD_RU, article.title.ru);
    setValue(REGIONS_FIELDS.SEO_TITLE_FIELD_RU, article.seo_title.ru);
    setValue(REGIONS_FIELDS.SEO_DESCRIPTION_FIELD_RU, article.seo_description.ru);
    setValue(REGIONS_FIELDS.SUB_TITLE_FIELD_RU, article.sub_title.ru);
    setValue(REGIONS_FIELDS.SEARCH_AREA, {
      ...article.place
    });
    setValue(REGIONS_FIELDS.TYPE_FIELD, article.type);
    setValue(REGIONS_FIELDS.META_TAGS_FIELD, article.meta_tags);
  }, [article, setValue]);

  return (
    <Flex direction='column' gap='lg'>
      <Flex direction='column' gap='xs'>
        <Text>{t('Common.SearchArea')}</Text>
        <InputForm name='type' type='hidden' />
        <FullRegionSelectForm
          label={t('Objects.New.Form.SearchArea')}
          placeholder={t('SearchArea.Objects.Placeholder')}
          name={REGIONS_FIELDS.SEARCH_AREA}
        />
      </Flex>

      <Flex direction='column' gap='xs'>
        <Text>{t('Common.General')}</Text>
        <InputForm
          name={REGIONS_FIELDS.TITLE_FIELD_RU}
          placeholder={t('Common.Name.Placeholder')}
          label={t('Regions.Form.Name')}
          required
        />
      </Flex>

      <Flex direction='column' gap='xs'>
        <Text>{t('Regions.Subtitle')}</Text>
        <InputForm
          name={REGIONS_FIELDS.SUB_TITLE_FIELD_RU}
          placeholder={t('Common.Name.Placeholder')}
          label={t('Regions.Form.Subtitle')}
          required
        />
      </Flex>

      <Flex direction='column' gap='xs'>
        <Text>{t('Common.Seo.Title')}</Text>
        <InputForm
          name={REGIONS_FIELDS.SEO_TITLE_FIELD_RU}
          placeholder={t('Common.Name.Placeholder')}
          label={t('Common.Title')}
        />
        <TextareaForm
          name={REGIONS_FIELDS.SEO_DESCRIPTION_FIELD_RU}
          placeholder={t('Common.Description.Placeholder')}
          label={t('Common.Description')}
        />
        <MetaTagsForm />
      </Flex>

      <Flex align='center' gap='md'>
        <Button type='submit' size='md' color='green' px='xl'>
          {t('Action.Save')}
        </Button>

        <Button type='reset' size='md' color='gray'>
          {t('Action.Cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default RegionsForm;
