import { useState, useCallback } from 'react';
import reviewersApi from 'apis/ReviewersApi';

import { AuthorRequest, AuthorResponse } from 'types/authors';

type useReviewers = {
  (): [
    value: AuthorResponse[],
    funcs: {
      create: (reviewer: AuthorRequest) => Promise<{ data: AuthorResponse; error: any }>;
      update: (id: string, reviewer: AuthorRequest) => Promise<{ data: AuthorResponse; error: any }>;
      deleteReviewer: (reviewId: string) => void;
      // publish: (reviewer: AuthorRequest) => void;
      // unpublish: (reviewer: AuthorRequest) => void;
      fetchList: () => void;
      fetchOne: (reviewId: string) => void;
    }
  ];
};

const useReviewers: useReviewers = () => {
  // все данные автора, которые приходят с сервера
  const [value, setValue] = useState<AuthorResponse[]>([]);

  // publish & unpublish
  /*  const replaceValue = useCallback((data: AuthorResponse) => {
     setValue((state) =>
       state.map((item) => {
         return item.id === data.id ? data : item;
       })
     );
   }, []); */

  const removeValue = useCallback((id: string) => {
    setValue((state) =>
      state.filter((item) => {
        return item.id !== id;
      })
    );
  }, []);

  const appendValue = useCallback((data: AuthorResponse) => {
    setValue((state) => [...state, data]);
  }, []);

  const create = async (props: AuthorRequest) => {
    const { data, error } = await reviewersApi.create(props);

    if (!error) {
      appendValue(data);
    }

    return {
      data,
      error
    };
  };

  const update = async (id: string, props: AuthorRequest) => {
    const { data, error } = await reviewersApi.update(id, props);

    if (!error) {
      appendValue(data);
    }

    return {
      data,
      error
    };
  };

  const deleteReviewer = async (id: string) => {
    const { error } = await reviewersApi.delete(id);

    if (!error) {
      removeValue(id);
    }
  };

  const fetchList = async () => {
    const { data, error } = await reviewersApi.fetchList();

    if (!error) {
      setValue(data);
    }

    return {
      data,
      error
    };
  };

  const fetchOne = async (id: string) => {
    const { data, error } = await reviewersApi.fetchOne(id);

    if (!error) {
      setValue([data]);
    }

    return {
      data,
      error
    };
  };

  /*
    const publish = async ({ id }: AuthorRequest) => {
      const { data, error } = await reviewersApi.publish(id);

      if (!error) {
        replaceValue(data);
      }
    };

    const unpublish = async ({ id }: AuthorRequest) => {
      const { data, error } = await reviewersApi.unpublish(id);

      if (!error) {
        replaceValue(data);
      }
    }; */

  return [
    value,
    {
      create,
      update,
      deleteReviewer,

      // publish,
      // unpublish,
      fetchList,

      fetchOne
    }
  ];
};

export default useReviewers;
