import getTranslate from 'utils/getTranslate';

function cancellationDeadline(rule) {
  const { t } = getTranslate();

  const day = t('Common.Day', { count: rule.deadline.amount });
  let penalty;

  switch (rule.penalty.unit) {
    case 'percent':
      penalty = t('Tariffs.Rules.Percent', { count: rule.penalty.amount });
      break;

    case 'first_day':
      penalty = t('Tariffs.Rules.Days', { count: rule.penalty.amount });
      break;

    default:
      penalty = t('Tariffs.Rules.Rub', { count: rule.penalty.amount });
      break;
  }

  return t('Tariffs.Rules.Content', { day, penalty });
}

export default cancellationDeadline;
