import * as React from 'react';
import { Controller } from 'react-hook-form';
import { DatePickerInput } from '@mantine/dates';

type Props = React.PropsWithChildren<{
    className?: string | undefined;
    name: string;
    format: string;
    label?: string;
    placeholder?: string;
    width?: string;
    type?: string;
    defaultValue?: string;
    required?: boolean;
}>;

function DatePickerForm({ name, label, className, placeholder, format, width, required, defaultValue }: Props) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue || new Date().toISOString()}
      render={({ field: { onChange, value, ...rest } }) => (
        <DatePickerInput
          {...rest}
          value={value ? new Date(value) : new Date()}
          onChange={(date) => onChange(date?.toISOString())}
          className={className}
          w={width}
          label={label}
          valueFormat={format}
          placeholder={placeholder}
          required={required}
          locale='ru'
          withAsterisk={required}
          defaultValue={new Date()}
          defaultDate={new Date()}
        />
      )}
    />
  );
}

export default DatePickerForm;
