import React, { useMemo, useState } from 'react';
import { Flex, NavLink } from '@mantine/core';

import NavContent from './NavContent';
import { IObjectDataFull } from 'types';

import getTranslate from 'utils/getTranslate';

type Props = {
  hotel: any,
  object: IObjectDataFull,
  currentLanguage: string
}

type Tab = {
  name: string,
  tag: string,
  isActive: boolean
}

function Content({ hotel, object, currentLanguage }: Props) {
  const { t } = getTranslate(currentLanguage);
  const blocks = object.body_translations[currentLanguage]?.blocks;

  const tabs: Tab[] = useMemo(() => {
    return [
      {
        name: t('HotelPage.Content.Descriptions', { language: currentLanguage }),
        tag: 'description',
        isActive: blocks?.find((b) => b.type === 'description')?.data?.about?.length > 0
      },
      {
        name: t('Description.Title.ChatGPT', { language: currentLanguage }),
        tag: 'descriptionChatGPT',
        isActive: blocks?.find((b) => b.type === 'descriptionChatGPT')?.data?.about?.length > 0
      },
      {
        name: t('HotelPage.Content.Programms'),
        tag: 'programms',
        isActive: blocks?.find((b) => b.type === 'programs')?.data?.list?.length > 0
      },
      {
        name: t('HotelPage.Content.Specializations'),
        tag: 'specializations',
        isActive: hotel.specializations.length > 0
      },
      {
        name: t('HotelPage.Content.Procedures'),
        tag: 'procedures',
        isActive: blocks?.find((b) => b.type === 'procedures')?.data?.list?.length > 0
      },
      {
        name: t('HotelPage.Content.MealOptions'),
        tag: 'meal',
        isActive: blocks?.find((b) => b.type === 'feeding_programs')?.data?.list?.length > 0
      },
      {
        name: t('HotelPage.Content.Amenities'),
        tag: 'amenities',
        isActive: hotel.amenities.length > 0
      }
    ];
  }, [currentLanguage]);

  const [selected, setSelected] = useState<Tab | undefined>(() => tabs?.find((t) => t.isActive));

  const handleClick = (item: Tab) => () => {
    setSelected(item);
  };

  const linkElements = tabs.filter((tab) => tab.isActive === true).map((tab) => (
    <NavLink
      key={tab.tag}
      label={tab.name}
      active={selected?.tag === tab.tag}
      onClick={handleClick(tab)}
      color='rgb(241, 229, 221)'
      variant='filled'
      autoContrast
      h='50px'
    />
  ));

  return (
    <Flex direction='column' mt='md'>
      <Flex align='center' h='50px' bg='rgb(250, 244, 240)'>
        {linkElements}
      </Flex>

      <NavContent
        currentLanguage={currentLanguage}
        page={selected}
        hotel={hotel}
        object={object}
      />

    </Flex>
  );
}

export default Content;
