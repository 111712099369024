import React, { useMemo } from 'react';
import { Box } from '@mantine/core';

import arrayToTree from 'array-to-tree';
import Group from './Group';

type Props = {
  hotel: any
}

function Amenities({ hotel }: Props) {
  const amenities = useMemo(() => {
    return arrayToTree(hotel.amenities || [], {
      parentProperty: 'parent_tag',
      customID: 'tag'
    });
  }, [hotel]);

  const elements = amenities.map((group: any) => <Group group={group} key={group.id} />);

  return (
    <Box mt='md' style={{ columnCount: 2 }}>
      {elements}
    </Box>
  );
}

export default Amenities;
