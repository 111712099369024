import React from 'react';
import { Box, Paper, Button } from '@mantine/core';
import InputForm from 'components/ui/InputForm';
import { LOGIN_FIELDS } from './schema';

type AuthFormProps = {
  loading: boolean;
};

function AuthForm({ loading }: AuthFormProps) {
  return (
    <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
      <Box mb='md'>
        <InputForm name={LOGIN_FIELDS.EMAIL} label='Email' placeholder='Ваш email' required />
      </Box>
      <Box mb='lg'>
        <InputForm name={LOGIN_FIELDS.PASSWORD} label='Password' placeholder='Ваш пароль' isPasswordField required />
      </Box>
      <Button type='submit' fullWidth loading={loading} color='green'>
        Войти
      </Button>
    </Paper>
  );
}

export default AuthForm;
