import * as russian from 'i18next-icu/locale-data/ru';
import ru from 'src/locales/ru/ru';
import en from 'src/locales/en/en';

export default {
  resources: { ru, en },
  lng: 'ru',
  fallbackLng: 'ru',
  keySeparator: false,
  whitelist: ['ru', 'en'],
  i18nFormat: {
    localeData: russian,
    formats: {
      number: {
        RUB: {
          style: 'currency',
          currency: 'RUB',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }
      }
    }
  }
};
