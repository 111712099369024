import chevronRightIcon from '../icons/chevron-right';
import editIcon from '../icons/edit';

export const classNames = {
  container: 'carousel-block__container',
  imageList: 'carousel-block__image-list',
  imageListItem: 'carousel-block__image-list-item',
  controlPrevContainer: 'carousel-block__control-prev-container',
  controlNextContainer: 'carousel-block__control-next-container',
  controlIconContainer: 'carousel-block__control-icon-container',
  indicatorsContainer: 'carousel-block__indicators-container',
  indicatorsInnerContainer: 'carousel-block__indicators-inner-container',
  indicatorsDivider: 'carousel-block__indicators-divider',
  imagesCurrentNumberIndicator: 'carousel-block__images-current-number-indicator',
  imagesCountIndicator: 'carousel-block__images-count-indicator'
};

export const SIDE_IMAGES_COUNT = 3;

const carouselBlock = {
  mixins: ['block'],
  type: 'carousel',
  draggable: false,
  parser: {
    tag: 'div',
    parse: function ($el) {
      return ($el.hasClass('carousel')) ? 'carousel' : false;
    }
  },
  toolbar: {
    add: { command: 'addbar.popup', title: '## buttons.add ##' },
    edit: {
      command: 'carousel.edit',
      title: '## carousel.edit ##',
      icon: editIcon
    }
  },
  control: {
    trash: { command: 'block.remove', title: '## buttons.delete ##' }
  },
  translations: {
    en: {
      blocks: {
        carousel: 'Carousel block'
      }
    }
  },

  create: function () {
    const id = this.app.utils.getRandomId();
    const $block = this.dom('<div>')
      .addClass(`${classNames.container} noneditable carousel`);

    $block.attr('id', id);

    const state = {
      horizontalScroll: 0,
      currentNumber: 1
    };

    this._createImageList($block);
    this._createPrevControl($block);
    this._createNextControl($block);
    this._createIndicators($block, state);

    this._handlePrevControlsClick($block, state);
    this._handleNextControlsClick($block, state);

    return $block;
  },

  _createImageList: function ($block) {
    const $imageList = this.dom('<div>')
      .addClass(classNames.imageList);

    $block.append($imageList);
  },

  _createPrevControl: function ($block) {
    const $control = this.dom('<div>')
      .addClass(classNames.controlPrevContainer);

    const $iconContainer = this.dom('<div>')
      .addClass(classNames.controlIconContainer);

    $iconContainer.append(chevronRightIcon);
    $control.append($iconContainer);
    $block.append($control);
  },

  _createNextControl: function ($block) {
    const $control = this.dom('<div>')
      .addClass(classNames.controlNextContainer);

    const $iconContainer = this.dom('<div>')
      .addClass(classNames.controlIconContainer);

    $iconContainer.append(chevronRightIcon);
    $control.append($iconContainer);
    $block.append($control);
  },

  _createIndicators: function ($block, state) {
    const $indicatorsContainer = this.dom('<div>')
      .addClass(classNames.indicatorsContainer);

    const $indicatorsInnerContainer = this.dom('<div>')
      .addClass(classNames.indicatorsInnerContainer);

    const $indicatorsDivider = this.dom('<div>')
      .addClass(classNames.indicatorsDivider)
      .text('/');

    const $imagesCurrentNumberIndicator = this.dom('<div>')
      .addClass(classNames.imagesCurrentNumberIndicator)
      .text(state.currentNumber);

    const $imagesCountIndicator = this.dom('<div>')
      .addClass(classNames.imagesCountIndicator)
      .text('-');

    $indicatorsContainer.append($indicatorsInnerContainer);

    $indicatorsInnerContainer.append($imagesCurrentNumberIndicator);
    $indicatorsInnerContainer.append($indicatorsDivider);
    $indicatorsInnerContainer.append($imagesCountIndicator);

    $block.append($indicatorsContainer);
  },

  _handlePrevControlsClick: function ($block, state) {
    const $prevControl = $block.find(`.${classNames.controlPrevContainer} .${classNames.controlIconContainer}`);

    $prevControl.on('click', () => {
      const $imageList = $block.find(`.${classNames.imageList}`).nodes[0];
      const scrollStep = $imageList.getBoundingClientRect().width;
      const $images = $block.find(`.${classNames.imageList} img`);
      const currentImageListWidth = scrollStep * ($images.length - SIDE_IMAGES_COUNT - 1);

      if (state.horizontalScroll > currentImageListWidth) {
        state.horizontalScroll = currentImageListWidth;
      }

      state.horizontalScroll -= scrollStep;

      if (state.horizontalScroll < 0) {
        state.horizontalScroll = 0;
      }

      this._updateCurrentNumber($block, state, -1);
      this._updateHorizontalScroll($block, state);
    });
  },

  _handleNextControlsClick: function ($block, state) {
    const $nextControl = $block.find(`.${classNames.controlNextContainer} .${classNames.controlIconContainer}`);

    $nextControl.on('click', () => {
      const $imageList = $block.find(`.${classNames.imageList}`).nodes[0];
      const scrollStep = $imageList.getBoundingClientRect().width;
      const $images = $block.find(`.${classNames.imageList} img`);
      const currentImageListWidth = scrollStep * ($images.length - SIDE_IMAGES_COUNT - 1);

      if (state.horizontalScroll > currentImageListWidth) {
        state.horizontalScroll = currentImageListWidth;
      }

      state.horizontalScroll += scrollStep;

      this._updateCurrentNumber($block, state, 1);
      this._updateHorizontalScroll($block, state);
    });
  },

  _updateCurrentNumber: function ($block, state, increaseValue) {
    const $images = $block.find(`.${classNames.imageList} img`);

    if (state.currentNumber + increaseValue < 1) {
      state.currentNumber = 1;

      return;
    }

    if (state.currentNumber + increaseValue > $images.length - SIDE_IMAGES_COUNT) {
      state.currentNumber = $images.length - SIDE_IMAGES_COUNT;

      return;
    }

    state.currentNumber += increaseValue;

    $block
      .find(`.${classNames.imagesCurrentNumberIndicator}`)
      .text(state.currentNumber);
  },

  _updateHorizontalScroll: function ($block, state) {
    const $imageList = $block.find(`.${classNames.imageList}`).nodes[0];

    $imageList.scrollTo({
      left: state.horizontalScroll,
      behavior: 'smooth'
    });
  },

  _destroy: function () {
    // ToDo. Need implementation
  }
};

export default carouselBlock;
