import { createAsyncThunk } from '@reduxjs/toolkit';

import { parsingCreate } from 'apis/ParsingApi';

const ACTION = 'parsing/create';

const createParsingAction = createAsyncThunk(
  ACTION,
  parsingCreate
);

export default createParsingAction;
