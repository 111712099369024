import React from 'react';
import { Flex, Image } from '@mantine/core';

import { getImage } from 'utils/getImage';
import { IImage } from 'types';

type Props = {
  className?: string;
  hotel: any;
};

function Gallery({ hotel }: Props) {
  const mainImage: IImage = hotel.images.length > 0 && hotel.images[0];
  const image = getImage(mainImage?.encoded_url, { width: 810, height: 380 });
  const smallImages: IImage[] = hotel.images.slice(0, 4);
  const images = smallImages
    .map((img) => getImage(img.encoded_url, { width: 196, height: 110 }))
    .map((img, index) => <Image key={index} {...img} />);

  return (
    <Flex mt='20px' direction='column' gap='xs'>
      <Image {...image} />
      <Flex gap='8px' style={{ overflow: 'hidden' }}>
        {images}
      </Flex>
    </Flex>
  );
}
export default Gallery;
