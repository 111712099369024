import { IObjectData } from 'types';
import reviewerMapper from './reviewMapper';
import programsMapper from './programsMapper';
import proceduresMapper from './proceduresMapper';

type Distance = {
  description: string,
  icon: string,
  name: string,
  unit: string,
  value: number
}

type Description = {
  about: string,
  preview: string
}

type Feed = {
  title: string,
  content: string
}

function toBlocksMapper(hotelDraft: any) {
  if (hotelDraft.content_version === 2 && hotelDraft.body_translations) {
    return {
      content: {
        ...hotelDraft.body_translations
      }
    };
  }

  const hotel: IObjectData = {
    content: {
      ru: {
        blocks: blocksMapper(hotelDraft),
        time: 1664894659119,
        version: '2.25.0',
        name: hotelDraft.name
      },
      en: {
        blocks: blocksMapper(hotelDraft),
        time: 1664894659119,
        version: '2.25.0',
        name: hotelDraft.name
      }
    }
  };

  return hotel;
}

function blocksMapper(hotelDraft: any) {
  if (hotelDraft.content_version === 2) {
    return hotelDraft.body_translations.blocks;
  }

  return [
    starsMapper(hotelDraft.star),
    distancesMapper(hotelDraft.distances),
    reviewerMapper(hotelDraft.review),
    descriptionMapper(hotelDraft.site_description),
    programsMapper(hotelDraft.treatment_programs),
    proceduresMapper(hotelDraft.manipulation_programs),
    feedMapper(hotelDraft.feeding_programs)
  ];
}

function feedMapper(types: Feed[]) {
  return {
    type: 'feeding_programs',
    data: {
      list: types.map((t) => ({
        ...t,
        description: t.content
      }))
    }
  };
}

function descriptionMapper(description: Description) {
  return {
    type: 'description',
    data: {
      ...description
    }
  };
}

function starsMapper(star: number) {
  return {
    type: 'stars',
    data: {
      rating: star
    }
  };
}

function distancesMapper(distances: Distance[]) {
  return {
    type: 'distances',
    data: {
      list: distances
    }
  };
}

export default toBlocksMapper;
