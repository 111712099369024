import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesUpdate } from 'apis/ArticlesApi';

const ACTION = 'articles/update';

const deleteArticle = createAsyncThunk(
  ACTION,
  articlesUpdate
);

export default deleteArticle;
