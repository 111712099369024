import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { TextInputProps } from '@mantine/core';
import { FileInput } from '@mantine/core';

interface Props extends TextInputProps {
  width?: string;
  customError?: string | undefined | null;
}

const UploadFile = React.forwardRef<HTMLInputElement, Props>(({
  name,
  label,
  required = false,
  placeholder,
  disabled,
  width,
  leftSection,
  customError
}: Props, ref) => {
  const {
    formState: { errors }
  } = useFormContext<{ name: Props['name'] }>();

  const getError = (path: string, errors: any) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], errors);
  };
  const errorMsg = getError(name, errors) ? String(getError(name, errors)?.message) : undefined;
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <FileInput
          {...field}
          ref={ref}
          label={label}
          placeholder={placeholder}
          required={required}
          error={errorMsg || customError}
          w={width}
          leftSection={leftSection}
          disabled={disabled}
        />
      )}
    />
  );
});

UploadFile.displayName = 'FileInput';

export default UploadFile;
