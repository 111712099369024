import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesCreate } from 'apis/ArticlesApi';
import type { ArticleRequest, IArticleResponse, DiseaseRequest, RegionRequest } from 'types';

const ACTION = 'articles/create';

const createArticleAction = createAsyncThunk(
  ACTION,
  async (article: ArticleRequest | DiseaseRequest | RegionRequest): Promise<IArticleResponse> => {
    return await articlesCreate(article);
  }
);

export default createArticleAction;
