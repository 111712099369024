import { PayloadAction } from '@reduxjs/toolkit';
import {
  IArticleResponse,
  SHOW_TYPE,
  STATUSES,
  ARTICLE_FILTER_TYPE,
  ISearchArea,
  ISearchAreaResponseData,
  ISearchAreaMeta,
  ISearchAreaBreadcrumbs,
  ICatalog
} from 'types';
import _cloneDeep from 'lodash/cloneDeep';

const DEFAULTS = {
  state: STATUSES.IDLE,
  stateMore: STATUSES.IDLE,
  stateOne: STATUSES.IDLE,
  articleState: STATUSES.IDLE,
  searchAreas: [],
  searchArea: undefined,
  type: SHOW_TYPE.LIST,
  articleType: ARTICLE_FILTER_TYPE.BLOG,
  breadcrumbs: [],
  breadcrumbsOne: [],
  isLast: false,
  total: 0,
  catalog: []
};

export interface IAdapter {
  state: STATUSES;
  stateMore: STATUSES;
  stateOne: STATUSES;
  breadcrumbsOne: ISearchAreaBreadcrumbs[];
  searchAreas: ISearchArea[];
  searchArea?: ISearchArea;
  article?: IArticleResponse;
  type: SHOW_TYPE;
  articleState: STATUSES;
  articleType: ARTICLE_FILTER_TYPE;
  breadcrumbs: ISearchAreaBreadcrumbs[];
  isLast: boolean;
  total: number;
  catalog: ICatalog[];
}

class Adapter {
  public getInitialState(props?: IAdapter): IAdapter {
    return { ...DEFAULTS, ...props };
  }

  public searchAreaFetchPending(state: IAdapter): IAdapter {
    const currentState = { state: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public searchAreaFetchFulfilled(
    state: IAdapter,
    action: PayloadAction<ISearchAreaResponseData<ISearchArea[], ISearchAreaMeta>>
  ): IAdapter {
    const searchAreas: ISearchArea[] = action.payload.data;
    const breadcrumbs: ISearchAreaBreadcrumbs[] = action.payload.meta.breadcrumbs;
    const isLast: boolean = searchAreas.length === 0;
    const total = action.payload.meta.total;

    const currentState = {
      state: STATUSES.FULFILLED,
      searchAreas,
      breadcrumbs: breadcrumbs.reverse(),
      isLast,
      total
    };

    return { ...state, ...currentState };
  }

  public catalogFetchFullfilled(
    state: IAdapter,
    action: PayloadAction<ISearchAreaResponseData<ICatalog[], ISearchAreaMeta>>
  ): IAdapter {
    const catalog: ICatalog[] = action.payload.data;

    const currentState = {
      catalog
    };

    return { ...state, ...currentState };
  }

  public searchAreaFetchMoreFulfilled(
    state: IAdapter,
    action: PayloadAction<ISearchAreaResponseData<ISearchArea[], ISearchAreaMeta>>
  ): IAdapter {
    const searchAreas: ISearchArea[] = action.payload.data;
    const isLast: boolean = searchAreas.length === 0;

    const currentState = {
      stateMore: STATUSES.FULFILLED,
      searchAreas: [...state.searchAreas, ...searchAreas],
      isLast
    };

    return { ...state, ...currentState };
  }

  public searchAreaFetchMorePending(state: IAdapter): IAdapter {
    const currentState = { stateMore: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public searchAreaOneFetchFulfilled(
    state: IAdapter,
    action: PayloadAction<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>>
  ): IAdapter {
    const searchArea: ISearchArea = action.payload.data;
    const breadcrumbsOne: ISearchAreaBreadcrumbs[] = action.payload.meta.breadcrumbs;

    const currentState = {
      stateOne: STATUSES.FULFILLED,
      searchArea: searchArea,
      breadcrumbsOne: breadcrumbsOne.reverse()
    };

    return { ...state, ...currentState };
  }

  public searchAreaOneFetchPending(state: IAdapter): IAdapter {
    const currentState = { stateOne: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public searchAreaUpdateFulfilled(
    state: IAdapter,
    action: PayloadAction<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>>
  ): IAdapter {
    const searchArea = action.payload.data;
    const ind = state.searchAreas.findIndex((area) => area.id === searchArea.id);
    const searchAreas = _cloneDeep(state.searchAreas);
    searchAreas[ind] = searchArea;

    const currentState = {
      stateOne: STATUSES.FULFILLED,
      searchArea: searchArea,
      searchAreas
    };

    return { ...state, ...currentState };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
