import React from 'react';
import { Helmet } from 'react-helmet';

import useScripts from './hooks/useScripts';

import Editor from './Editor';
import carouselPlugin from './plugins/carousel';
import documentsPlugin from './plugins/documents';
import carouselBlock from './blocks/carousel';
import documentsBlock from './blocks/documents';

const EDITOR = [
  `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/article-editor.min.js`
];

const PLUGINS = [
  `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/plugins/style/style.min.js`,
  `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/plugins/counter/counter.min.js`,
  `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/plugins/reorder/reorder.min.js`
];

const STYLES = [
  `${process.env.PUBLIC_URL}/vendors/article-editor-2-3-5/css/article-editor.min.css`,
  /*
    Если плагин появляется вне iframe-а с редактором, например, в popup-е,
    то стили нужно подключать здесь (в head тэге основного документа),
    в противном случае нужно указывать пути к стилям при создании
    экземпляра редактора (ArticleEditor).
  */
  `${process.env.PUBLIC_URL}/vendors/custom/styles/article-editor/plugins/documents.css`,
  `${process.env.PUBLIC_URL}/vendors/custom/styles/article-editor/plugins/carousel.css`
];

function EditorWrapper(props) {
  const [loaded, error] = useScripts([EDITOR, PLUGINS]);

  if (!loaded) {
    return null;
  }

  if (error) {
    return null;
  }

  const ArticleEditor = window.ArticleEditor;

  ArticleEditor.add('block', 'block.documents', documentsBlock);
  ArticleEditor.add('block', 'block.carousel', carouselBlock);

  ArticleEditor.add('plugin', 'documents', documentsPlugin);
  ArticleEditor.add('plugin', 'carousel', carouselPlugin);

  return (
    <>
      <Helmet>
        {STYLES.map((href, idx) => (
          <link
            key={idx}
            rel='stylesheet'
            href={href}
          />
        ))}
      </Helmet>

      <Editor
        {...props}
        ArticleEditor={ArticleEditor}
      />
    </>
  );
}

export default EditorWrapper;
