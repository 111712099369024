import connection from 'connection/instance';

class CategoriesApi {
  constructor() {
    this.baseUrl = '/api/categories';
  }

  async handleRequest(method, url, data = null) {
    try {
      const response = await connection[method](url, data);
      return { data: response.data.data, error: undefined };
    } catch (error) {
      return { data: undefined, error };
    }
  }

  async fetchList() {
    return this.handleRequest('get', this.baseUrl);
  }
}

export default new CategoriesApi();
