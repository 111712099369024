import { EditorJSI18n } from 'types/editor';

export const editorJsI18n: Record<string, EditorJSI18n> = {
  ru: {
    messages: {
      ui: {
        blockTunes: {
          toggler: {
            'Click to tune': 'Нажмите, чтобы настроить',
            'or drag to move': 'или перетащите'
          }
        },
        inlineToolbar: {
          converter: {
            'Convert to': 'Преобразовать в'
          }
        },
        toolbar: {
          toolbox: {
            Add: 'Добавить'
          }
        }
      },
      toolNames: {
        Text: 'Параграф',
        procedures: 'test',
        Heading: 'Заголовок',
        List: 'Список',
        Warning: 'Примечание',
        Checklist: 'Чеклист',
        Quote: 'Цитата',
        Code: 'Код',
        Delimiter: 'Разделитель',
        'Raw HTML': 'HTML-фрагмент',
        Table: 'Таблица',
        Link: 'Ссылка',
        Marker: 'Маркер',
        Bold: 'Полужирный',
        Italic: 'Курсив',
        InlineCode: 'Моноширинный'
      },
      tools: {
        warning: {
          Title: 'Название',
          Message: 'Сообщение'
        },
        link: {
          'Add a link': 'Вставьте ссылку'
        },
        stub: {
          'The block can not be displayed correctly.': 'Блок не может быть отображен'
        }
      },
      blockTunes: {
        delete: {
          Delete: 'Удалить'
        },
        moveUp: {
          'Move up': 'Переместить вверх'
        },
        moveDown: {
          'Move down': 'Переместить вниз'
        }
      }
    }
  },
  en: {
    messages: {
      ui: {
        blockTunes: {
          toggler: {
            'Click to tune': 'Click to tune',
            'or drag to move': 'or drag to move'
          }
        },
        inlineToolbar: {
          converter: {
            'Convert to': 'Convert to'
          }
        },
        toolbar: {
          toolbox: {
            Add: 'Add'
          }
        }
      },
      toolNames: {
        Text: 'Paragraph',
        procedures: 'test',
        Heading: 'Heading',
        List: 'List',
        Warning: 'Warning',
        Checklist: 'Checklist',
        Quote: 'Quote',
        Code: 'Code',
        Delimiter: 'Delimiter',
        'Raw HTML': 'Raw HTML',
        Table: 'Table',
        Link: 'Link',
        Marker: 'Marker',
        Bold: 'Bold',
        Italic: 'Italic',
        InlineCode: 'Inline Code'
      },
      tools: {
        warning: {
          Title: 'Title',
          Message: 'Message'
        },
        link: {
          'Add a link': 'Add a link'
        },
        stub: {
          'The block can not be displayed correctly.': 'The block can not be displayed correctly.'
        }
      },
      blockTunes: {
        delete: {
          Delete: 'Delete'
        },
        moveUp: {
          'Move up': 'Move up'
        },
        moveDown: {
          'Move down': 'Move down'
        }
      }
    }
  }
};
