import { createAsyncThunk } from '@reduxjs/toolkit';
import { catalogFetch } from 'apis/SearchAreaApi';

const ACTION = 'search_area/catalog';

const catalogFetchAction = createAsyncThunk(
  ACTION,
  catalogFetch
);

export default catalogFetchAction;
