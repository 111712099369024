const likeIcon = `
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4.40247V11.4025C0 11.8857 0.391731 12.2775 0.875
      12.2775H3.5V3.52747H0.875C0.391731 3.52747 0 3.9192 0 4.40247Z"
    />

    <path
      d="M12.3842 4.40228H9.79932C9.57331 4.40228 9.46009 4.25147
      9.42206 4.18652C9.38404 4.12201 9.30713 3.94983 9.41651
      3.75244L10.3278 2.11139C10.5278 1.75207 10.5483 1.32953
      10.3846 0.952266C10.221 0.574581 9.89801 0.300716 9.49853
      0.201168L8.85595 0.0405234C8.69958 0.00121677 8.53381
      0.0512046 8.42486 0.172116L4.93597 4.04809C4.5741 4.45099
      4.375 4.97052 4.375 5.51141V10.0898C4.375 11.2959 5.35638
      12.2773 6.5625 12.2773L10.9149 12.2768C11.8975 12.2768
      12.7653 11.615 13.0246 10.6678L13.9586 6.3817C13.9859 6.2625
      14 6.14031 14 6.01812C14 5.12731 13.275 4.40227 12.3841
      4.40227L12.3842 4.40228Z"
    />
  </svg>
`;

const starIcon = `
  <svg
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 18.75L5.15268 22.6127L6.5559 14.4314L0.611794 8.63729L8.82634 7.44364L12.5 0L16.1737 7.44364L24.3882 8.63729L18.4441 14.4314L19.8473 22.6127L12.5 18.75Z"
      fill="#FFD544"
    />
  </svg>
`;

const mapPointIcon = `
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="22">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.210938H17.8356V21.0315H0V0.210938Z" fill="white"/>
  </mask>
  <g mask="url(#mask0)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9184 21.032C8.7576 21.032 8.5968 20.9824 8.46 20.8819C8.1156 20.6289 0 14.6295 0 8.97757C0 3.65246 3.5004 0.210938 8.9184 0.210938C14.3352 0.210938 17.8356 3.65246 17.8356 8.97757C17.8356 14.6295 9.7212 20.6289 9.3768 20.8819C9.24 20.9824 9.0792 21.032 8.9184 21.032ZM8.9184 1.78462C5.3616 1.78462 1.5612 3.67425 1.5612 8.97757C1.5612 13.1406 7.2432 17.933 8.9184 19.2549C10.5924 17.9318 16.2744 13.1357 16.2744 8.97757C16.2744 3.67425 12.4752 1.78462 8.9184 1.78462Z" fill="#323232"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.91836 12.1706C6.96476 12.1706 5.37476 10.5679 5.37476 8.59716C5.37476 6.62642 6.96476 5.02368 8.91836 5.02368C10.872 5.02368 12.4608 6.62642 12.4608 8.59716C12.4608 10.5679 10.872 12.1706 8.91836 12.1706ZM8.91836 6.59737C7.82516 6.59737 6.93596 7.49437 6.93596 8.59716C6.93596 9.69994 7.82516 10.5982 8.91836 10.5982C10.0116 10.5982 10.8996 9.69994 10.8996 8.59716C10.8996 7.49437 10.0116 6.59737 8.91836 6.59737Z" fill="#323232"/>
  </svg>
`;

const nameText = 'ЛПУП санаторий «Жемчужина»';
const addressText = 'Россия, г. Сочи, ул. Минеральная, 62';
const pointSymbol = '•';
const amenities = [
  'Собственный аквацентр с бассейном',
  'Оздоровительный центр',
  'Ресторан на территории',
  'Боулинг',
  'Терренкур'
];

const descriptionText = 'И вновь предлагаем рассмотреть вариант размещения на Красной Поляне: шикарная «пятерка» не оставит равнодушными даже привередливых гостей. Особенно впечатляют номера: стильные и роскошные, с потрясающим видом на хребты Кавказа. Оснащение супер-современное, есть даже система «умный дом». Подходит отель для самого широкого круга гостей, в том числе и для семей с детьми: для них предусмотрены зона PlayStation и игровая комната. Гурманы и эстеты останутся в восторге от ресторана с панорамными видами. Ну а поклонники здорового образа жизни и релакса оценят оснащение медицинского центра Medical & SPA.';
const ratingValue = '8,5';
const reviewsValueText = '123 отзывов';
const priceValueText = 'от 2 200 ₽';
const priceValueMeasure = 'за сутки';
const moreInfoBtnText = 'Выбрать номер';
const addressMapLocationLinkText = 'На карте';

const bigHotelCardSnippet = {
  name: 'BigHotelCard',
  html: `
    <div class='big-hotel-card-snippet'>
      <div class='primary-data-cont'>
        <div class='name-cont'>
          <div class='arx-text name-text'>${nameText}</div>

          <div class='stars-cont'>
            <div class='star-icon'>${starIcon}</div>
            <div class='star-icon'>${starIcon}</div>
            <div class='star-icon'>${starIcon}</div>
            <div class='star-icon'>${starIcon}</div>
            <div class='star-icon'>${starIcon}</div>
          </div>
        </div>

        <div class='address-cont'>
          <div class='address-icon'>${mapPointIcon}</div>

          <div class='arx-text address-text'>${addressText}</div>

          <div class='arx-text address-map-location-link'>${addressMapLocationLinkText}</div>
        </div>

        <div class='amenities-cont'>
          <div class='arx-text amenities-item'>${amenities[0]}</div>
          <div class='amenities-item-divider'>${pointSymbol}</div>
          <div class='arx-text amenities-item'>${amenities[1]}</div>
          <div class='amenities-item-divider'>${pointSymbol}</div>
          <div class='arx-text amenities-item'>${amenities[2]}</div>
          <div class='amenities-item-divider'>${pointSymbol}</div>
          <div class='arx-text amenities-item'>${amenities[3]}</div>
          <div class='amenities-item-divider'>${pointSymbol}</div>
          <div class='arx-text amenities-item'>${amenities[4]}</div>
        </div>

        <div class='rating-cont'>
          <div class='rating-value-cont'>
            <div class='rating-value-icon'>${likeIcon}</div>
            <div class='arx-text rating-value'>${ratingValue}</div>
          </div>

          <div class='reviews-cont'>
            <div class='arx-text reviews-value-text'>${reviewsValueText}</div>
          </div>
        </div>
      </div>

      <div class='carousel-cont'></div>

      <div class='additional-data-cont'>
        <div class='description-cont'>
          <div class='arx-text description-text'>${descriptionText}</div>
        </div>

        <div class='price-cont'>
          <div class='price-value-cont'>
            <div class='arx-text price-value-text'>${priceValueText}</div>
            <div class='arx-text price-value-measure'>${priceValueMeasure}</div>
          </div>

          <div class='arx-text more-info-btn'>${moreInfoBtnText}</div>
        </div>
      </div>
    </div>
  `
};

export default bigHotelCardSnippet;
