export const writeToLocalStorage = (name, data) => {
  const json = data;
  localStorage.setItem(name, JSON.stringify(json));

  return data;
};

export const readFromLocalStorage = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

export const removeFromLocalStorage = (name) => {
  localStorage.removeItem(name);
};
