import { RootState } from 'store';
import { ISearchArea, ISearchAreaBreadcrumbs } from 'types';

export const searchAreaAllSelector = (
  state: RootState
): [ISearchArea[], ISearchAreaBreadcrumbs[], boolean, string, number] => {
  return [
    state.searchArea.searchAreas,
    state.searchArea.breadcrumbs,
    state.searchArea.isLast,
    state.searchArea.stateMore,
    state.searchArea.total
  ];
};

export const searchAreaSelector = (state: RootState): [ISearchAreaBreadcrumbs[], ISearchArea?] => {
  return [state.searchArea.breadcrumbsOne, state.searchArea.searchArea];
};
