import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum LOGIN_FIELDS {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const validationSchema = () =>
  yup
    .object({
      [LOGIN_FIELDS.EMAIL]: yup.string().email('Введите корректный e-mail').required('Обязательное поле'),
      [LOGIN_FIELDS.PASSWORD]: yup.string().min(8, 'Минимум 8 символов').max(40, 'Максимум 40 символов').required('Обязательное поле')
    });

export const defaultValues = {
  [LOGIN_FIELDS.EMAIL]: '',
  [LOGIN_FIELDS.PASSWORD]: ''
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
