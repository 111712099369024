import { RootState } from 'store';
import { IArticleResponse, STATUSES } from 'types';
import _orderBy from 'lodash/orderBy';

export const articleSelector = (state: RootState): [STATUSES, IArticleResponse?] => {
  return [
    state.articles.articleState,
    state.articles.article
  ];
};
