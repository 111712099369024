import pasteEvent, { pasteHtmlEvent } from '../utils/pasteEvent';
import _uniqueId from 'lodash/uniqueId';

export default class Ui {
  constructor({ t, list, onAdd, onRemove, onRemoveInclude, onParseFull, onParsePart, onAddInclude, toList, onApplyTemplate }) {
    this.list = list;
    this.onAdd = onAdd;
    this.onRemove = onRemove;
    this.onRemoveInclude = onRemoveInclude;
    this.onAddInclude = onAddInclude;
    this.toList = toList;
    this.onApplyTemplate = onApplyTemplate;
    this.onParseFull = onParseFull;
    this.onParsePart = onParsePart;
    this.t = t;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      listElement: make('div', [this.CSS.listElement]),
      blockInput: make('div', this.CSS.blockInput, {
        contentEditable: true
      }),
      template: make('div', this.CSS.template, {
        innerHTML: t('Procedures.Actions.ApplyTemplate')
      }),
      topContainer: make('div', this.CSS.topContainer),
      title: make('h2', this.CSS.title, {
        innerHTML: t('Procedures.Title')
      }),
      includesContainer: make('div', this.CSS.listIncludes),
      includesTitle: make('div', this.CSS.listIncludesTitle),
      includesElement: make('div', this.CSS.listIncludesElement, {
        contentEditable: true
      }),
      listContainer: make('div', this.CSS.listContainer),
      button: this.createAddButton(this.onAdd),
      inputButtonFull: this.createInputButton(onParseFull, t('Procedures.Actions.Rewrite')),
      inputButtonPart: this.createInputButton(onParsePart, t('Procedures.Actions.Complement')),
      inputButtons: make('div', this.CSS.inputButtons)
    };

    this.nodes.template.addEventListener('click', () => {
      this.onApplyTemplate();
    });

    this.nodes.blockInput.setAttribute('data-before', t('Procedures.Placeholder.BlockInput'));

    this.nodes.title.addEventListener('paste', pasteEvent);
    this.nodes.blockInput.addEventListener('paste', pasteHtmlEvent);
  }

  createAddButton(callback, params) {
    const button = make('div', this.CSS.button, {
      innerHTML: this.t('Common.Add')
    });

    button.addEventListener('click', () => {
      callback(params);
    });

    return button;
  }

  createInputButton(callback, text) {
    const button = make('div', this.CSS.button, {
      innerHTML: text
    });

    button.addEventListener('click', () => {
      callback();

      this.nodes.blockInput.innerHTML = '';
    });

    return button;
  }

  createInputButtons(callback) {
    const button = make('div', this.CSS.button, {
      innerHTML: this.t('Procedures.Actions.Create')
    });

    button.addEventListener('click', () => {
      callback();

      this.nodes.blockInput.innerHTML = '';
    });

    return button;
  }

  get CSS() {
    return {
      wrapper: 'procedures',
      topContainer: 'procedures_top_container',
      template: 'procedures_template',
      blockInput: 'procedures_block_input',
      inputButtonFull: 'procedures_input_button_full',
      inputButtonPart: 'procedures_input_button_part',
      inputButtons: 'procedures_input_buttons',
      listElement: 'procedures_element',
      title: 'procedures_title',
      listContainer: 'procedures_list',
      remove: 'procedures_settings_remove',
      button: 'procedures_add',
      listIcon: 'procedures_list_icon',
      removeIcon: 'procedures_element_remove',
      listText: 'procedures_element_text',
      listTitle: 'procedures_element_title',
      listDuration: 'procedures_element_duration',
      listIncludes: 'procedures_includes',
      listIncludesTitle: 'procedures_includes_title',
      listIncludesElement: 'procedures_includes_element',
      listIncludesText: 'procedures_includes_text',
      listContent: 'procedures_list_content'
    };
  }

  getParseData() {
    const liArr = this.nodes.blockInput.querySelectorAll('li');
    const list = [];
    let index = -1;

    Array.from(liArr).map((element) => {
      if (element.style.listStyleType === 'disc') {
        index++;

        list.push({
          title: element.innerText,
          includes: []
        });
      } else {
        list[index].includes.push(element.innerText);
      }
    });

    return list;
  }

  getData() {
    const list = this.nodes.wrapper.getElementsByClassName(this.CSS.listElement);

    return {
      list: Array.from(list).map((el) => {
        const title = el.querySelector(`.${this.CSS.listTitle}`);
        const includes = el.getElementsByClassName(this.CSS.listIncludesElement);

        return {
          title: title.innerHTML,
          includes: Array.from(includes).map((el) => el.innerText),
          id: _uniqueId()
        };
      }),
      listDevelop: Array.from(list).map((el) => {
        const includes = el.getElementsByClassName(this.CSS.listIncludesElement);

        return {
          includes: Array.from(includes).map((el) => el.innerHTML)
        };
      })
    };
  }

  renderList() {
    return this.list.map((element, index) => {
      const listElement = make('div', this.CSS.listElement);

      const listTitle = make('h3', this.CSS.listTitle, {
        contentEditable: true,
        innerHTML: element.title
      });

      const content = make('div', this.CSS.listContent);

      const button = this.createAddButton(this.onAddInclude, index);

      element?.includes?.forEach((include, elementIndex) => {
        const removeIcon = make('div', this.CSS.removeIcon);
        const listIcon = make('div', this.CSS.listIcon, {
          innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.999 494.999"><path d="M105.031 119.481h284.938c32.988 0 59.742-26.743 59.742-59.741C449.71 26.743 422.957 0 389.968 0H105.031C72.043 0 45.289 26.743 45.289 59.74 45.289 92.738 72.043 119.481 105.031 119.481zM389.968 187.757H105.031c-32.987 0-59.742 26.747-59.742 59.744 0 32.994 26.755 59.741 59.742 59.741h284.938c32.988 0 59.742-26.747 59.742-59.741C449.71 214.504 422.957 187.757 389.968 187.757zM389.968 375.518H105.031c-32.987 0-59.742 26.747-59.742 59.741 0 32.997 26.755 59.74 59.742 59.74h284.938c32.988 0 59.742-26.743 59.742-59.74C449.71 402.265 422.957 375.518 389.968 375.518z"/></svg>'
        });

        removeIcon.addEventListener('click', () => {
          this.onRemoveInclude(elementIndex, index);
        });

        listIcon.addEventListener('click', () => {
          this.toList(elementIndex, index);
        });

        const listIncludesElement = make('div', this.CSS.listIncludesElement);

        const listIncludesText = make('div', this.CSS.listIncludesText, {
          contentEditable: true,
          innerHTML: include
        });

        listIncludesText.addEventListener('paste', pasteEvent);

        listIncludesElement.appendChild(listIncludesText);
        listIncludesElement.appendChild(listIcon);
        listIncludesElement.appendChild(removeIcon);

        content.appendChild(listIncludesElement);
      });

      content.appendChild(button);

      listTitle.addEventListener('paste', pasteEvent);
      content.addEventListener('paste', pasteEvent);

      const removeIcon = make('div', this.CSS.removeIcon);

      removeIcon.addEventListener('click', () => {
        this.onRemove(index);
      });

      listElement.appendChild(listTitle);
      listElement.appendChild(removeIcon);
      listElement.appendChild(content);

      return listElement;
    });
  }

  render() {
    const list = this.renderList();

    this.clear(this.nodes.listContainer);

    list.forEach((element) => {
      this.nodes.listContainer.appendChild(element);
    });

    this.nodes.inputButtons.appendChild(this.nodes.inputButtonFull);
    this.nodes.inputButtons.appendChild(this.nodes.inputButtonPart);

    this.nodes.wrapper.appendChild(this.nodes.blockInput);
    this.nodes.wrapper.appendChild(this.nodes.inputButtons);
    this.nodes.topContainer.appendChild(this.nodes.title);
    this.nodes.topContainer.appendChild(this.nodes.template);
    this.nodes.wrapper.appendChild(this.nodes.topContainer);
    this.nodes.wrapper.appendChild(this.nodes.listContainer);
    this.nodes.wrapper.appendChild(this.nodes.button);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  }

  displayErrorItemTitle = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.listTitle);
  }

  displayErrorItemIncludes = (flag, index) => {
    const element = this.getItem(index);
    flag ? element.classList.add('error') : element.classList.remove('error');
    return element;
  }

  displayItemElementError = (flag, index, cssClass) => {
    const item = this.getItem(index);
    const element = item.querySelector(`.${cssClass}`);

    flag ? element.classList.add('error') : element.classList.remove('error');

    return element;
  }

  getItem = (index) => {
    return this.nodes.wrapper.getElementsByClassName(this.CSS.listElement)[index];
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
