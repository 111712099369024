export default {
  translation: {
    'Comments.Location': 'Location',
    'Comments.Title': 'Best comment',
    'Comments.Purity': 'Purity',
    'Comments.Service': 'Service',
    'Comments.Price': 'Price/quality',
    'Comments.Rating': 'Estimation',
    'Comments.Placeholder.Name': "Enter the commentator's name",
    'Comments.Placeholder.Comment': 'Enter a comment',
    'Comments.Error.Name': "The best comment. The commentator's name cannot be empty",
    'Comments.Error.Title': 'The best comment. The header cannot be empty',
    'Comments.Error.Comment': 'The best comment. The comment cannot be empty',
    'Comments.Placeholder.Title': 'Enter title',

    'Available.Title': 'Available calendar',

    'Description.Title': 'Sanatory description',
    'Description.About': 'Enter description',
    'Description.AboutEN': 'Description (EN)',
    'Description.Prompt': 'Enter a query and click the "Generate Description" button',
    'Description.Title.ChatGPT': 'Sanatory description (ChatGPT)',
    'Description.Error.About': 'Description. Description cannot be empty',
    'Description.Error.Prompt': 'Query. Query cannot be empty',

    'Distances.Title': 'How to get',
    'Distances.Description': 'Enter description',
    'Distances.Name': 'Enter name',
    'Distances.Error.Name': 'Food. The name header({number}) cannot be empty',
    'Distances.Error.Description': 'Food. The description header({number}) cannot be empty',
    'Distances.Unit.Km': 'Kilometers',
    'Distances.Unit.M': 'Meters',
    'Distances.Transport.Train': 'Train',
    'Distances.Transport.Plane': 'Plane',
    'Distances.Transport.Transfer': 'Transfer',
    'Distances.Placeholder.Unit': 'Enter the distance',
    'Distances.Label.Unit': 'Select the unit of measurement',
    'Distances.Label.Transport': 'Choose a transport',
    'Distances.Placeholder.Transport': 'Choose a transport',
    'Distances.Label.Value': 'Enter the distance',
    'Distances.Placeholder.Value': 'Enter the distance',

    'NewYear.Title': 'About program',

    'Food.Title': 'Food',
    'Food.Data.Description': 'Enter description',
    'Food.Data.Title': 'Enter title',
    'Food.Error.Title': 'Food. The block header({number}) cannot be empty',
    'Food.Error.Description': 'Food. The block description({number}) cannot be empty',

    'Documents.Title': 'Documents for the trip',
    'Documents.Data.Description': 'Enter description',
    'Documents.Data.Title': 'Enter title',
    'Documents.Error.Title': 'Documents for the trip. The block header({number}) cannot be empty',
    'Documents.Error.Description': 'Documents for the trip. The block description({number}) cannot be empty',

    'Procedures.Title': 'Procedures',
    'Procedures.Data.Title': 'Procedure',
    'Procedures.Data.Includes': 'Enter a list of procedures',
    'Procedures.Error.Title': 'Procedures. The block header({number}) cannot be empty',
    'Procedures.Error.Includes': 'Procedures. The list of block procedures({number}) cannot be empty',
    'Procedures.Actions.Rewrite': 'Overwrite procedures',
    'Procedures.Actions.Complement': 'Supplement procedures',
    'Procedures.Actions.ApplyTemplate': 'Apply template',
    'Procedures.Actions.Create': 'Create procedures',
    'Procedures.Placeholder.BlockInput': 'Insert the data from the Google spreadsheet',

    'Programs.Title': 'Programs',
    'Programs.Data.Description': 'Enter a description',
    'Programs.Data.List.Title': 'Program',
    'Programs.Data.List.Description': 'Description',
    'Programs.Data.List.Duration': 'Enter a period',
    'Programs.Data.List.Includes': 'Enter a list of procedures',
    'Programs.Errors.Description': 'Programs. The description cannot be empty',
    'Programs.Errors.List.Title': 'Programs. The block header({number}) cannot be empty',
    'Programs.Errors.List.Description': 'Programs. The block description({number}) cannot be empty',
    'Programs.Errors.List.Duration': 'Programs. The duration of the block program({number}) cannot be empty',
    'Programs.List.Includes.Title': 'What is included in the program',

    'Stars.Title': 'Choose stardom',

    'RegionCard.Check': 'See',
    'Blogcard.CommonText': 'The sanatorium is included in ',

    'Action.Test': 'Test',
    'Action.Save': 'Save',
    'Action.Preview': 'Preview',
    'Action.Delete': 'Delete',
    'Action.Cancel': 'Cancel',
    'Action.Edit': 'Edit',
    'Action.EditForm': 'Edit form',
    'Action.ToList': 'To list',
    'Action.ShowSite': 'View on the website',
    'SearchArea.RegionSelect.Placeholder': 'Enter the region',
    'SearchArea.Objects.Placeholder': 'Enter a property',
    'SearchArea.Title': 'Search areas',
    'SearchArea.Table.Field1': 'Name',
    'SearchArea.Table.Field2': 'Country',
    'SearchArea.Table.Field3': 'Search type',
    'SearchArea.Table.Field4': 'Searchable',
    'SearchArea.Table.Field5': 'Coordinates',
    'SearchArea.Table.Field6': 'Radius, km',
    'SearchArea.Catalog.Title': 'Catalog page',

    'Parsing.Add.UUID.Placeholder': 'hotel TravelLine ID',
    'Parsing.Table.Field1': 'Name',
    'Parsing.Table.Field2': 'Sanatorium ID',
    'Parsing.Table.Field3': 'Date of creation',
    'Parsing.Table.Field4': 'Interval',
    'Parsing.Table.Field5': 'Tariffs count',
    'Parsing.Table.Field6': 'Status',

    'Parsing.Status.Created': 'Created',
    'Parsing.Status.PendingHotel': 'Waiting information',
    'Parsing.Status.ParsingHotel': 'Getting information',
    'Parsing.Status.PendingRates': 'Waiting tariffs',
    'Parsing.Status.ParsingRates': 'Getting tariffs',
    'Parsing.Status.Parsed': 'Parsing is over',
    'Parsing.Status.Completed': 'Request completed',
    'Parsing.Status.Cancelled': 'Request canceled',
    'Parsing.Status.Expired': 'Request expired',
    'Parsing.Status.Failed': 'Parsing error',

    'Parsing.Title': 'TravelLine fare parsing',
    'Parsing.Create': 'Create request',

    'Tariffs.Table.Field1': 'Name',
    'Tariffs.Table.Field2': 'Description',
    'Tariffs.Table.Field3': 'Source',
    'Tariffs.Table.Field4': 'Interval',
    'Tariffs.Title': 'Tariffs',

    'Tariffs.Rules.Content': 'Cancellation before {day} days prior to arrival will involve {penalty} penalty',
    'Tariffs.Rules.Days': 'of {count, plural, one {# day} few {# days} many {# days}} of the tariff cost',
    'Tariffs.Rules.Percent': '{count}% from the accommodation amount',
    'Tariffs.Rules.Rub': '{count} rub.',
    'Tariffs.Rates.Title': 'Accommodation categories',
    'Tariffs.Periods.Title': 'Pricing periods',
    'Tariffs.Rates.Description': `{ageGroup, select,
      child {Child}
      adult {Adult}
    } {occupation, select,
      main {on the main bed {count, plural,
        =0 {{ageGroup, select,
          child {}
          adult {with shares}
        }}
        one {single occupancy}
        other {{count} occupancy}
      }}
      extra {on the extra bed}
      no {without place}
    }
    {ageGroup, select,
      adult {}
      child {({from} - {to} age)}
    }`,
    'Tariffs.Cancellation.FullRefund': 'Free cancellation',
    'Tariffs.Cancellation.NonRefund': 'It is a non-refundable booking',
    'Tariffs.Cancellation.Label': 'Cancellation policy',

    'Tariffs.RoomTypes.Title': 'Number type in Extranet',
    'Tariffs.RoomTypes.TL.Title': 'Number type in TL',
    'Tariffs.RoomTypes.Placeholder': 'Room type',

    'Tariffs.AvailablePeriod.Label': 'Period of validity',

    'Tariffs.Base': 'base',
    'Tariffs.Tour': 'shift',
    'Tariffs.Package': 'trearment program',

    'Tariffs.Status.Label': 'Status',
    'Tariffs.Status.New': 'new',

    'Tariffs.Type.Label': 'Type of tariff plan',

    'Objects.Table.Field1': 'Name',
    'Objects.Table.Field2': 'Country',
    'Objects.Table.Field3': 'Date of creation',
    'Objects.Table.Field4': 'Update date',

    'Diseases.Title': 'Diseases',
    'Diseases.Add': 'Add disease',
    'Disease.Form.Edit': 'Edit disease',
    'Disease.Subtitle': 'Disease name',
    'Disease.Form.Name': 'Article name',
    'Region.Form.Edit': 'Edit region',
    'Object.Form.Edit': 'Edit object',
    'Blog.Form.Edit': 'Edit blog',
    'Blog.Links.Action.Open': 'Hide all links',
    'Blog.Links.Action.Close': 'View all links',
    'Blog.Title': 'Articles',
    'Blog.Add': 'Add article',
    'Objects.Title': 'Objects',
    'Objects.Add': 'Add object',
    'Regions.Title': 'Regions',
    'Regions.Add': 'Add region',
    'Regions.Coordinates.X.Placeholder': 'Enter latitude',
    'Regions.Coordinates.Y.Placeholder': 'Enter longitude',
    'Regions.Coordinates.X': 'Latitude',
    'Regions.Form.Name': 'Article name',
    'Regions.Form.Subtitle': 'Name',
    'Regions.Coordinates.Y': 'Longitude',
    'Regions.Coordinates': 'Coordinates',
    'Regions.Subtitle': 'Region name',
    'Hotels.Title': 'Objects',
    'Hotels.Add': 'Add object',
    'Common.Create': 'Create',
    'Common.Yes': 'yes',
    'Common.No': 'no',
    'Common.SearchArea': 'Search area',
    'Common.Replace': 'Replace',
    'Common.Add': 'Add',
    'Common.Generate': 'Generate Description',
    'Common.UpdateGPT': 'Update Translate (en)',
    'Common.Form.Empty': 'Nothing found',
    'Common.General': 'General',
    'Common.Name.Placeholder': 'Enter name',
    'Common.Seo.Title': 'Data for SEO',
    'Common.Title': 'Title',
    'Common.Title.Placeholder': 'Enter title',
    'Common.Day': '{count, plural, one {# day} few {# days} many {# days}}',
    'Common.Duration': 'Duration {duration}',
    'Common.Description': 'Description',
    'Common.Description.Placeholder': 'Enter description',
    'Common.Topbar': 'Topbar',
    'Common.Template': 'Template',
    'Common.Review': '{count, plural, one {# comment} few {# comments} many {# comments}}',
    'Common.Edit': 'Editing',
    'Common.Loading': 'Loading...',
    'HotelCard.Action.ShowRooms': 'Show rooms',
    'HotelPage.Content.Programms': 'Programms',
    'HotelPage.Content.Specializations': 'Treatment profiles',
    'HotelPage.Content.Procedures': 'Procedures',
    'HotelPage.Content.MealOptions': 'Meal options',
    'HotelCard.Unavailable': 'Special offer is available on other dates',
    'HotelPage.Content.Amenities': 'Amenities',
    'HotelPage.Content.Descriptions': 'Description',
    'HotelCard.Action.SelectRoom': 'Select room',
    'HotelPage.Content.Programms.Description':
      'Get acquainted with the treatment programs, if necessary, we will advise you <span class="green">free</span> of charge on any program.',
    'HotelPage.TreatmentPrograms.IncludedText': 'Program includes'
  }
};
