import connection from 'connection/instance';
import type { AuthorRequest, AuthorResponse } from '../../types/authors';

const API_BASE = '/api/reviewers';

type ResponseReviewersApi<D> =
  | {
      data: D;
      error: undefined;
    }
  | {
      data: undefined;
      error: any;
    };

type handleRequest = {
  <T extends object = object>(method: 'get' | 'put' | 'post' | 'delete', url: string, data?: object | null): Promise<
    ResponseReviewersApi<T>
  >;
};

const handleRequest: handleRequest = async (method, url, data = null) => {
  try {
    const config = data ? { data } : {};
    const response = await connection[method](`${API_BASE}${url ? '/' + url : ''}`, config);
    return {
      data: response.data.data,
      error: undefined
    };
  } catch (e) {
    return {
      data: undefined,
      error: e
    };
  }
};

class ReviewersApi {
  create(attributes: AuthorRequest) {
    return handleRequest<AuthorResponse>('post', '', attributes);
  }

  update(id: string, attributes: AuthorRequest) {
    return handleRequest<AuthorResponse>('put', id, attributes);
  }

  delete(id: string) {
    return handleRequest<object>('delete', id);
  }

  fetchList() {
    return handleRequest<AuthorResponse[]>('get', '');
  }

  fetchOne(reviewerId: string) {
    return handleRequest<AuthorResponse>('get', reviewerId);
  }

  publish(reviewerId: string) {
    return handleRequest<AuthorResponse>('put', `${reviewerId}/publish`);
  }

  unpublish(reviewerId: string) {
    return handleRequest<AuthorResponse>('put', `${reviewerId}/unpublish`);
  }
}

export default new ReviewersApi();
