import React, { useState } from 'react';
import { Table, Flex } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import Card from '../../Card';
import applySort from './utils/applySort';

import { type BlogCardsProps } from 'types';

function ListCards({
  data,
  onEdit,
  onEditAdditional,
  onPublish,
  type,
  sitePath,
  onDelete,
  onEditForm
}: BlogCardsProps) {
  const [sort, setSort] = useState();
  const displayData: BlogCardsProps['data'] = applySort(data, sort);

  const handleClick = (tag) => () => {
    tag === sort ? setSort(null) : setSort(tag);
  };
  const isBlog = data.some((item) => item.type === 'blog');

  return (
    <Table bg='white' withTableBorder style={{ '--table-border-color': 'var(--mantine-color-gray-4)' }}>
      <Table.Thead bg='var(--sb-main-gray)'>
        <Table.Tr style={{ '--table-border-color': 'var(--mantine-color-gray-3)' }}>
          <Table.Th h='70px'>
            <Flex style={{ cursor: 'pointer' }} align='center' gap='2px' onClick={handleClick('name')}>
              Название
              {sort === 'name' ? <IconChevronDown size='16' /> : <IconChevronUp size='16' />}
            </Flex>
          </Table.Th>

          <Table.Th>Время</Table.Th>

          {isBlog && (
            <Table.Th>
              <Flex style={{ cursor: 'pointer' }} align='center' gap='2px' onClick={handleClick('category')}>
                Категория
                {sort === 'category' ? <IconChevronDown size='16' /> : <IconChevronUp size='16' />}
              </Flex>
            </Table.Th>
          )}

          <Table.Th>Действия</Table.Th>

          <Table.Th>
            <Flex style={{ cursor: 'pointer' }} align='center' gap='2px' onClick={handleClick('publish')}>
              Опубликовать
              {sort === 'publish' ? <IconChevronDown size='16' /> : <IconChevronUp size='16' />}
            </Flex>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {displayData.map((blog) => (
          <Card
            key={blog.id}
            blog={blog}
            onEditForm={onEditForm}
            onEdit={onEdit}
            onEditAdditional={onEditAdditional}
            onPublish={onPublish}
            type={type}
            sitePath={sitePath}
            onDelete={onDelete}
          />
        ))}
      </Table.Tbody>
    </Table>
  );
}

export default ListCards;
