type Review = {
  total: number,
  best: Best,
  categories: Category[]
}

type Category = {
  name: string,
  rating: number
}

type Best = {
  avatar: string,
  description: string,
  geolocation: string,
  provider_id: number,
  rating: number,
  categories: Category[],
  title: string,
  user_name: string
}

function reviewerMapper(review: Review) {
  if (!review) {
    return;
  }

  return {
    type: 'comments',
    data: {
      comment: review.best.description,
      title: review.best.title,
      location: getLocation(review.best.categories),
      name: review.best.user_name,
      price: getPrice(review.best.categories),
      purity: getPurity(review.best.categories),
      rating: review.best.rating,
      service: getService(review.best.categories),
      total: review.total
    }
  };
}

function getLocation(categories: Category[]): number | undefined {
  if (!categories) {
    return 0;
  }

  return categories.find((c) => c.name === 'Расположение')?.rating || 0;
}

function getPurity(categories: Category[]): number | undefined {
  if (!categories) {
    return 0;
  }

  return categories.find((c) => c.name === 'Чистота')?.rating || 0;
}

function getService(categories: Category[]): number | undefined {
  if (!categories) {
    return 0;
  }

  return categories.find((c) => c.name === 'Обслуживание')?.rating || 0;
}

function getPrice(categories: Category[]): number | undefined {
  if (!categories) {
    return 0;
  }

  return categories.find((c) => c.name === 'Цена/качество')?.rating || 0;
}

export default reviewerMapper;
