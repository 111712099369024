import React from 'react';
import { Badge, Box, Flex, Text } from '@mantine/core';

import { IObjectDataFull } from 'types';
import RatingElement from './RatingElement';
import { IconThumbUpFilled } from '@tabler/icons-react';

type Props = {
  object: IObjectDataFull;
};

function BestReview({ object }: Props) {
  if (!object.review) return <div />;

  const elements = object.review.best.categories.map((cat) => <RatingElement element={cat} key={cat.name} />);

  return (
    <Flex direction='column' gap='md' mt='md'>
      <Flex align='center' gap='md'>
        <Text fz={32}>Отзывы</Text>

        {object.review && (
          <Text c='blue' fz={32}>
            {object.review.total}
          </Text>
        )}
        <Badge size='md' color='green' leftSection={<IconThumbUpFilled size='16' />}>
          <Text fz='14'>Отлично</Text>
        </Badge>
      </Flex>

      <Flex wrap='wrap' justify='space-between'>
        {elements}
      </Flex>

      <Box>
        <Flex align='center' gap='xs'>
          <Text fz={20}>{object.review.best.user_name}</Text>
          <Badge size='md' color='green' leftSection={<IconThumbUpFilled size='16' />}>
            <Text fz='14'>{object.review.best.rating}</Text>
          </Badge>
        </Flex>

        <Text dangerouslySetInnerHTML={{ __html: object.review.best.description }} />
      </Box>
    </Flex>
  );
}
export default BestReview;
