import React from 'react';
import { Text, Select, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type Props = React.PropsWithChildren<{
  roomTypes: any[];
  onSelect: (room_type: any) => void;
  roomType: any;
}>;

function RoomTypes({ roomTypes, onSelect, roomType }: Props) {
  const { t } = useTranslation();

  const mappedRoomTypes = roomTypes.map((type) => ({
    value: type.id,
    label: type.name_translations.ru
  }));

  return (
    <Flex direction='column' gap='4px'>
      <Text c='var(--mantine-color-gray-8)'>{t('Tariffs.RoomTypes.Title')}</Text>

      <Select
        data={mappedRoomTypes}
        onChange={onSelect}
        value={roomType}
        placeholder={t('Tariffs.RoomTypes.Placeholder')}
      />
    </Flex>
  );
}

export default RoomTypes;
