import React, { useState } from 'react';

import Treatment from './Treatment';
import { Box, Flex, Text } from '@mantine/core';

type Props = {
    data: any;
}

function Treatments({ data }:Props) {
  const [opened, setOpened] = useState({}) as any;

  const handleClick = (treatment) => {
    setOpened(opened?.title === treatment.title ? null : treatment);
  };

  const treatments = data.items.map((treatment, index) => (
    <Treatment
      treatment={treatment}
      key={index}
      opened={treatment.title === opened?.title}
      onClick={handleClick}
    />
  ));

  return (
    <Box mt='56px'>
      <Text fz='36px' fw='600' dangerouslySetInnerHTML={{ __html: data.title }} />
      <Flex style={{ overflow: 'auto' }} gap='36px' mt='36px'>
        {treatments}
      </Flex>

      <Text fz='20' maw='936px' mt='36px' dangerouslySetInnerHTML={{ __html: opened?.description }} />
    </Box>
  );
}

export default Treatments;
