import hotelsApi from 'apis/HotelsApi';
import { useState } from 'react';
import { IPrice } from 'types';

function useHotelPrice(hotel: any) {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [price, setPrice] = useState<IPrice>();

  const fetch = async (): Promise<void> => {
    setIsPending(true);
    const { data } = await hotelsApi.fetchPrice(hotel);
    setIsPending(false);

    setPrice(data.price_per_day_max);
  };

  return [
    {
      price,
      isPending
    },
    {
      fetch
    }
  ];
}

export default useHotelPrice;
