import ajax from '@codexteam/ajax';
import connection from 'connection/instance';

export default class Uploader {
  constructor({ config, onUpload, onError }) {
    this.config = config;
    this.onUpload = onUpload;
    this.onError = onError;

    const idArr = window.location.pathname.split('/edit')[0].split('/');

    this.id = idArr[idArr.length - 1];
  }

  async uploadByFile(file, index) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    const formData = new FormData();

    formData.append('file[]', file);

    if (this.config.additionalRequestData && Object.keys(this.config.additionalRequestData).length) {
      Object.entries(this.config.additionalRequestData).forEach(([name, value]) => {
        formData.append('file[]', value);
      });
    }

    const { data } = await connection.post(`/api/articles/${this.id}/attachments`, formData);

    this.onUpload(data[file.name], index);
  }

  async uploadSelectedFile(params) {
    const files = await ajax.selectFiles({ accept: this.config.types });
    const formData = new FormData();
    let file;

    for (let i = 0; i < files.length; i++) {
      file = files[i];

      // Check the file type.
      if (!file.type.match('image.*')) {
        continue;
      }

      // Add the file to the request.
      formData.append('file[]', file, file.name);
    }

    const { data } = await connection.post(`/api/articles/${this.id}/attachments`, formData);

    this.onUpload(data[file.name], params);
  }
}
