import React from 'react';
import { Flex, Text, Box } from '@mantine/core';

import MenuItem from './Item';
import { NavigationItem } from 'types/menu';

type MenuProps = {
  data: NavigationItem;
};

function Menu({ data }: MenuProps) {
  return (
    <Flex direction='column' bg='var(--mantine-color-gray-8)' pb='sm'>
      <Box bg='var(--mantine-color-gray-7)' h='1px' w='100%' />
      <Text fz='md' fw='bold' c='white' pt='md' px='md' pb='xs'>
        {data.label}
      </Text>

      {data.items.map((item, index) => (
        <MenuItem key={index} menuItem={item} />
      ))}
    </Flex>
  );
}

export default Menu;
