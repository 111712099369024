import HotelUi from './HotelUi';
import hotelMapper from './utils/hotelMapper';

export default class Ui {
  constructor({ onChangeInput, onSelectHotel, hotel, api }) {
    this.error = false;
    this.hotels = [];
    this.hotel = hotel;
    this.selectedHotel = null;
    this.onSelectHotel = onSelectHotel;
    this.api = api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      selectContainer: make('div', this.CSS.selectContainer),
      selectList: make('div', this.CSS.selectList),
      hotelContainer: make('div', this.CSS.hotelContainer),
      input: make('input', this.CSS.input, {
        placeholder: 'Введите название санатория',
        onchange: this.handleChangeInput
      })
    };

    this.nodes.input.addEventListener('input', (e) => {
      onChangeInput(e.target.value);
    });

    this.hotelUi = new HotelUi({ hotel, api });
  }

  get CSS() {
    return {
      wrapper: 'hotelcardSm',
      title: 'hotelcardSm_title',
      coordinates: 'hotelcardSm_coordinates',
      select: 'hotelcardSm_select',
      input: 'hotelcardSm_input',
      description: 'hotelcardSm_description',
      selectElement: 'hotelcardSm_select_element',
      selectElementName: 'hotelcardSm_select_element_name',
      selectElementDesc: 'hotelcardSm_select_element_desc',
      selectList: 'hotelcardSm_select_list',
      selectContainer: 'hotelcardSm_select_container',
      hotelContainer: 'hotelcardSm_hotel_container',
      hotel: {
        container: 'hotelcardSm_hotel_container',
        name: 'hotelcardSm_hotel_name'
      }
    };
  }

  getData() {
    return this.hotelUi.getHotelData();
  }

  renderHotels() {
    return this.hotels.map((element, index) => {
      const listElement = make('div', this.CSS.selectElement);

      const listElementName = make('div', this.CSS.selectElementName, {
        innerHTML: element.name
      });

      const listElementDesc = make('div', this.CSS.selectElementDesc, {
        innerHTML: element.description
      });

      listElement.appendChild(listElementName);
      listElement.appendChild(listElementDesc);

      listElement.addEventListener('click', () => {
        this.onSelectHotel(element);
      });

      return listElement;
    });
  }

  renderSelectedHotel() {
    this.hotelUi.setHotel(hotelMapper(this.selectedHotel));

    return this.hotelUi.render();
  }

  setInputFocus() {
    this.nodes.input.focus();
  }

  render() {
    if (this.selectedHotel || this.hotel) {
      this.clear(this.nodes.wrapper);
      const hotel = this.hotel ? this.hotelUi.render() : this.renderSelectedHotel();
      this.nodes.wrapper.appendChild(hotel);
    } else {
      const selectList = this.renderHotels();

      this.clear(this.nodes.selectList);

      selectList.forEach((element) => {
        this.nodes.selectList.appendChild(element);
      });

      this.nodes.selectContainer.appendChild(this.nodes.input);
      this.nodes.selectContainer.appendChild(this.nodes.selectList);
      this.nodes.wrapper.appendChild(this.nodes.selectContainer);
    }

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  displayErrorHotel = (flag) => {
    flag ? this.nodes.wrapper.classList.add('error') : this.nodes.wrapper.classList.remove('error');

    return this.nodes.wrapper;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
