import React from 'react';
import { Flex, Text, Badge } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconStarFilled, IconThumbUpFilled } from '@tabler/icons-react';

import { IObjectDataFull } from 'types';

type Props = {
  object: IObjectDataFull,
  hotel: any
}

function Add({ object, hotel }: Props) {
  const { t } = useTranslation();
  return (

    <Flex align='center' justify='space-between'>
      <Flex direction='column'>
        <Flex align='center' gap='md'>
          <Text fz='42px' lh='42px' dangerouslySetInnerHTML={{ __html: hotel.name }} />
          {!!(object.star || hotel.star) && (Array.from({ length: object.star || hotel.star || 0 }).map((_, idx) => (
            <Flex align='center' key={idx}>
              <IconStarFilled color='orange' />
            </Flex>
          )))}
        </Flex>
        <Text mt='xs'>
          {hotel.address.location}
        </Text>
      </Flex>

      {(hotel.rating > 5 || hotel.review?.total) > 0 && (
        <Flex direction='column' gap='xs'>
          {hotel.rating > 5 && (
            <Badge size='md' color='green' leftSection={<IconThumbUpFilled size='16' />}>
              <Text fz='14'>
                {hotel.rating}
              </Text>
            </Badge>
          )}

          {hotel.review?.total > 0 && (
            <Text c='blue'>
              {t('Common.Review', { count: hotel.review.total })}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
}
export default Add;
