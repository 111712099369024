import React, { useEffect } from 'react';
import _ from 'lodash';
import { Button, Text, Flex, Paper } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Content } from 'components/ui/Layout';
import Form from 'components/ui/Form';

import CurrencySelect from 'components/forms/CurrencySelect';
import RegionSelectForm from 'components/ui/RegionSelectForm/RegionSelectForm';
import TimezonesSelect from 'components/forms/TimezonesSelect';

import { useAppDispatch } from 'store';
import createObjectAction from 'store/objectsSlice/actions/createObjectAction';
import { toastify } from 'utils/toastify';
import useSources from './useSources';
import { extendDefaultValues, SANATORY_FIELDS, validationSchema } from './schema';
import InputForm from 'components/ui/InputForm';
import DatePickerForm from 'components/ui/DatePickerForm';

type SanatoryFormProps = {
  setOpenModal: (open: boolean) => void;
  setModalData: (data: any) => void;
  provider: string
}

function SanatoryForm({ setOpenModal, setModalData, provider }:SanatoryFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // @ts-ignore */
  const [sources, { fetch }] = useSources();

  const handleSubmit = async (data) => {
    const formatedData = {
      ...data,
      provider: provider,
      timezone: data.timezone,
      sources: _.map(data.sources, (source, index) => ({
        ...source,
        // @ts-ignore */
        insurance_id: sources?.[index].id,
        contracts: _.filter(source?.contracts, (contract) => contract?.commission_in_percent !== undefined).map(
          (contract) => ({
            ...contract
          })
        )
      })).filter(Boolean)
    };
    try {
      const result = await dispatch(createObjectAction(formatedData));
      setOpenModal(true);
      // @ts-ignore */
      setModalData(result?.payload?.meta);
    } catch (error) {
      toastify('error', 'Возникла ошибка');
    }
  };

  const handleReset = () => {
    navigate('/objects');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Form
      schema={validationSchema()}
      defaultValues={extendDefaultValues({})}
      onSubmit={handleSubmit}
      onError={handleError}
      onReset={handleReset}
    >
      <Content>
        <Flex gap='lg'>
          <Paper w='650px' withBorder shadow='md' p={30} radius='md'>
            <Text fz={30}>{t('Objects.New.Form1.Title')}</Text>

            <Flex direction='column' gap='md'>
              <Text mt='md' fz={20}>
                {t('Objects.New.Form.All')}
              </Text>
              <InputForm
                name={SANATORY_FIELDS.NAME}
                label={t('Objects.New.Form.Name')}
                placeholder={t('Objects.New.Form.Name.Placeholder')}
                required
              />
              <InputForm
                name={SANATORY_FIELDS.LOCATION}
                label={t('Objects.New.Form.Address')}
                placeholder={t('Objects.New.Form.Address.Placeholder')}
                required
              />
              <Flex gap='md'>
                <InputForm
                  width='100%'
                  name={SANATORY_FIELDS.EMAIL}
                  label={t('Objects.New.Form.Email')}
                  placeholder={t('Objects.New.Form.Email.Placeholder')}
                  required
                />
                <InputForm
                  width='100%'
                  name={SANATORY_FIELDS.PHONE}
                  label={t('Objects.New.Form.Phone')}
                  placeholder={t('Objects.New.Form.Phone.Placeholder')}
                  required
                  type='number'
                />
              </Flex>
            </Flex>

            <Flex direction='column' gap='md'>
              <Text mt='md' fz={20}>
                {t('Objects.New.Form.Law')}
              </Text>

              <Flex gap='md'>
                <InputForm
                  width='100%'
                  name={`${SANATORY_FIELDS.LEGAL_ENTITY}.${SANATORY_FIELDS.INN}`}
                  label={t('Objects.New.Form.Inn')}
                  placeholder={t('Objects.New.Form.Inn.Placeholder')}
                  required
                  type='number'
                />
                <InputForm
                  width='100%'
                  name={`${SANATORY_FIELDS.LEGAL_ENTITY}.${SANATORY_FIELDS.KPP}`}
                  label={t('Objects.New.Form.Kpp')}
                  placeholder={t('Objects.New.Form.Kpp.Placeholder')}
                  type='number'
                />
                <CurrencySelect required name={SANATORY_FIELDS.CURRENCY} label={t('Objects.New.Form.Currency')} />
              </Flex>
            </Flex>

            <Flex direction='column' gap='md'>
              <Text mt='md' fz={20}>
                {t('Objects.New.Form.Search')}
              </Text>
              <RegionSelectForm
                label={t('Objects.New.Form.SearchArea')}
                placeholder={t('SearchArea.Objects.Placeholder')}
                name={SANATORY_FIELDS.PLACE_ID}
                required
              />

              <Flex gap='md'>
                <InputForm
                  name={`${SANATORY_FIELDS.COORDINATES}.${SANATORY_FIELDS.LNG}`}
                  label={t('Objects.New.Form.Lng')}
                  placeholder={t('Objects.New.Form.Lng.Placeholder')}
                  required
                />
                <InputForm
                  name={`${SANATORY_FIELDS.COORDINATES}.${SANATORY_FIELDS.LAT}`}
                  label={t('Objects.New.Form.Lat')}
                  placeholder={t('Objects.New.Form.Lat.Placeholder')}
                  required
                />

                <TimezonesSelect required name={SANATORY_FIELDS.TIMEZONE} label={t('Objects.New.Form.Timezone')} />
              </Flex>
            </Flex>
          </Paper>
          <Paper withBorder shadow='md' p={30} radius='md'>
            <Text fz={30}>{t('Objects.New.Form2.Title')}</Text>
            {/* @ts-ignore */}
            {sources?.map(({ name, id }, index) => {
              return (
                <Flex direction='column' gap='md' key={id}>
                  <Text mt='md' fz={20}>
                    {name}
                  </Text>
                  <Flex gap='md'>
                    <InputForm
                      width='100%'
                      name={`${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.NUMBER}`}
                      label={t('Objects.New.Form2.Number')}
                      placeholder={t('Objects.New.Form2.Number.Placeholder')}
                      required
                    />

                    <DatePickerForm
                      required
                      format='DD.MM.YYYY'
                      width='100%'
                      placeholder='дд.мм.гггг'
                      label={t('Objects.New.Form2.Date')}
                      name={`${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.DATE}`}
                    />

                    <InputForm
                      width='100%'
                      name={`${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.COMMISSION}`}
                      label={t('Objects.New.Form2.Commission')}
                      placeholder={t('Objects.New.Form2.Commission.Placeholder')}
                      required
                      type='number'
                    />
                  </Flex>
                </Flex>
              );
            })}
            <Flex align='center' gap='md' mt='36px' w='100%' justify='flex-end'>
              <Button color='green' type='submit'>
                {t('Action.Add')}
              </Button>
              <Button type='reset' color='gray'>
                {t('Action.Cancel')}
              </Button>
            </Flex>
          </Paper>
        </Flex>
      </Content>
    </Form>
  );
}

export default SanatoryForm;
