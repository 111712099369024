import React, { type PropsWithChildren, type ReactNode } from 'react';

type ConditionalComponentProps = PropsWithChildren<{
  condition: boolean;
  fallback?: ReactNode;
}>;

const ConditionalComponent = ({ condition, fallback = null, children }: ConditionalComponentProps) => (
  <>{condition ? children : fallback}</>
);

export default ConditionalComponent;
