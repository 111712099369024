import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Drawer, Flex } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import ConditionalComponent from 'components/ConditionalComponent';
import Loader from 'components/ui/Loader';
import Form from './Form';

import { searchAreaSelector } from 'store/searchAreaSlice/selectors';
import { fetchOneSearchAreaAction } from 'store/searchAreaSlice/actions';

function SearchAreaEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [breadcrumbs, searchArea] = useAppSelector(searchAreaSelector);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchOneSearchAreaAction(id));
    };

    fetch();
  }, [id]);

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Drawer position='right' opened onClose={handleClose} size='lg'>
      <ConditionalComponent
        condition={!!searchArea}
        fallback={
          <Flex h='100vh' w='100%' align='center'>
            <Loader />
          </Flex>
        }
      >
        <Form searchArea={searchArea} onClose={handleClose} breadcrumbs={breadcrumbs} />
      </ConditionalComponent>
    </Drawer>
  );
}

export default SearchAreaEdit;
