import React, { HTMLAttributes } from 'react';
import { Loader as LoaderMantine, Flex } from '@mantine/core';

type Props = Pick<HTMLAttributes<'div'>, 'className'>;

function Loader(props: Props) {
  return (
    <Flex w='100%' h='100%' pos='relative' align='center' justify='center' {...props}>
      <LoaderMantine color='var(--mantine-color-green-8)' />
    </Flex>
  );
}

export default Loader;
