import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select, MultiSelect } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { type IOption } from 'types';

type Props = React.PropsWithChildren<{
  className?: string | undefined;
  name: string;
  label?: string;
  placeholder?: string;
  width?: string;
  type?: string;
  defaultValue?: string;
  options: IOption[];
  required?: boolean;
}>;

function MultiSelectForm({ name, label, className, placeholder, defaultValue, width, options, required }: Props) {
  const {
    formState: { errors }
  } = useFormContext<{ name: Props['name'] }>();
  const [open, setOpen] = React.useState(false);

  // TODO: use useFormContext<IFormValues>
  const errorMsg = errors?.[name] ? String(errors?.[name]?.message) : undefined;

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <MultiSelect
          {...field}
          w={width}
          label={label}
          error={errorMsg}
          className={className}
          // defaultValue={defaultValue}
          placeholder={placeholder}
          data={options}
          rightSection={
            open ? <IconChevronUp size={24} color='#B0BEC5' /> : <IconChevronDown size={24} color='#B0BEC5' />
          }
          onDropdownOpen={() => setOpen(true)}
          onDropdownClose={() => setOpen(false)}
          withAsterisk={required}
        />
      )}
    />
  );
}

export default MultiSelectForm;
