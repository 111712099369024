import React from 'react';
import { Box, Image, Text } from '@mantine/core';

import image from 'public/images/new_year.jpg';

function NewYearBlock() {
  return (
    <Box pos='relative'>
      <Image src={image} />

      <Box pos='absolute' bottom='20px' left='20px'>
        <Text maw='190px'>Новогодние предложения в этом санатории</Text>
      </Box>
    </Box>
  );
}

export default NewYearBlock;
