import Uploader from '../utils/Uploader';
import Ui from './Ui';
import './index.css';

class Gallery {
  static get toolbox() {
    return {
      icon: '<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"><path d="M32.12,10H3.88A1.88,1.88,0,0,0,2,11.88V30.12A1.88,1.88,0,0,0,3.88,32H32.12A1.88,1.88,0,0,0,34,30.12V11.88A1.88,1.88,0,0,0,32.12,10ZM32,30H4V12H32Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M8.56,19.45a3,3,0,1,0-3-3A3,3,0,0,0,8.56,19.45Zm0-4.6A1.6,1.6,0,1,1,7,16.45,1.6,1.6,0,0,1,8.56,14.85Z" class="clr-i-outline clr-i-outline-path-2"></path><path d="M7.9,28l6-6,3.18,3.18L14.26,28h2l7.46-7.46L30,26.77v-2L24.2,19a.71.71,0,0,0-1,0l-5.16,5.16L14.37,20.5a.71.71,0,0,0-1,0L5.92,28Z" class="clr-i-outline clr-i-outline-path-3"></path><path d="M30.14,3h0a1,1,0,0,0-1-1h-22a1,1,0,0,0-1,1h0V4h24Z" class="clr-i-outline clr-i-outline-path-4"></path><path d="M32.12,7V7a1,1,0,0,0-1-1h-26a1,1,0,0,0-1,1h0V8h28Z" class="clr-i-outline clr-i-outline-path-5"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>',
      title: 'Галлерея'
    };
  }

  constructor({ data, config, api, readOnly }) {
    this._data = data || [];

    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      captionPlaceholder: 'Caption',
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined,
      actions: config.actions || []
    };

    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error)
    });

    /**
     * Module for working with UI
     */
    this.ui = new Ui({
      api,
      config: this.config,
      onRemoveData: this.onRemoveData,
      onDrag: this.onPaste,
      onSelectFile: () => {
        this.uploader.uploadSelectedFile({
          onChange: (data) => {
            this.ui.data = data;
          }
        });
      },
      readOnly,
      data: this._data
    });
  }

  onRemoveData = (index) => {
    this._data = this._data.filter((_, i) => i !== index);

    this.ui.data = this._data;

    this.render();
  };

  save() {
    return this._data;
  }

  onUpload = (data) => {
    this._data.push(data);
    this.ui.data = this._data;

    this.render();
  };

  uploadFile(file) {
    this.uploader.uploadByFile(file);
  }

  uploadUrl(url) {
    this.uploader.uploadByUrl(url);
  }

  onPaste = (ev) => {
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();

          if (file.type?.includes('image')) {
            this.uploadFile(file);
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        if (ev.dataTransfer.files[i].type?.includes('image')) {
          this.uploadFile(ev.dataTransfer.files[i]);
        }
      }
    }
  };

  render() {
    return this.ui.render();
  }
}

export default Gallery;
