import React, { useEffect } from 'react';
import { Flex, Text, Button, Box } from '@mantine/core';
import { useFormContext } from 'react-hook-form';

import SpecializationsSelect from 'components/forms/SpecializationsSelect';
import FileInputForm from 'components/ui/FileInputForm';
import MetaTagsForm from './MetaTagsForm';
import AwardsForm from './AwardsForm';
import InputForm from 'components/ui/InputForm';
import { AUTHORS_FIELDS } from './schema';
import DatePickerForm from 'components/ui/DatePickerForm';
import TextareaForm from 'components/ui/TextareaForm';
import { AuthorResponse } from 'types/authors';

type Props = {
  author?: AuthorResponse;
};

function AuthorsForm({ author }: Props) {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!author) {
      return;
    }
    setValue(AUTHORS_FIELDS.FIRST_NAME_FIELD, author.first_name);
    setValue(AUTHORS_FIELDS.LAST_NAME_FIELD, author.last_name);
    setValue(AUTHORS_FIELDS.MIDDLE_NAME_FIELD, author.middle_name);
    setValue(AUTHORS_FIELDS.AVATAR_FIELD, author.avatar);
    setValue(AUTHORS_FIELDS.START_OF_PRACTICE_FIELD, author.start_of_practice);
    setValue(
      AUTHORS_FIELDS.SPECIALIZATIONS_FIELD,
      author.specializations.map((s) => s.tag)
    );
    setValue(AUTHORS_FIELDS.SEO_TITLE_FIELD, author.seo_title);
    setValue(AUTHORS_FIELDS.SEO_DESCRIPTION_FIELD, author.seo_description);
  }, [author, setValue]);

  return (
    <Flex direction='column' gap='8px'>
      <Flex direction='column' gap='8px'>
        <Text fw='500'>Общее</Text>
        <Flex gap='md'>
          <Box flex='1'>
            <InputForm required label='Имя' name={AUTHORS_FIELDS.FIRST_NAME_FIELD} placeholder='Введите имя' />
          </Box>
          <Box flex='1'>
            <InputForm required label='Фамилия' name={AUTHORS_FIELDS.LAST_NAME_FIELD} placeholder='Введите фамилию' />
          </Box>
        </Flex>
        <Flex gap='md'>
          <InputForm
            width='100%'
            label='Отчество'
            name={AUTHORS_FIELDS.MIDDLE_NAME_FIELD}
            placeholder='Введите отчество'
          />
          <FileInputForm width='100%' label='Аватар' name={AUTHORS_FIELDS.AVATAR_FIELD} placeholder='Выберите файл' />
        </Flex>

        <DatePickerForm
          format='DD.MM.YYYY'
          placeholder='дд.мм.гггг'
          label='Дата начала практики'
          name={AUTHORS_FIELDS.START_OF_PRACTICE_FIELD}
        />

        <SpecializationsSelect label='Специализации' name={AUTHORS_FIELDS.SPECIALIZATIONS_FIELD} />

        <AwardsForm author={author} />
      </Flex>

      <Flex direction='column' gap='8px'>
        <Text fw='500' my='xs'>
          Данные для SEO
        </Text>
        <InputForm required label='Заголовок' name={AUTHORS_FIELDS.SEO_TITLE_FIELD} placeholder='Введите заголовок' />
        <TextareaForm
          required
          label='Описание'
          name={AUTHORS_FIELDS.SEO_DESCRIPTION_FIELD}
          placeholder='Введите описание'
        />
        <MetaTagsForm author={author} />
      </Flex>

      <Flex align='center' gap='8px' mt='md'>
        <Button type='submit' color='green'>
          Сохранить
        </Button>

        <Button type='reset' color='gray'>
          Отмена
        </Button>
      </Flex>
    </Flex>
  );
}

export default AuthorsForm;
