import React from 'react';
import { Container } from '@mantine/core';
import cx from 'clsx';

import classes from './Content.module.css';

type Props = React.PropsWithChildren<{
  hasBottomBar?: boolean;
  className?: string;
  onScroll?: (e: any) => void;
}>;

function Content({ children, hasBottomBar, className, onScroll }: Props) {
  return (
    <Container className={cx(classes.root, className, hasBottomBar && classes.bottomBar)} fluid onScroll={onScroll}>
      {children}
    </Container>
  );
}

export default Content;
