import React from 'react';
import PropTypes from 'prop-types';

const headers = [
  'headers_h1',
  'headers_h2',
  'headers_h3',
  'headers_h4',
  'headers_h5',
  'headers_h6'
];

function Header({ data, ...rest }) {
  return <div className={`header_wrapper ${headers[data.level - 1]}`} {...rest} dangerouslySetInnerHTML={{ __html: data.text }} />;
}

Header.propTypes = {
  data: PropTypes.object
};

export default Header;
