import { createAsyncThunk } from '@reduxjs/toolkit';

import { parsingFetch } from 'apis/ParsingApi';

const ACTION = 'parsing/fetch';

const fetchParsingAction = createAsyncThunk(
  ACTION,
  parsingFetch
);

export default fetchParsingAction;
