import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, Add, Edit, EditAdditional } from './containers';

function Authors() {
  return (
    <Routes>
      <Route path='/:id/edit' element={<Edit />} />

      <Route path='/' element={<List />}>
        <Route path='/add' element={<Add />} />

        <Route path='/:id/edit-additional' element={<EditAdditional />} />
      </Route>
    </Routes>
  );
}

export default Authors;
