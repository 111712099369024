import React from 'react';
import { Box, Accordion, Text, Flex } from '@mantine/core';

import { IObjectDataFull } from 'types';

type Props = {
  object: IObjectDataFull;
  currentLanguage: string;
};

function Meal({ object, currentLanguage }: Props) {
  const programs = object.body_translations[currentLanguage]?.blocks.find((b) => b.type === 'feeding_programs').data
    .list;

  const feedingComponents = programs.map((program: any) => {
    return (
      <Accordion.Item key={program.value} value={program.title}>
        <Accordion.Control bg='var(--mantine-color-gray-2)'>
          <Text fz='18px' fw='500'>
            {program.title}
          </Text>
        </Accordion.Control>
        <Accordion.Panel bg='var(--mantine-color-gray-2)'>
          <Text dangerouslySetInnerHTML={{ __html: program.description }} />
        </Accordion.Panel>
      </Accordion.Item>
    );
  });

  return (
    <Box mt='md'>
      <Accordion>
        <Flex direction='column' gap='16px'>
          {feedingComponents}
        </Flex>
      </Accordion>
    </Box>
  );
}

export default Meal;
