import { createAsyncThunk } from '@reduxjs/toolkit';

import { objectsFetchOne } from 'apis/ObjectsApi';
import { IObjectData } from 'types';
import { toBlocksMapper } from 'utils/hotelMapper';

const ACTION = 'objects/fetchOne';

type Params = {
  object: IObjectData,
  hotel: any
}

export interface IObjectParams {
  id: string,
  lang: string
}

const fetchOneArticlesAction = createAsyncThunk(
  ACTION,
  async (params: IObjectParams): Promise<Params> => {
    const data: any = await objectsFetchOne(params);

    return {
      object: toBlocksMapper(data),
      hotel: data
    };
  }
);

export default fetchOneArticlesAction;
