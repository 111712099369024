import { startAppListening } from '../../listenerMiddleware';

import { toastify } from 'utils/toastify';

import { fetchOneObjectAction } from '../actions';

startAppListening({
  actionCreator: fetchOneObjectAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось получить данные');
  }
});
