const value = {
  content: {
    ru: {
      blocks: [
        {
          id: 'test',
          type: 'titleAndDescription',
          data: {
            title: 'О курорте',
            description: 'Алупка — небольшой климатический курорт на Южном берегу Крыма, расположенный у подножия горы Ай-Петри, в 17 км от Ялты. Город окружен горами, которые защищают его от холодных ветров и помогают удерживать тепло. В Алупке работают несколько десятков отелей, санаториев и гостевых домов, которые пользуются популярностью среди любителей спокойного отдыха и хорошего уровня сервиса по приемлемым ценам. Более 100 лет здравницы города помогают отдыхающим в лечении заболеваний опорно-двигательного аппарата, органов дыхания, кожи, эндокринной и сердечно-сосудистой системы.'
          }
        },

        {
          id: 'test10',
          type: 'treatments',
          data: {
            title: 'Что помогает в оздоровлении',
            items: [
              {
                image: {
                  id: 'refer',
                  encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvYmFjNjBlMjI2/ZjYwNzNmNThjNTMw/YTNmZmU3NzdhOTEu/anBlZw',
                  thumb_url: 'https://cdn.sanatory-testing.ru/fill/240/114/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvYjk3M2U0NDE2/NzIxNDMxZmQwMTFh/ZmQ2MDFhNGFkNGMu/anBlZw.jpg',
                  url: 'https://cdn.sanatory-testing.ru/fill/960/456/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvYjk3M2U0NDE2/NzIxNDMxZmQwMTFh/ZmQ2MDFhNGFkNGMu/anBlZw.jpg'
                },
                title: 'Климат',
                description: 'Основы лечения в санаториях Анапы — благоприятный климат и сухой, насыщенным морскими солями воздух, которых положительно влияет на состояние дыхательной системы и улучшает общее самочувствие.'
              }
              // {
              //   image: {
              //     id: 'freeeee',
              //     encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvNWUxNmZlNmJi/YTBjMjU2YzgxNjY3/OWQ2OTQwYTA3Mjku/anBlZw',
              //     url: 'https://cdn.sanatory-testing.ru/fill/960/456/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvNWUxNmZlNmJi/YTBjMjU2YzgxNjY3/OWQ2OTQwYTA3Mjku/anBlZw.jpg',
              //     thumb_url: 'https://cdn.sanatory-testing.ru/fill/240/114/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvNWUxNmZlNmJi/YTBjMjU2YzgxNjY3/OWQ2OTQwYTA3Mjku/anBlZw.jpg'
              //   },
              //   title: 'Море',
              //   description: 'Общая протяженность пляжей Алупки составляет около 4 км, а основной купальный сезон на курорте продолжается с июня по сентябрь. Лечение морем является важным оздоровительным фактором. Купание в морской воде улучшает кровообращение, обмен веществ, работу мозга, тонус мышц, состояние кожи, дыхательной и иммунной систем.'
              // }
            ]
          }
        },

        {
          id: 'test3',
          type: 'whatToSee',
          data: {
            title: 'Чем заняться и что стоит посмотреть',
            list: [
              {
                title: 'Центральная набережная',
                description: 'Это главный променад и центр курортной жизни Анапы. На набережной протяженностью 1.6 км находится большое количество скульптур, памятников, скамеек, мест отдыха и развлечений для всей семьи. Прогуляться по набережной можно свободно в любое время.'
              }
              // {
              //   title: 'Археологический музей',
              //   description: 'Музей создан на месте раскопок руин античного города. Посетители могут прогуляться по археологическому парку под открытым небом, а также посетить Археологический музей, экспозиция которого рассказывает об истории древнего города. Музей работает со вторника по воскресенье с 10.00 до 19.00.'
              // },
              // {
              //   title: 'Центральная набережная2',
              //   description: 'Это главный променад и центр курортной жизни Анапы. На набережной протяженностью 1.6 км находится большое количество скульптур, памятников, скамеек, мест отдыха и развлечений для всей семьи. Прогуляться по набережной можно свободно в любое время.'
              // }
            ]
          }
        },
        {
          id: 'test2',
          type: 'listItems',
          data: {
            title: 'С какими заболеваниями стоит приезжать',
            description: null,
            list: [
              'Заболевания опорно-двигательного аппарата'
              // 'Заболевания сердечно-сосудистой системы',
              // 'Заболевания кожи',
              // 'Заболевания эндокринной системы, расстройства питания и нарушения обмена веществ',
              // 'Заболевания дыхательной системы и ЛОР-органов',
              // 'Санаторно-курортное лечение и оздоровление детей'
            ]
          }
        },
        {
          id: 'test4',
          type: 'facts',
          data: {
            title: 'Интересные факты',

            list: [
              '1. Именно в Анапе были изобретены такие необычные виды лечения, как виноградотерапия, музыкотерапия, библиотекотерапия и лечебная педагогика.'
              // '2. В 2011 году Анапа признана лучшим бальнеологическим курортом мира, по версии ФЕМТЕК (Международной Ассоциации климато- и бальнеолечения).',
              // '3. В анапском парке развлечений «Книга джунглей» находится третье по высоте в России колесо обозрения. Оно возвышается над городом на 70 метров.'
            ]
          }
        },

        {
          id: 'test9',
          type: 'map',
          data: {
            coordinates: [55.7522, 37.6156],
            description: 'Введите описание',
            title: 'Как добраться'
          }
        }
      ]
    },

    en: {
      // blocks: [
      //   {
      //     id: 'test11',
      //     type: 'titleAndDescription',
      //     data: {
      //       title: 'About the resort',
      //       description: 'Alupka is a small climatic resort on the Southern coast of Crimea, located at the foot of Ai—Petri Mountain, 17 km from Yalta. The city is surrounded by mountains that protect it from cold winds and help to retain heat. There are several dozen hotels, sanatoriums and guest houses in Alupka, which are popular among lovers of a relaxing holiday and a good level of service at reasonable prices. For more than 100 years, the health resorts of the city have been helping vacationers in the treatment of diseases of the musculoskeletal system, respiratory organs, skin, endocrine and cardiovascular systems.'
      //     }
      //   },
      //   {
      //     id: 'test10',
      //     type: 'treatments',
      //     data: {
      //       title: 'What helps in recovery',
      //       items: [
      //         {
      //           image: {
      //             id: 'refer',
      //             encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvYmFjNjBlMjI2/ZjYwNzNmNThjNTMw/YTNmZmU3NzdhOTEu/anBlZw',
      //             thumb_url: 'https://cdn.sanatory-testing.ru/fill/240/114/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvYjk3M2U0NDE2/NzIxNDMxZmQwMTFh/ZmQ2MDFhNGFkNGMu/anBlZw.jpg',
      //             url: 'https://cdn.sanatory-testing.ru/fill/960/456/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvYjk3M2U0NDE2/NzIxNDMxZmQwMTFh/ZmQ2MDFhNGFkNGMu/anBlZw.jpg'
      //           },
      //           title: 'Climate',
      //           description: 'The basics of treatment in Anapa sanatoriums are a favorable climate and dry air saturated with sea salts, which positively affects the state of the respiratory system and improves overall well—being.'
      //         },
      //         {
      //           image: {
      //             id: 'freeeee',
      //             encoded_url: 'aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvNWUxNmZlNmJi/YTBjMjU2YzgxNjY3/OWQ2OTQwYTA3Mjku/anBlZw',
      //             url: 'https://cdn.sanatory-testing.ru/fill/960/456/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvNWUxNmZlNmJi/YTBjMjU2YzgxNjY3/OWQ2OTQwYTA3Mjku/anBlZw.jpg',
      //             thumb_url: 'https://cdn.sanatory-testing.ru/fill/240/114/sm/1/aHR0cHM6Ly8yMDk2/MjUuc2VsY2RuLnJ1/L3Nrb19pbWFnZXNf/ZGVtby9ibG9nL3Bh/Z2UvNjJkN2U2NDk0/MGQ4MDIwMDAxOWU5/MDhlL2F0dGFjaG1l/bnQvNWUxNmZlNmJi/YTBjMjU2YzgxNjY3/OWQ2OTQwYTA3Mjku/anBlZw.jpg'
      //           },
      //           title: 'Sea',
      //           description: 'The total length of the beaches of Alupka is about 4 km, and the main bathing season at the resort lasts from June to September. Sea treatment is an important health factor. Bathing in seawater improves blood circulation, metabolism, brain function, muscle tone, skin condition, respiratory and immune systems.'
      //         }
      //       ]
      //     }
      //   },

      //   {
      //     id: 'test3',
      //     type: 'whatToSee',
      //     data: {
      //       title: 'What to do and what to see',
      //       list: [
      //         {
      //           title: 'Central embankment',
      //           description: 'This is the main promenade and the center of Anapa resort life. On the 1.6 km long embankment there is a large number of sculptures, monuments, benches, places of recreation and entertainment for the whole family. You can walk along the embankment freely at any time.'
      //         },
      //         {
      //           title: 'Archaeological museum',
      //           description: 'The museum was created at the site of the excavation of the ruins of an ancient city. Visitors can stroll through the archaeological park in the open air, as well as visit the Archaeological Museum, the exposition of which tells about the history of the ancient city. The museum is open from Tuesday to Sunday from 10.00 to 19.00.'
      //         },
      //         {
      //           title: 'Central embankment 2',
      //           description: 'This is the main promenade and the center of Anapa resort life. On the 1.6 km long embankment there is a large number of sculptures, monuments, benches, places of recreation and entertainment for the whole family. You can walk along the embankment freely at any time.'
      //         }
      //       ]
      //     }
      //   },
      //   {
      //     id: 'test2',
      //     type: 'listItems',
      //     data: {
      //       title: 'What diseases should I come with',
      //       description: null,
      //       list: [
      //         'Diseases of the musculoskeletal system',
      //         'Diseases of the cardiovascular system',
      //         'Skin diseases',
      //         'Diseases of the endocrine system, eating disorders and metabolic disorders',
      //         'Diseases of the respiratory system and ENT organs',
      //         'Spa treatment and rehabilitation of children'
      //       ]
      //     }
      //   },
      //   {
      //     id: 'test4',
      //     type: 'facts',
      //     data: {
      //       title: 'Interesting facts',
      //       list: [
      //         '1. It was in Anapa that such unusual types of treatment as grape therapy, music therapy, library therapy and therapeutic pedagogy were invented.',
      //         '2. In 2011 Anapa was recognized as the best balneological resort in the world, according to FEMTEK (International Association of Climate and Balneotherapy).',
      //         '3. In Anapa amusement park "The Jungle Book" is the third highest Ferris wheel in Russia. It rises 70 meters above the city.'
      //       ]
      //     }
      //   },

      //   {
      //     id: 'test9',
      //     type: 'map',
      //     data: {
      //       coordinates: [55.7522, 37.6156],
      //       description: 'Enter description',
      //       title: 'Hot to get'
      //     }
      //   }
      // ]
    }
  }
};

export default value;
