import { getImage } from 'utils/getImage';

export default class Ui {
  constructor({ api, config, onSelectFile, readOnly, data, onRemoveData, onDrag }) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.onRemoveData = onRemoveData;
    this.readOnly = readOnly;
    this.onDrag = onDrag;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [this.CSS.imageContainer]),
      fileButton: this.createFileButton()
    };

    this.nodes.wrapper.addEventListener('drop', (event) => {
      this.onDrag(event);
    });
  }

  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,
      wrapper: 'gallery-tool',
      imageContainer: 'gallery-tool__image'
    };
  }

  renderImages() {
    return this.data.map((image) => {
      const img = getImage(image.encoded_url, { width: 360, height: 360 });

      const container = make('div', 'gallery-img-container');
      const closeIcon = make('div', 'gallery-img-remove');

      const picture = make('img', 'gallery-img', {
        src: img.defaultSrc,
        key: img.defaultSrc
      });

      container.appendChild(picture);
      container.appendChild(closeIcon);

      return container;
    });
  }

  render() {
    const images = this.renderImages();
    const imagesContainer = make('div');

    images.forEach((img) => {
      imagesContainer.appendChild(img);
    });

    this.nodes.imageContainer.innerHTML = imagesContainer.innerHTML;

    this.nodes.wrapper.appendChild(this.nodes.imageContainer);

    this.nodes.wrapper.appendChild(this.nodes.fileButton);

    this.addHandlers();

    return this.nodes.wrapper;
  }

  addHandlers() {
    const items = this.nodes.wrapper.getElementsByClassName('gallery-img-remove');

    if (!items) return;

    Array.from(items).forEach((item, index) => item.addEventListener('click', (data) => {
      this.onRemoveData(index);
    }));
  }

  createFileButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = this.config.buttonContent || `${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
