import React from 'react';
import { ActionIcon, Input, Flex, Text, Paper } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

type Object = {
  area_id: string;
  type: string;
  name: string;
  radius?: number;
};

type Props = React.PropsWithChildren<{
  // eslint-disable-next-line @typescript-eslint/ban-types
  region: Object;
  handleDelete: (id: string) => void;
  searchWithHotels?: boolean | undefined;
  onChangeRadius: (radius: number, index: number) => void;
  index: number;
  disabled?: boolean;
}>;

function SearchArea({ region, handleDelete, searchWithHotels, onChangeRadius, index, disabled }: Props) {
  const handleClick = () => {
    handleDelete(region.area_id);
  };

  const handleChange = (e: any) => {
    onChangeRadius(Number(e.target.value), index);
  };

  return (
    <Paper withBorder shadow='md' p='xs' radius='md'>
      <Flex w='100%' justify='space-between' align='center' gap='xs'>
        <Text fz={14}>{region.name}</Text>
        <Flex align='center' gap='4px' bd='lg' color='green'>
          {!searchWithHotels && <Input type='number' onChange={handleChange} value={region.radius} disabled={disabled} />}
          <ActionIcon size={18} onClick={handleClick} variant='transparent'>
            <IconX color='gray' />
          </ActionIcon>
        </Flex>
      </Flex>
    </Paper>
  );
}

export default SearchArea;
