import { OSTROVOK, SANATORY } from '../AddObject/constants';

export const getProviderTranslate = (provider:string) => {
  switch (provider) {
    case SANATORY:
      return 'Sanatory.ru';
    case OSTROVOK:
      return 'Островок';
  }
};
